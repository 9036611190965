import React, { useRef, useState } from "react";
import { cleanEnergyMineralsValueChainsSliderOptions } from "../../config/sliders/slider-option";
import { useConfig } from "../../contexts/config/ConfigContext";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Splide } from "@splidejs/react-splide";
import CleanEnergyMineralsValueChainsSliderItem from "./CleanEnergyMineralsValueChainsSliderItem";

export default function CleanEnergyMineralsValueChainsSlider({
  minerals,
  onChange,
}) {
  const { configState } = useConfig();

  const [selectedMineralId, setSelectedMineralId] = useState(-1);
  const valueChainsSliderRef = useRef(null);

  const handleOnClickNextButton = () => {
    valueChainsSliderRef.current.splide.go("+1");
  };

  const handleOnClickPreviousButton = () => {
    valueChainsSliderRef.current.splide.go("-1");
  };

  const handleOnChange = (mineral) => {
    onChange(mineral);
    setSelectedMineralId((prevId) => (prevId === mineral.id ? -1 : mineral.id));
  };

  if (minerals) {
    return (
      <div
        className="w-full"
        aria-label="Clean Energy Minerals Value Chains Slider"
        data-aos="fade-up"
        data-aos-duration={500}
        data-aos-delay={600}
      >
        <div className="flex space-x-4 rtl:flex-row-reverse mb-3">
          <button
            onClick={handleOnClickPreviousButton}
            title={configState.translation.PREVIOUS}
            aria-label="Previous"
            className="flex"
            data-aos="zoom-in"
            data-aos-duration={700}
            data-aos-delay={500}
          >
            <span className="border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out">
              <GrFormPrevious className="w-6 h-6 tv:w-12" />
            </span>
          </button>
          <button
            onClick={handleOnClickNextButton}
            title={configState.translation.NEXT}
            aria-label="Next"
            className="flex"
            data-aos="zoom-in"
            data-aos-duration={700}
            data-aos-delay={500}
          >
            <span className="border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out">
              <GrFormNext className="w-6 h-6 tv:w-12" />
            </span>
          </button>
        </div>
        <Splide
          ref={valueChainsSliderRef}
          options={cleanEnergyMineralsValueChainsSliderOptions}
          aria-label="Minerals value chains slider"
        >
          {minerals.map((mineral) => (
            <CleanEnergyMineralsValueChainsSliderItem
              key={mineral.id}
              language={configState.language}
              mineral={mineral}
              onChange={handleOnChange}
              selectedMineralId={selectedMineralId}
            />
          ))}
        </Splide>
      </div>
    );
  }
}
