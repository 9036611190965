// Component to display the animated image in the platform message section
export default function PlatformMessageAnimatedImage() {
  return (
    // Image tag for the animated image
    <img
      // Animation attributes for fading in
      data-aos={`fade-in`}
      data-aos-duration={`500`}
      data-aos-delay={`500`}
      // CSS class for styling
      className={`w-full lg:w-[550px]`}
      // Image source
      src={`/assets/gifs/platform_message.gif`}
      // Alternative text for accessibility
      alt={`رسم متحرك لمصادر طاقة نظيفة`}
    />
  );
}
