import { useConfig } from "../../contexts/config/ConfigContext";

export default function CleanEnergyMineralsRegionDetailsParagraph({ mineral }) {
  const { configState } = useConfig();

  if (mineral && mineral.countries[0].id === 22) {
    return (
      <>
        <h2 className={`text-3xl font-bold md:text-5xl capitalize`}>
          {mineral[`name_${configState.language}`]} (
          <span className={`text-warning`}>{mineral.symbol}</span>)
        </h2>
        <div className={`mt-6`}>
          <article>
            <div
              className={`text-lg leading-loose text-justify`}
              dangerouslySetInnerHTML={{
                __html: mineral.countries[0][`body_${configState.language}`],
              }}
            />
            {mineral.countries[0][`source_${configState.language}`] && (
              <div
                className={`border-r-4 border-warning pr-4 cursor-default mt-6`}
              >
                <h4 className={`mb-0.5 font-bold`}>
                  {configState.translation.SOURCE}
                </h4>
                <div
                  className={`underline`}
                  dangerouslySetInnerHTML={{
                    __html:
                      mineral.countries[0][`source_${configState.language}`],
                  }}
                />
              </div>
            )}
          </article>
        </div>
      </>
    );
  }
}
