import { Link } from 'react-router-dom';
import PlatformOverviewBannerParagraph from './PlatformOverviewBannerParagraph';
import PlatformOverviewBannerImage from './PlatformOverviewBannerImage';

// Component for the platform overview banner
export default function PlatformOverviewBanner() {
    return (
        <div className={`relative group bg-warning hover:bg-primary overflow-hidden duration-500 ease-in-out transform`}>
            <Link to={`/clean-energy-minerals/opportunities`} className="block">
                {/* Arrow image */}
                <img
                    className={`duration-500 ease-in-out group-hover:transform group-hover:left-10 absolute h-full w-full object-cover opacity-20 bottom-0 left-0`}
                    src={`/assets/images/overview/arrow.svg`}
                    alt="arrow"
                />
                {/* Banner content */}
                <div className='app-container py-12 px-6 lg:px-0'>
                    <div className='space-y-12 relative lg:space-y-0 flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                        {/* Paragraph component */}
                        <PlatformOverviewBannerParagraph />
                        {/* Image component */}
                        <PlatformOverviewBannerImage />
                    </div>
                </div>
            </Link>
        </div>
    );
}