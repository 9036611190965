import Title from "../componenets/UI/Title";
import Spacing from "../componenets/UI/Spacing";
import { useConfig } from "../contexts/config/ConfigContext";
import CleanEnergyMineralsRegionProductionPowerBI from "../componenets/clean-energy-minerals-region-production/CleanEnergyMineralsRegionProductionPowerBI.jsx";
import { Helmet } from "react-helmet";

export default function CleanEnergyMineralsRegionProduction() {
  // Accessing configuration state from context
  const { configState } = useConfig();
  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `الانتاج العربي | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Arab Production | Arab Platform for Future Minerals`;
      default:
        return `الانتاج العربي | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
      case "en":
        return `Explore the world of minerals in the Arab region through the Arab Platform for the Future of Minerals. We offer insights and investment opportunities in the mining sector, focusing on the development and exploration of mineral resources. Learn about the latest technologies in mineral extraction and how to develop metal industries for sustainable growth`;
      default:
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `استكشف التنوع الغني للمعادن في المنطقة العربية وتعرف على الإنتاج العربي القوي في مختلف القطاعات. من التعدين إلى الصناعات المعدنية، تشارك الدول العربية بنشاط في إنتاج المعادن، معززةً النمو والتطور. اكتشف قطاع التعدين العربي المزدهر ومساهماته في تطوير الموارد والممارسات المستدامة`;
      case "en":
        return `Explore the state of minerals in the Arab region with a focus on Arab production. Learn about the latest trends in metal production, mineral exploration, and the development of the mining sector in the Arab world. Discover how Arab industries are working to ensure sustainable mining practices and the use of advanced technologies in resource extraction`;
      default:
        return `استكشف التنوع الغني للمعادن في المنطقة العربية وتعرف على الإنتاج العربي القوي في مختلف القطاعات. من التعدين إلى الصناعات المعدنية، تشارك الدول العربية بنشاط في إنتاج المعادن، معززةً النمو والتطور. اكتشف قطاع التعدين العربي المزدهر ومساهماته في تطوير الموارد والممارسات المستدامة`;
    }
  };
  return (
    <div className={`py-16 px-6 bg-white overflow-hidden`}>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: "https://arabpfm.org/assets/logos/app_logo.svg",
            publisher: {
              "@type": "Organization",
              name: "Arab Platform for Future Minerals",
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className={`lg:px-0 app-container`}>
        {/* Page heading */}
        <Title data-aos={`fade-left`} data-aos-duration={`500`}>
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRODUCTION
          }
        </Title>

        {/* Power BI Embedded iframe */}
        <CleanEnergyMineralsRegionProductionPowerBI
          language={configState.language}
        />
      </div>
    </div>
  );
}
