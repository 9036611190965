import { useEffect, useRef, useState } from "react";
import moment from "moment"; // Importing moment.js for date formatting
import { CountUp } from "use-count-up"; // Importing count up animation component
import { AreaChart, Area, Tooltip, ResponsiveContainer, YAxis } from "recharts"; // Importing necessary components from Recharts
import { BiSolidUpArrow } from "react-icons/bi"; // Importing up arrow icon
import { GoDash } from "react-icons/go"; // Importing dash icon

import { useConfig } from "../../../../contexts/config/ConfigContext"; // Importing useConfig hook for accessing configuration state,

import CustomChartTooltip from "../../../UI/CustomChartTooltip"; // Importing custom chart tooltip component

// Component for displaying a card with area chart for clean energy minerals home prices
export default function CleanEnergyMineralsHomePricesAreaChartCard({
  mineralPrice,
}) {
  const { configState } = useConfig(); // Accessing configuration state
  const chartRef = useRef(null); // Reference to the chart component
  const [isVisible, setIsVisible] = useState(false); // State to track if the chart is visible

  // Effect to observe when the chart becomes visible in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change this threshold as needed
      }
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) {
        observer.unobserve(chartRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={chartRef}
      className="col-span-6 lg:col-span-2 w-full border-muted border-2 border-dashed hover:border-solid hover:border-warning transform transition duration-300 ease-in-out"
    >
      {isVisible && ( // Render chart only when it becomes visible
        <div className="flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div className="flex items-end space-x-2 p-2 rtl:space-x-reverse">
              {/* Mineral image */}
              <img
                className="h-[40px] w-[40px] object-cover"
                src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineralPrice.image}`}
                alt={mineralPrice[`name_${configState.language}`]}
              />
              {/* Mineral name */}
              <h3 className="font-semibold text-xl capitalize">
                {mineralPrice[`name_${configState.language}`]}
              </h3>
            </div>
            <div className={`p-2`}>
              {/* Display price change */}
              {parseFloat(mineralPrice.latest.change) !== 0 && (
                <div
                  className={`flex items-center space-x-2 rtl:space-x-reverse text-${
                    parseFloat(mineralPrice.latest.change) > 0
                      ? "success"
                      : "danger"
                  }`}
                >
                  <div dir="ltr">
                    {
                      <CountUp
                        isCounting
                        end={parseFloat(mineralPrice.latest.change.toFixed(5))}
                        duration={1.2}
                      />
                    }{" "}
                    %
                  </div>
                  <BiSolidUpArrow
                    className={`${
                      parseFloat(mineralPrice.latest.change) > 0
                        ? ""
                        : "rotate-180"
                    }`}
                  />
                </div>
              )}
              {/* Display dash if price change is zero */}
              {parseFloat(mineralPrice.latest.change) === 0 && (
                <div
                  className={`flex items-center space-x-2 rtl:space-x-reverse`}
                >
                  <div dir="ltr">0.00 %</div>
                  <GoDash strokeWidth={2} />
                </div>
              )}
            </div>
          </div>
          {/* Price and date */}
          <div className={`mb-4 flex flex-col items-start px-2`}>
            <h2 className={`font-bold text-2xl rtl:text-right`} dir={`ltr`}>
              $
              {
                <CountUp
                  isCounting
                  end={parseFloat(
                    mineralPrice.latest.price
                      .toFixed(5)
                      .toString()
                      .replace(/\.?0+$/, "")
                  )}
                  duration={1.2}
                />
              }
              /{mineralPrice.unit_symbol}
            </h2>
            <div
              className={`bg-muted/50 border-muted border text-sm px-2 py-1`}
            >
              {moment(mineralPrice.latest.date).format("MMM DD")}
            </div>
          </div>
          {/* Area chart */}
          <div className="w-full h-[128px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                data={mineralPrice.data}
              >
                <YAxis domain={["auto", "auto"]} hide={true} />
                <Tooltip
                  content={
                    <CustomChartTooltip unit={mineralPrice.unit_symbol} />
                  }
                />
                {/* Different area colors based on price change */}
                {mineralPrice.latest.change > 0 && (
                  <Area
                    type="linear"
                    dataKey="price"
                    strokeWidth={2}
                    stroke={"#198754"}
                    fill={"#19875499"}
                  />
                )}
                {mineralPrice.latest.change < 0 && (
                  <Area
                    type="linear"
                    dataKey="price"
                    strokeWidth={2}
                    stroke={"#dc3545"}
                    fill={"#dc354599"}
                  />
                )}
                {mineralPrice.latest.change == 0 && (
                  <Area
                    type="linear"
                    dataKey="price"
                    strokeWidth={2}
                    stroke={"#067697"}
                    fill={"#06769799"}
                  />
                )}
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
}
