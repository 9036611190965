import React from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import { Link } from "react-router-dom";

export default function CleanEnergyMineralsOpportunitiesContainerGridItem({
  opportunity,
}) {
  const { configState } = useConfig();
  return (
    <div
      className={
        "col-span-6 md:col-span-3 border-2 group hover:border-warning hover:border-dashed transform transition duration-300 ease-in-out"
      }
    >
      <h3 className="py-2 px-4 bg-primary text-white font-bold select-all">
        {opportunity[`title_${configState.language}`]}
      </h3>

      <ul className="py-2 px-4 space-y-2 group-hover:bg-muted/10 transform transition duration-300 ease-in-out">
        <li className="flex flex-row space-x-2">
          <div className="font-bold">
            {configState.translation.OPPORTUNITIES_PAGE_ROW_MENIRALS}
          </div>
          <div>{opportunity[`minerals_${configState.language}`]}</div>
        </li>
        <li className="flex flex-row space-x-2">
          <div className="font-bold">
            {configState.translation.OPPORTUNITIES_PAGE_AREA}
          </div>
          <div>
            {opportunity.area == null
              ? configState.translation.OPPORTUNITIES_PAGE_NOT_SPECIFIED
              : opportunity.area}
          </div>
        </li>
        <li className="flex flex-row space-x-2">
          <div className="font-bold">
            {configState.translation.OPPORTUNITIES_PAGE_DATE}
          </div>
          <div>
            {opportunity.started_at == null
              ? configState.translation.OPPORTUNITIES_PAGE_NOT_SPECIFIED
              : new Date(opportunity.started_at).getFullYear()}
          </div>
        </li>
        <li className="flex flex-row space-x-2">
          <div className="font-bold">
            {configState.translation.OPPORTUNITIES_PAGE_TYPE}
          </div>
          <div>{opportunity[`type_${configState.language}`]}</div>
        </li>
      </ul>
      <div>
        {opportunity.attachment_link ? (
          <Link
            target="_blank"
            to={opportunity.attachment_link}
            className="flex justify-center py-2 px-4 w-full font-semibold text-black bg-warning/30 hover:bg-warning/50 transform transition duration-300 ease-in-out"
          >
            {configState.translation.OPPORTUNITIES_PAGE_ADDITIONAL_INFORMATION}
          </Link>
        ) : (
          <p className="flex justify-center py-2 px-4 w-full font-semibold text-black/70 bg-muted/30 cursor-default">
            {configState.translation.OPPORTUNITIES_PAGE_ADDITIONAL_INFORMATION}
          </p>
        )}
      </div>
    </div>
  );
}
