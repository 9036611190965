import React from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../contexts/config/ConfigContext";
import { Helmet } from "react-helmet";

export default function Error404() {
  const { configState } = useConfig();
  if (configState === "ar") {
    return (
      <div className="flex h-screen w-full">
        <Helmet>
          <title>خطأ 404 | المنصة العربية لمعادن المستقبل</title>
        </Helmet>
        <div className="items-center justify-center flex w-full">
          <div className="text-center">
            <h1 className="text-7xl mb-8 font-bold">خطأ 404</h1>
            <Link to={`/`}>
              <p className="text-xl">
                العودة إلى{" "}
                <span className="font-bold text-primary">الصفحة الرئيسية</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex h-screen w-full">
        <Helmet>
          <title>Error 404 not Found | Arab Platform for Future Minerals</title>
        </Helmet>
        <div className="items-center justify-center flex w-full">
          <div className="text-center">
            <h1 className="text-7xl mb-8 font-bold">Error 404</h1>
            <Link to={`/`}>
              <p className="text-xl">
                Go back to <span className="font-bold text-primary">Home</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
