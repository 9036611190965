import { SplideSlide } from "@splidejs/react-splide";

export default function CleanEnergyMineralsValueChainsSliderItem({
  mineral,
  language,
  selectedMineralId,
  onChange,
}) {
  return (
    <SplideSlide key={mineral.id}>
      <div
        onClick={() => onChange(mineral)}
        className={`group overflow-hidden border-2 flex flex-col space-y-2 items-center justify-center py-6 px-3 ${
          mineral.id === selectedMineralId
            ? "bg-muted border-warning"
            : "hover:bg-muted hover:border-warning border-muted"
        } cursor-pointer transform transition duration-300 ease-in-out`}
      >
        <h3 className={`text-lg font-bold capitalize`}>
          {mineral[`name_${language}`]}
        </h3>

        <img
          width={100}
          height={100}
          className={`group-hover:scale-125 transform transition duration-300 ease-in-out`}
          src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
          alt={mineral.name_ar}
        />

        <p className={`text-lg font-bold text-warning`}>{mineral.symbol}</p>
      </div>
    </SplideSlide>
  );
}
