/**
 * Component for rendering a video background in the hero section.
 * This video background showcases the importance of mineral resources in clean energy technologies.
 */
export default function HeroVideo() {
  return (
    <>
      {/* Video element with attributes for autoplay, looping, and muting */}
      <video autoPlay loop muted className={`object-cover w-full h-full`}>
        {/* Source tag specifying the video file and its type */}
        <source
          src={`/assets/videos/home/hero_background.webm`}
          type={`video/webm`}
        />
      </video>
      {/* Video overlay to add opacity and darken the video */}
      <div
        className={`absolute inset-0 bg-black opacity-60`}
        aria-hidden={`true`}
      >
        {/* ARIA hidden attribute ensures the overlay is not read by screen readers */}
      </div>
    </>
  );
}
