import { useConfig } from "../contexts/config/ConfigContext";
import Title from "../componenets/UI/Title";
import Spacing from "../componenets/UI/Spacing";
import SubTitle from "../componenets/UI/SubTitle";
import { Helmet } from "react-helmet";

export default function About() {
  const { configState } = useConfig();

  if (configState.language === "ar")
    return (
      <div>
        <Helmet>
          <title>حول المنصة - المنصة العربية لمعادن المستقبل</title>
          <meta
            name="description"
            content="المنصة العربية لمعادن المستقبل هي أول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل في المنطقة العربية، وتحتوي على معلومات حول المعادن الاستراتيجية وفرص الاستثمار."
          />
          <meta
            name="keywords"
            content="معادن المستقبل, معادن استراتيجية, استثمار التعدين, التعدين العربي"
          />

          {/* Open Graph for social media sharing */}
          <meta property="og:title" content="حول المنصة - معرف" />
          <meta
            property="og:description"
            content="المنصة العربية لمعادن المستقبل هي أول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل في المنطقة العربية."
          />
          <meta property="og:image" content="/assets/images/about_image.webp" />

          {/* Twitter Card for Twitter sharing */}
          <meta name="twitter:card" content="/assets/images/about_image.webp" />
          <meta
            name="twitter:title"
            content="حول المنصة - المنصة العربية لمعادن المستقبل"
          />
          <meta
            name="twitter:description"
            content="المنصة العربية لمعادن المستقبل هي أول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل في المنطقة العربية."
          />
          <meta
            name="twitter:image"
            content="/assets/images/about_image.webp"
          />

          {/* Structured Data for Article */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              headline: "حول المنصة",
              description:
                "المنصة العربية لمعادن المستقبل هي أول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل في المنطقة العربية، وتحتوي على معلومات حول المعادن الاستراتيجية وفرص الاستثمار.",
              image: "/assets/images/about_image.webp",
            })}
          </script>
        </Helmet>
        <Spacing />
        <div className={`py-16 px-6 bg-white overflow-hidden`}>
          <div className={`lg:px-0 app-container select-none`}>
            <article itemscope itemtype="http://schema.org/Article">
              <Title
                itemprop="headline"
                data-aos={`fade-left`}
                data-aos-duration="800"
              >
                حول المنصة
              </Title>
              <div
                className={`h-[312px] mb-12`}
                data-aos={`fade-up`}
                data-aos-duration={500}
                data-aos-delay={500}
              >
                <img
                  className={`h-full w-full object-cover object-bottom`}
                  src={`/assets/images/about_image.webp`}
                  alt={`صورة حول المنصة`}
                  itemprop="image"
                />
              </div>

              <SubTitle
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={500}
              >
                مقدمة
              </SubTitle>

              <p
                className={`mb-6`}
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={700}
                itemprop="description"
              >
                المنطقة العربية تزخر بالعديد من الثروات المعدنية المطلوبة في
                الصناعات المستقبلية المواكبة للتوجهات العالمية، فالعالم يسعى إلى
                تحقيق الحياد الكربوني وخلق بيئة نظيفة ومستدامة وتوفير تكنولوجيات
                حديثة، وهذا ما يؤدي إلى زيادة الطلب على الموارد المعدنية التي
                تمثل مصدرا رئيسيا لإقامة صناعات متطورة كتقنيات الطاقة النظيفة
                (الطاقة الكهروضوئية، توربينات الرياح، المركبات الكهربائية، شبكة
                الكهرباء، الهيدروجين الأخضر،....)، أنظمة الأتصالات، الهواتف،
                الذكاء الأصطناعي، انترنت الأشياء وغيرها. وتبعا لذلك وتماشيا مع
                التحول الرقمي وتنفيذا لمخرجات المبادرة العربية للمعادن المستخدمة
                في تقنيات الطاقة النظيفة، قامت المنظمة العربية للتنمية الصناعية
                والتقييس والتعدين بإعداد "المنصة العربية لمعادن المستقبل" لخدمة
                الفاعلين والمهتمين بقطاعات الطاقة، الصناعة والتعدين عربيأ
                ودولياً.
              </p>
              <div
                className={`my-12 flex flex-col md:items-start md:flex-row md:space-x-6 md:rtl:space-x-reverse`}
              >
                <img
                  data-aos={`fade-in`}
                  data-aos-duration={500}
                  data-aos-delay={700}
                  className={`w-full md:w-2/5 lg:w-1/5`}
                  src={`/assets/logos/app_logo.svg`}
                  alt={`platform logo`}
                />
                <p className={`w-full md:w-3/5 lg:w-4/5 md:mt-0 mt-3`}>
                  <span
                    data-aos={`fade-left`}
                    data-aos-duration={600}
                    data-aos-delay={700}
                  >
                    اول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل كالمعادن
                    الإستراتيجية والحرجة وغيرها في المنطقة العربية وتشمل على:
                  </span>
                  <ul>
                    <li
                      data-aos={`fade-left`}
                      data-aos-duration={600}
                      data-aos-delay="800"
                    >
                      المعلومات الفنية (مثل التعريف بالمعادن، تواجدها وفرص
                      استثمارها في الدول العربية، سلسلة القيمة، الطلب العالمي،
                      أسعار المعادن،......الخ)
                    </li>
                    <li
                      data-aos={`fade-left`}
                      data-aos-duration={600}
                      data-aos-delay="900"
                    >
                      الخريطة العربية (كنافذة تفاعلية بإستخدام Arc GIS Server
                      لتوفير المعلومات الجيومكانية عن معادن المستقبل في المنطقة
                      العربية ومعلومات أخرى ذات العلاقة).
                    </li>
                  </ul>
                </p>
              </div>
              <SubTitle
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={500}
              >
                رسالة المنصة
              </SubTitle>
              <div className={`grid grid-cols-12 gap-8 w-full`}>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={600}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2 px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/promoting-icon.svg`}
                      alt="promoting"
                    />
                  </div>
                  <p className={`mt-2 text-2xl sm:text-xl text-center`}>
                    ابراز دور المعادن والتعدين في مواكبة التوجهات العالمية نحو
                    التنمية المستدامة
                  </p>
                </div>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={800}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2  px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/role-of-minerals-icon.svg`}
                      alt="role-of-minerals"
                    />
                  </div>
                  <p className={`mt-2 text-2xl sm:text-xl text-center`}>
                    الترويج للمعادن ذات البعد الاستراتيجي وفرص استثمارها في
                    الدول العربية
                  </p>
                </div>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={1000}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2 px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/interactive-icon.svg`}
                      alt="interactive"
                    />
                  </div>
                  <p className="mt-2 text-2xl sm:text-xl text-center">
                    التعريف بمعادن المستقبل
                  </p>
                </div>
              </div>
              <div className={`mt-12`}>
                <SubTitle
                  data-aos={`fade-left`}
                  data-aos-duration={600}
                  data-aos-delay={500}
                >
                  المرحلة الاولى للمنصة
                </SubTitle>
                <p
                  data-aos={`fade-left`}
                  data-aos-duration={800}
                  data-aos-delay={500}
                >
                  ترتكز المرحلة الاولى من انجاز المنصة على " معادن الطاقة
                  النظيفة" وذلك تماشيا مع مخرجات المبادرة العربية للمعادن
                  المستخدمة في تقنيات الطاقة النظيفة المقترحة من طرف المنظمة
                  العربية للتنمية الصناعية والتقييس والتعدين، حيث تم الاطلاق
                  الرسمي للمنصة في اصدارها الاول من قبل أصحاب المعالي الوزراء
                  العرب المعنيين بشؤون الثروة المعدنية خلال اجتماعهم التشاوري
                  التاسع يوم 09 يناير 2024 في مدينة الرياض بالمملكة العربية
                  السعودية
                </p>
              </div>
              <div className={`mt-12`}>
                <SubTitle
                  data-aos={`fade-left`}
                  data-aos-duration={600}
                  data-aos-delay={500}
                >
                  أهداف المرحلة الأولى
                </SubTitle>
                <p
                  data-aos={`fade-left`}
                  data-aos-duration={800}
                  data-aos-delay={500}
                >
                  <ul>
                    <li>
                      تنفيذ السياسات الرامية الى تعزيز الاقتصاد الأخضر في سياق
                      التنمية المستدامة عبر الاستفادة من الثروات المعدنية
                      العربية.
                    </li>
                    <li>
                      مواكبة التوجهات العالمية نحو مواجهة التغير المناخي والوصول
                      للحياد الكربوني.
                    </li>
                    <li>
                      الاطلاع والتعريف بالفرص الاستثمارية التعدينية العربية
                      وخاصة ذات العلاقة بمعادن الطاقة النظيفة.
                    </li>
                    <li>
                      تعزيز مكانة المنطقة العربية في دعم الطلب العالمي وجعلها
                      مركز تعديني عالمي مستدام.
                    </li>
                    <li>دعم سلسلة القيمة لمعادن الطاقة النظيفة.</li>
                  </ul>
                </p>
                <span
                  data-aos={`fade-left`}
                  data-aos-duration={500}
                  data-aos-delay={700}
                  className={`font-bold text-sm`}
                >
                  المعلومات المتوفرة على المنصة مصدرها الجهات المعنية بقطاع
                  الثروة المعدنية في الدول العربية والمواقع الرسمية والتقارير
                  الدولية ذات العلاقة
                </span>
              </div>
            </article>
          </div>
        </div>
      </div>
    );
  if (configState.language === "en")
    return (
      <div>
        <Spacing />
        <div className={`py-16 px-6 bg-white overflow-hidden`}>
          <div className={`lg:px-0 app-container select-none`}>
            <Title data-aos={`fade-left`} data-aos-duration="800">
              About
            </Title>
            <article>
              <div
                className={`h-[312px] mb-12`}
                data-aos={`fade-up`}
                data-aos-duration={500}
                data-aos-delay={500}
              >
                <img
                  className={`h-full w-full object-cover object-bottom`}
                  src={`/assets/images/about_image.webp`}
                  alt={`about`}
                />
              </div>

              <SubTitle
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={500}
              >
                INTRODUCTION
              </SubTitle>

              <p
                className={`mb-6`}
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={700}
              >
                The Arab region is rich in many mineral resources that are
                required in future industries to keep up with global trends. The
                world is striving to achieve carbon neutrality and create a
                clean, sustainable environment, as well as to provide modern
                technologies. This leads to an increased demand for mineral
                resources, which represent a primary source for establishing
                advanced industries such as clean energy technologies
                (photovoltaics, wind turbines, electric vehicles, electric
                grids, green hydrogen...), communication systems, phones,
                artificial intelligence, the Internet of Things, and others.
                Accordingly, and in line with digital transformation and the
                implementation of the outputs of the Arab initiative for
                minerals used in clean energy technologies, the Arab Industrial
                Development, Standardization, and Mining Organization (AIDSMO)
                has prepared the "Arab Platform for Future Minerals" to serve
                stakeholders and those interested in the energy, industry, and
                mining sectors both regionally and internationally.{" "}
              </p>
              <div
                className={`my-12 flex flex-col md:items-start md:flex-row md:space-x-6 md:rtl:space-x-reverse`}
              >
                <img
                  data-aos={`fade-in`}
                  data-aos-duration={500}
                  data-aos-delay={700}
                  className={`w-full md:w-2/5 lg:w-1/5`}
                  src={`/assets/logos/app_logo.svg`}
                  alt={`platform logo`}
                />
                <p className={`w-full md:w-3/5 lg:w-4/5 md:mt-0 mt-3`}>
                  <span
                    data-aos={`fade-left`}
                    data-aos-duration={600}
                    data-aos-delay={700}
                  >
                    The first digital and interactive database for future
                    minerals such as strategic and critical minerals in the Arab
                    region includes:
                  </span>
                  <ul>
                    <li
                      data-aos={`fade-left`}
                      data-aos-duration={600}
                      data-aos-delay={800}
                    >
                      Technical information (such as mineral identification,
                      their presence and investment opportunities in Arab
                      countries, value chain, global demand, mineral prices,
                      etc.),
                    </li>
                    <li
                      data-aos={`fade-left`}
                      data-aos-duration={600}
                      data-aos-delay={900}
                    >
                      Arab map (as an interactive window using ArcGIS Server to
                      provide geospatial data about future minerals in the Arab
                      region and other related information).
                    </li>
                  </ul>
                </p>
              </div>
              <SubTitle
                data-aos={`fade-left`}
                data-aos-duration={600}
                data-aos-delay={500}
              >
                Platform's mission
              </SubTitle>
              <div className={`grid grid-cols-12 gap-8 w-full`}>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={600}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2 px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/promoting-icon.svg`}
                      alt="promoting"
                    />
                  </div>
                  <p className={`mt-2 text-2xl sm:text-xl !text-center`}>
                    Highlighting the role of minerals and mining in keeping pace
                    with global trends towards sustainable development.{" "}
                  </p>
                </div>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={800}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2  px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/role-of-minerals-icon.svg`}
                      alt="role-of-minerals"
                    />
                  </div>
                  <p className={`mt-2 text-2xl sm:text-xl !text-center`}>
                    Promoting minerals with a strategic dimension and their
                    investment opportunities in Arab countries.
                  </p>
                </div>
                <div
                  data-aos={`fade-up`}
                  data-aos-duration={500}
                  data-aos-delay={1000}
                  className={`col-span-12 lg:col-span-4 flex-grow flex-shrink-0 w-full flex flex-col items-center justify-between border-2 px-3 py-12 lg:py-6 lg:px-2 sm:py-6 bg-muted/50`}
                >
                  <div className={`mb-2 flex items-start`}>
                    <img
                      className={`w-[128px] h-[128px] ltr:w-[100px] ltr:h-[100px]`}
                      src={`/assets/icons/about/interactive-icon.svg`}
                      alt="interactive"
                    />
                  </div>
                  <p className="mt-2 text-2xl sm:text-xl !text-center">
                    Introducing future minerals.
                  </p>
                </div>
              </div>
              <div className={`mt-12`}>
                <SubTitle
                  data-aos={`fade-left`}
                  data-aos-duration={600}
                  data-aos-delay={500}
                >
                  First phase: "Clean energy minerals"
                </SubTitle>
                <p
                  data-aos={`fade-left`}
                  data-aos-duration={800}
                  data-aos-delay={500}
                >
                  The first phase of the platform's based on "Clean energy
                  minerals," in alignment with the outputs of the Arab
                  Initiative for Minerals used in Clean Energy Technologies
                  proposed by the Arab Industrial Development, Standardization
                  and Mining Organization. The official launch of the platform
                  in its first edition was carried out by Their Excellencies the
                  Arab Ministers concerned with mineral resources during their
                  ninth consultative meeting on January 9, 2024, in Riyadh,
                  Saudi Arabia.
                </p>
              </div>
              <div className={`mt-12`}>
                <SubTitle
                  data-aos={`fade-left`}
                  data-aos-duration={600}
                  data-aos-delay={500}
                >
                  Objectives of the first phase
                </SubTitle>
                <p
                  data-aos={`fade-left`}
                  data-aos-duration={800}
                  data-aos-delay={500}
                >
                  <ul>
                    <li>
                      Implementing policies aimed at enhancing the green economy
                      within the framework of sustainable development through
                      leveraging Arab mineral resources.
                    </li>
                    <li>
                      Keeping pace with global trends towards addressing climate
                      change and achieving carbon neutrality.
                    </li>
                    <li>
                      Exploring and promoting the Arab mining sector's
                      investment opportunities, especially those related to
                      clean energy minerals.
                    </li>
                    <li>
                      Boosting the Arab region's standing in supporting global
                      demand and establishing it as a sustainable global mining
                      center.
                    </li>
                    <li>
                      Supporting the value chain for clean energy minerals.
                    </li>
                  </ul>
                </p>
                <span
                  data-aos={`fade-left`}
                  data-aos-duration={500}
                  data-aos-delay={700}
                  className={`font-bold text-sm`}
                >
                  The data available on the platform originates from entities
                  concerned with the mineral resources sector in Arab countries,
                  official websites, and related international reports.
                </span>
              </div>
            </article>
          </div>
        </div>
      </div>
    );
}
