import React from "react";
import { useConfig } from "../../contexts/config/ConfigContext";

export default function Copyright() {
  const { configState } = useConfig();
  return (
    <div className="pb-6 px-3 lg:py-3 lg:px-0 bg-muted">
      <p className="text-center">{configState.translation.COPYRIGHT}</p>
    </div>
  );
}
