import React from "react";

const WIDTH = 1522;
const HEIGHT = 813;

export default function CleanEnergyMineralsRegionDetailsMapCountries({
  countries,
  language,
  imageSize,
  selectedCountry,
  onChange,
}) {
  function calculateNewXPosition(newWidth, currentX) {
    return (newWidth / WIDTH) * currentX;
  }
  function calculateNewYPosition(newHeight, currentY) {
    return currentY * (newHeight / HEIGHT);
  }
  function calculateNewXSize(newWidth, currentSize) {
    return (newWidth / WIDTH) * currentSize;
  }
  function calculateNewYSize(newHeight, currentSize) {
    return (newHeight / HEIGHT) * currentSize;
  }

  return countries.map((country) => (
    <img
      title={country[`name_${language}`]}
      key={country.id}
      style={{
        top: calculateNewYPosition(
          imageSize.height,
          selectedCountry && selectedCountry.id === country.id
            ? country.virtual_position_y - 14 * 2
            : country.virtual_position_y - 14
        ),
        left: calculateNewXPosition(
          imageSize.width,
          selectedCountry && selectedCountry.id === country.id
            ? country.virtual_position_x - 22 * 2
            : country.virtual_position_x - 22
        ),
        height: calculateNewYSize(
          imageSize.height,
          selectedCountry && selectedCountry.id === country.id ? 32 * 2 : 32
        ),
        width: calculateNewXSize(
          imageSize.width,
          selectedCountry && selectedCountry.id === country.id ? 50 * 2 : 50
        ),
      }}
      onClick={() => onChange(country)}
      className={`absolute transition duration-300 ease-in-out ${
        selectedCountry && selectedCountry.id === country.id
          ? `z-10`
          : `z-2 hover:scale-110`
      }  cursor-pointer`}
      src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
      alt={country[`name_${language}`]} // Alt text based on the country name
    />
  ));
}
