import MobileNavbar from "./mobile/MobileNavbar";
import { Link, useLocation } from "react-router-dom";
import DesktopNavbar from "./DesktopNavbar";
import PlatformMineralPricesMarquee from "./platform-mineral-prices-marquee/PlatformMineralPricesMarquee";
import { useConfig } from "../../contexts/config/ConfigContext";

export default function Header() {
  const { configState } = useConfig();
  const location = useLocation();

  return (
    <>
      <div
        className="absolute top-0 left-0 z-10 w-full overflow-hidden lg:overflow-visible"
        data-aos="slide-down"
        data-aos-duration="500"
      >
        <PlatformMineralPricesMarquee />
        <div className="">
          <div className="flex app-container items-center justify-between py-3 px-6 lg:px-0 z-50">
            <div
              data-aos="zoom-out"
              data-aos-duration="600"
              data-aos-delay="700"
            >
              <Link className="flex items-center gap-2" to={`/`}>
                <img
                  className="w-[64px] h-[64px]"
                  src={`/assets/logos/app_logo.svg`}
                  alt="app logo"
                />
                <h1
                  className={`text-xl font-bold ${
                    location.pathname === "/" ? "text-white" : "text-primary"
                  }`}
                >
                  {configState.language == "en" && (
                    <p>
                      <span class="text-warning">A</span>rab{" "}
                      <span class="text-warning">P</span>latform for{" "}
                      <span class="text-warning">F</span>uture{" "}
                      <span class="text-warning">M</span>inerals{" "}
                    </p>
                  )}
                  {configState.language == "ar" && (
                    <p>المنصة العربية لمعادن المستقبل</p>
                  )}
                </h1>
              </Link>
            </div>
            <div
              data-aos="zoom-out"
              data-aos-duration="500"
              data-aos-delay="600"
            >
              <Link to={`https://www.aidsmo.org/`} target="_blank">
                <img
                  className="w-[64px] h-[64px]"
                  src={`/assets/logos/aidsmo_logo.webp`}
                  alt="aidsmo logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <MobileNavbar />
        <DesktopNavbar />
      </div>
    </>
  );
}
