import { Link } from "react-router-dom";
import { SplideSlide } from "@splidejs/react-splide";

export default function CleanEnergyMineralsHomeSliderItem({
  mineral,
  language,
}) {
  return (
    // SplideSlide component for each mineral item
    <SplideSlide
      key={mineral.id}
      // CSS classes for styling
      className={`group overflow-hidden border-2 border-dashed border-muted hover:border-solid hover:border-warning hover:bg-muted cursor-pointer rounded-none transition duration-300 ease-in-out`}
    >
      {/* Link to the mineral detail page */}
      <Link
        to={`/clean-energy-minerals/${mineral.slug}/details`}
        // CSS classes for styling
        className={`flex flex-col items-center py-6 px-3`}
      >
        {/* Mineral image */}
        <img
          // CSS classes for styling and animation
          className={`h-[90px] group-hover:scale-125 transform transition duration-300 ease-in-out`}
          // Image source
          src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
          // Alternative text for accessibility
          alt={mineral.name_ar}
        />
        {/* Mineral name */}
        <h3 className={`text-lg font-semibold mt-3`}>
          {/* Displaying mineral name */}
          {mineral[`name_${language}`]?.replace("(", "").replace(")", "")}
        </h3>
        {/* Mineral symbol */}
        <h3 className={`text-lg font-bold text-warning`}>
          {/* Displaying mineral symbol */}
          {mineral.symbol}
        </h3>
      </Link>
    </SplideSlide>
  );
}
