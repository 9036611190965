import Hero from "../componenets/home/hero/Hero";
import PlatformNumbers from "../componenets/home/platform-numbers/PlatformNumbers";
import PlatformMessage from "../componenets/home/platform-message/PlatformMessage";
import CleanEnergyMineralsHome from "../componenets/home/clean-energy-minerals/CleanEnergyMineralsHome";
import CleanEnergyMineralsRegionHome from "../componenets/home/clean-energy-minerals-region-home/CleanEnergyMineralsRegionHome";
import CleanEnergyMineralsHomePrices from "../componenets/home/clean-energy-minerals/CleanEnergyMineralsHomePrices";
import ARCGISMap from "../componenets/home/ARCGISMap";
import PlatformOverview from "../componenets/home/overview/PlatformOverview";
import { Helmet } from "react-helmet";
import { useConfig } from "../contexts/config/ConfigContext";

export default function Home() {
  const { configState } = useConfig();
  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `
            اكتشف إمكانيات المعادن المستقبلية في المنطقة العربية من خلال أول قاعدة بيانات رقمية تفاعلية - منصة رائدة. استمتع بالتفاصيل التقنية حول المعادن الاستراتيجية، مثل تعاريفها، ووجودها في المنطقة، وفرص الاستثمار المتاحة في الدول العربية. احصل على فهم شامل حول الطلب العالمي، وأسعار المعادن، وسلاسل القيمة. انطلق بسهولة عبر الخريطة العربية باستخدام نافذتنا التفاعلية، المدعومة بـ Arc GIS Server، لتوفير بيانات مكانية حول المعادن المستقبلية والمعلومات ذات الصلة. اختبر الحدود الجديدة في استكشاف المعادن والاستثمار في العالم العربي من خلال منصتنا الرائدة، التي تغيّر تمامًا وجهة نظرك حول المعادن الاستراتيجية والحرجة.`;
      case "en":
        return `Discover the future of metals in the Arab region with the first interactive digital database – a groundbreaking platform. Dive into the technical details of strategic metals, including their definitions, presence in the region, and investment opportunities in Arab countries. Gain a comprehensive understanding of global demand, metal prices, and value chains. Navigate the Arab map effortlessly with our interactive window, powered by Arc GIS Server, providing spatial data on future metals and related information. Explore new frontiers in mineral exploration and investment in the Arab world through our cutting-edge platform, redefining your perspective on strategic and critical metals.`;

      default:
        return `
            اكتشف إمكانيات المعادن المستقبلية في المنطقة العربية من خلال أول قاعدة بيانات رقمية تفاعلية - منصة رائدة. استمتع بالتفاصيل التقنية حول المعادن الاستراتيجية، مثل تعاريفها، ووجودها في المنطقة، وفرص الاستثمار المتاحة في الدول العربية. احصل على فهم شامل حول الطلب العالمي، وأسعار المعادن، وسلاسل القيمة. انطلق بسهولة عبر الخريطة العربية باستخدام نافذتنا التفاعلية، المدعومة بـ Arc GIS Server، لتوفير بيانات مكانية حول المعادن المستقبلية والمعلومات ذات الصلة. اختبر الحدود الجديدة في استكشاف المعادن والاستثمار في العالم العربي من خلال منصتنا الرائدة، التي تغيّر تمامًا وجهة نظرك حول المعادن الاستراتيجية والحرجة.`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `المعادن المستقبلية,الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة ,قاعدة بيانات رقمية للمعادن, استكشاف المعادن في المنطقة العربية, استثمار في المعادن الاستراتيجية, أنظمة الطاقة الشمسية, تكنولوجيا طاقة الرياح, موارد الطاقة المستدامة, الطاقة المتجددة, تقنيات الطاقة النظيفة, توربينات الرياح, الطاقة الحرارية الجوفية, طاقة الكتلة الحيوية, الطاقة الكهرومائية, ابتكار الطاقة النظيفة `;
      case "en":
        return `Renewable Energy, Clean Energy Solutions, Solar Power Systems, Wind Energy Technology, Energy Resources, Sustainable Energy, Green Energy, Clean Energy Sources, Renewable Energy Technologies, Clean Energy Resources, Solar Energy, Wind Energy, Geothermal Energy, Biomass Energy, Energy Transition, Clean Energy Future, Mineral Resources, Energy Efficiency, Sustainable Resources, Eco-friendly Energy, Energy Sustainability, Clean Technology, Solar Panels, Renewable Energy Resources, Wind Turbines, Hydroelectric Energy, Green Technology, Clean Energy Innovation, Future Energy, Renewable Resources, Alternative Energy, Energy Conservation, future metals, digital metal database, Arab region mineral exploration, strategic metals investment, solar energy systems, wind energy technology, sustainable energy resources, renewable energy, clean energy technology, wind turbines, geothermal energy, biomass energy, hydropower, clean energy innovation`;
      default:
        return `المعادن المستقبلية,الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة ,قاعدة بيانات رقمية للمعادن, استكشاف المعادن في المنطقة العربية, استثمار في المعادن الاستراتيجية, أنظمة الطاقة الشمسية, تكنولوجيا طاقة الرياح, موارد الطاقة المستدامة, الطاقة المتجددة, تقنيات الطاقة النظيفة, توربينات الرياح, الطاقة الحرارية الجوفية, طاقة الكتلة الحيوية, الطاقة الكهرومائية, ابتكار الطاقة النظيفة `;
    }
  };

  return (
    <main id="IdHome">
      <Helmet>
        <title>APFM | المنصة العربية لمعادن المستقبل</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />
        {/* Open Graph tags for social media */}
        <meta
          property="og:title"
          content={"APFM | المنصة العربية لمعادن المستقبل"}
        />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content={`https://arabpfm.org/assets/logos/app_logo.svg`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="APFM | المنصة العربية لمعادن المستقبل"
        />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content={`https://arabpfm.org/assets/logos/app_logo.svg`}
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "APFM | المنصة العربية لمعادن المستقبل",
            description: getDescription(),
            url: window.location.href,
            image: `https://arabpfm.org/assets/logos/app_logo.svg`,
            publisher: {
              "@type": "Organization",
              name: configState.translation.PLATFORM_NAME,
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Hero />
      <PlatformNumbers />
      {/* <div className="app-container pb-14 overflow-hidden ">
        <div className="w-full h-[120px] border-black border-[2px]">
          <img
            className="object-fill w-full h-full"
            src="/assets/images/ad/Image4.png"
            alt=""
          />
        </div>
      </div> */}
      <PlatformMessage />
      <CleanEnergyMineralsHome />
      <CleanEnergyMineralsHomePrices />
      <CleanEnergyMineralsRegionHome />
      <ARCGISMap />

      <PlatformOverview />
    </main>
  );
}
