import { useEffect, useRef, useState } from "react";
import { CountUp } from "use-count-up";
import { useInView } from "react-intersection-observer";

// Component to display a single platform number card
export default function PlatformNumbersCard({ number, title, subTitle, icon }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      // Animation attributes for fading up
      data-aos={`fade-up`}
      data-aos-duration={`500`}
      // CSS classes for styling and responsive layout
      className={`group col-span-12 sm:col-span-6 lg:col-span-3 select-none`}
      ref={ref}
    >
      <div
        className={`h-full flex flex-col items-center justify-start border-2 border-muted px-3 py-12 lg:py-6 lg:px-2 sm:py-6 transform transition duration-300 ease-in-out`}
      >
        {/* Number icon */}
        <div className={`mb-1 w-32 h-32 flex items-start justify-center`}>
          <img
            // Animation for scaling on hover
            src={icon}
            alt={title}
          />
        </div>
        {/* Number */}
        <h3 className={`text-3xl font-bold`}>
          <CountUp
            isCounting={inView}
            start={0}
            end={number}
            duration={number < 100 ? 5 : 2}
          />
        </h3>
        {/* Title */}
        <p className={`mt-2 text-2xl sm:text-xl text-center`}>{title}</p>
        {/* Subtitle, if available */}
        {subTitle && <span className={`text-sm`}>({subTitle})</span>}
      </div>
    </div>
  );
}
