import React from "react";
import { useConfig } from "../../../contexts/config/ConfigContext";

/**
 * Component for rendering the hero paragraph based on the selected language.
 * This paragraph highlights the significance of mineral resources in the Arab region.
 */
export default function HeroParagraph() {
  const { configState } = useConfig(); // Accessing configuration state to determine the language

  // Content for Arabic language
  const arabicContent = (
    <>
      <p className={`leading-loose`}>أول منصة معلوماتية وتفاعلية</p>
      <p
        className={`text-[40px] text-warning lg:text-[48px] 2xl:text-[60px] ltr:font-bold rtl:font-black`}
      >
        <span>لمعادن</span>
        {` `}
        <span className={`text-primary`}>المستقبل</span>
      </p>
      <p>في المنطقة العربية</p>
    </>
  );

  // Content for English language
  const englishContent = (
    <>
      <p>First Information Platform for</p>
      <p
        className={`text-[40px] text-warning lg:text-[48px] 2xl:text-[60px] ltr:font-bold rtl:font-black`}
      >
        <span className={`text-primary`}>FUTURE</span> <span>MINERALS</span>
      </p>
      <p className={`normal-case`}>In the Arab Region</p>
    </>
  );

  // Determine which content to render based on the selected language
  const content =
    configState.language === "ar"
      ? arabicContent
      : configState.language === "en"
      ? englishContent
      : null;

  return (
    <div
      className={`mt-28 app-container absolute inset-0 flex items-center justify-center lg:justify-start`}
    >
      <div
        className={`text-[32px] lg:text-[40px] 2xl:text-[50px] rtl:font-bold ltr:font-semibold text-white mt-10 lg:mt-12 flex flex-col text-center lg:rtl:text-right lg:ltr:text-left lg:ltr:font-bold space-y-6`}
        data-aos={`fade-up`}
        data-aos-duration={`500`}
      >
        {content}
      </div>
    </div>
  );
}
