// Usage in PlatformOverviewBannerImage component
export default function PlatformOverviewBannerImage() {
  return (
    <div
      className={`h-[406px] duration-500 ease-in-out transform lg:rtl:group-hover:rounded-tr-[400px] lg:rtl:rounded-tr-[350px] lg:rtl:rounded-bl-[350px] lg:ltr:group-hover:rounded-tl-[400px] lg:ltr:rounded-tl-[350px] lg:ltr:rounded-br-[350px] w-full lg:w-1/2 overflow-hidden`}
    >
      <img
        data-aos={`fade-in`}
        data-aos-duration={`800`}
        data-aos-delay={`400`}
        className={`h-full w-full object-cover group-hover:scale-125`}
        src={`/assets/images/overview/overview-banner.webp`}
        alt={`overview-banner`}
      />
    </div>
  );
}
