import { useConfig } from "../../../contexts/config/ConfigContext";
import PlatformMessageParagraphe from "./PlatformMessageParagraphe";
import PlatformMessageAnimatedImage from "./PlatformMessageAnimatedImage";

// Component to display a message section based on the selected language
const PlatformMessage = () => {
  // Accessing language preference from the global configuration context
  const { configState } = useConfig();

  return (
    // Container for the message section
    <div className={`bg-muted relative`}>
      <div className={`app-container py-16 overflow-hidden px-6`}>
        {/* Flex container to arrange content in a row on large screens */}
        <div
          className={`flex flex-col space-y-12 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between`}
        >
          {/* Component to display the message paragraph based on the selected language */}
          <PlatformMessageParagraphe lng={configState.language} />
          {/* Component to display the animated image */}
          <PlatformMessageAnimatedImage />
        </div>
      </div>
    </div>
  );
};

export default PlatformMessage;
