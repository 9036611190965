import React, { useEffect, useState } from "react";
import Title from "../../UI/Title";
import CleanEnergyMineralsHomePriceCharts from "./clean-energy-minerals-home-price-charts/CleanEnergyMineralsHomePriceCharts";
import axiosClient from "../../../config/axios-client";
import { useConfig } from "../../../contexts/config/ConfigContext";
import CleanEnergyMineralsHomePriceChartsLoading from "./clean-energy-minerals-home-price-charts/CleanEnergyMineralsHomePriceChartsLoading";
import CleanEnergyMineralsHomePriceChartsError from "./clean-energy-minerals-home-price-charts/CleanEnergyMineralsHomePriceChartsError";
import { Link } from "react-router-dom";

export default function CleanEnergyMineralsHomePrices() {
  // Access configuration state using custom hook
  const { configState } = useConfig();

  // State variables to manage mineral prices data fetching and error handling
  const [mineralPrices, setMineralPrices] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Effect hook to fetch mineral prices data when component mounts
  useEffect(() => {
    // Function to fetch mineral prices data
    function fetchMineralsPrices() {
      // Reset error and loading states
      setIsError(false);
      setIsLoading(true);

      // Send request to API to fetch mineral prices data
      axiosClient
        .get(`/platform/minerals/prices/charts/home`)
        .then(({ data }) => {
          // Update state with fetched mineral prices data
          setMineralPrices(data.minerals);
        })
        .catch((error) => {
          // Set error state to true and log the error
          setIsError(true);
        })
        .finally(() => {
          // Set loading state to false
          setIsLoading(false);
        });
    }

    // Call the fetchMineralsPrices function when component mounts
    fetchMineralsPrices();
  }, []);

  // Render the component
  return (
    <div className="app-container pb-16 px-6 overflow-hidden">
      {/* Title component */}

      <Title className={`mt-12`} data-aos="fade-left" data-aos-duration="500">
        {configState.translation.HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES}
      </Title>
      <div className={`flex justify-between`}>
        <div
          className={`text-center`}
          data-aos={`zoom-in`}
          data-aos-duration={`500`}
          data-aos-delay={`800`}
        >
          {/* Link to navigate to the page displaying more minerals */}
          <Link to={`/prices`} className={`flex`}>
            {/* Button to show more minerals */}
            <span
              className={`px-4 py-2 border-2 font-bold border-muted hover:border-warning hover:bg-muted transform transition duration-300 ease-in-out`}
            >
              {configState.translation.HOME_PAGE_CLEAN_MINERALS_ENERGY_MORE}
            </span>
          </Link>
        </div>
        <div></div>
      </div>
      {/* Loading component to show loading animation */}
      <CleanEnergyMineralsHomePriceChartsLoading isLoading={isLoading} />
      {/* Error component to show error message if fetching fails */}
      <CleanEnergyMineralsHomePriceChartsError isError={isError} />
      {/* Component to display mineral prices charts */}
      <CleanEnergyMineralsHomePriceCharts mineralPrices={mineralPrices} />
    </div>
  );
}
