import HeroParagraph from "./HeroParagraph";
import HeroVideo from "./HeroVideo";

export default function Hero() {
  return (
    <div className={`relative h-screen z-0`}>
      {/* Background Video */}
      <HeroVideo />
      {/* Content */}
      <HeroParagraph />
    </div>
  );
}
