import { Link } from "react-router-dom";
import Title from "../UI/Title";
import { SlSizeFullscreen } from "react-icons/sl";
import { useConfig } from "../../contexts/config/ConfigContext";

// Component to display an ARCGIS map
export default function ARCGISMap() {
  const { configState } = useConfig();

  return (
    <div className={`app-container px-6 py-14 lg:px-0 overflow-hidden`}>
      {/* Section title */}
      <div className={`flex justify-between items-center`}>
        <Title
          data-aos={`fade-left`}
          data-aos-duration={500}
          data-aos-delay={600}
        >
          {/* Displaying the title */}
          {configState.translation.HOME_PAGE_REGION_MAP_TITLE}
        </Title>
        {/* Link to view the map in fullscreen */}
        <div
          className={`group`}
          data-aos={`fade-right`}
          data-aos-duration={500}
          data-aos-delay={600}
        >
          <Link className={`group-hover:text-warning`} to={`/region/map`}>
            {/* Icon for fullscreen */}
            <SlSizeFullscreen size={32} />
          </Link>
        </div>
      </div>
      {/* ARCGIS Map iframe */}
      <div className={`grid grid-cols-12 gap-[16px] w-full`}>
        <div
          data-aos={`fade-up`}
          data-aos-duration={500}
          data-aos-delay={1000}
          className={`col-span-12`}
        >
          <iframe
            title={`الخريطة العربية`}
            className={`h-[532px] tv:h-[1000px] w-full border`}
            src={`https://gis.aidmo.org/portal/apps/webappviewer/index.html?id=8ec7ff8605e046459da6ac0643c3f440`}
          />
        </div>
      </div>
    </div>
  );
}
