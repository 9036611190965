import React, { useRef } from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import { cleanEnergyMineralsOpportunitiesMineralFilterOptions } from "../../config/sliders/slider-option";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useSearchParams } from "react-router-dom";

export default function CleanEnergyMineralsOpportunitiesMineralFilter({
  minerals,
}) {
  const { configState } = useConfig();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMineralId = searchParams.get("mineral_id");

  const mineralsSilderRef = useRef(null);

  const handleOnClickNextButton = () => {
    mineralsSilderRef.current.splide.go("+1");
  };

  const handleOnClickPreviousButton = () => {
    mineralsSilderRef.current.splide.go("-1");
  };

  function handleOnClickMineral(mineralId) {
    const updatedSearchParams = new URLSearchParams(searchParams);

    if (!selectedMineralId) {
      // If there's no mineral_id in the URL, set it to the given mineralId
      updatedSearchParams.set("mineral_id", mineralId.toString());
      setSearchParams(updatedSearchParams);
    } else if (mineralId == selectedMineralId) {
      // If the given mineralId matches the selectedMineralId, reset the filter
      updatedSearchParams.delete("mineral_id");
      setSearchParams(updatedSearchParams);
    } else {
      updatedSearchParams.set("mineral_id", mineralId.toString());
      setSearchParams(updatedSearchParams);
    }
  }

  return (
    <div className="col-span-1 w-full">
      <div className="flex justify-end">
        <div className="flex space-x-4 rtl:flex-row-reverse mb-3">
          <button
            onClick={handleOnClickPreviousButton}
            title={configState.translation.PREVIOUS}
            aria-label="Previous"
            className="flex"
            data-aos="zoom-in"
            data-aos-duration={700}
            data-aos-delay={500}
          >
            <span className="border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out">
              <GrFormPrevious className="w-6 h-6 tv:w-12" />
            </span>
          </button>
          <button
            onClick={handleOnClickNextButton}
            title={configState.translation.NEXT}
            aria-label="Next"
            className="flex"
            data-aos="zoom-in"
            data-aos-duration={700}
            data-aos-delay={500}
          >
            <span className="border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out">
              <GrFormNext className="w-6 h-6 tv:w-12" />
            </span>
          </button>
        </div>
      </div>
      <div dir="ltr">
        <Splide
          ref={mineralsSilderRef}
          options={cleanEnergyMineralsOpportunitiesMineralFilterOptions}
          aria-label="Minerals"
        >
          {minerals.map((mineral) => {
            return (
              <SplideSlide
                className="rounded-none cursor-pointer"
                data-aos="fade-right"
                data-aos-duration="500"
                key={mineral.id}
              >
                <div
                  onClick={() => handleOnClickMineral(mineral.id)}
                  className={`border-2 py-3 px-2 flex items-center flex-col space-y-2 group transform transition duration-300 ease-in-out 
                                        ${
                                          selectedMineralId &&
                                          mineral.id == selectedMineralId
                                            ? "bg-warning/20 border-warning border-solid"
                                            : "border-muted border-dashed hover:bg-warning/20 hover:border-warning hover:border-solid"
                                        }`}
                >
                  <div>
                    <img
                      className="h-[64px]"
                      src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                      alt=""
                    />
                  </div>
                  <h3 className="text-center capitalize">
                    {mineral[`name_${configState.language}`]}
                  </h3>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
}
