import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ConfigProvider } from './contexts/config/ConfigContext';
import router from './config/router';

import "./assets/css/app.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( <React.StrictMode>
    <ConfigProvider>
      <RouterProvider router={router}/>
    </ConfigProvider> 
  </React.StrictMode>
);