// Component to display an error message when platform numbers fail to load
export default function PlatformNumbersError({ isError }) {
  if (isError) {
    return (
      <div
        className={`flex items-center justify-center border border-muted p-8 h-[258px]`}
      >
        {/* Displaying the error message */}
        <h2 className={`font-bold text-xl`}>
          .حدث خطأ ما! الرجاء المحاولة مرة أخرى في وقت لاحق
        </h2>
      </div>
    );
  }
}
