export default function CleanEnergyMineralsHomePriceChartsLoading({
  isLoading,
}) {
  // Render loading animation if isLoading is true
  if (isLoading) {
    return (
      // Grid layout for loading animation
      <div className={`grid grid-cols-3 gap-6 mt-6`}>
        {/* Loading animation for small screens */}
        <div
          className={`animate-pulse flex flex-col items-center justify-center space-y-1 border border-light col-span-1 w-full h-[258px] bg-light`}
        />
        {/* Loading animation for small and medium screens */}
        <div
          className={`animate-pulse sm:flex flex-col items-center justify-center space-y-1 border border-light col-span-1 w-full h-[258px] bg-light`}
        />
        {/* Loading animation for small, medium, and large screens */}
        <div
          className={`animate-pulse md:flex flex-col items-center justify-center space-y-1 border border-light col-span-1 w-full h-[258px] bg-light`}
        />
      </div>
    );
  }
}
