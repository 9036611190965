import React, { useEffect, useState } from "react";
import CleanEnergyMineralsHomeSlider from "./clean-energy-minerals-home-slider/CleanEnergyMineralsHomeSlider";
import { useConfig } from "../../../contexts/config/ConfigContext";
import axiosClient from "../../../config/axios-client";
import Title from "../../UI/Title";
import CleanEnergyMineralsHomeSliderLoading from "./clean-energy-minerals-home-slider/CleanEnergyMineralsHomeSliderLoading";
import CleanEnergyMineralsHomeSliderError from "./clean-energy-minerals-home-slider/CleanEnergyMineralsHomeSliderError";

// Component to display the section on clean energy minerals
export default function CleanEnergyMineralsHome() {
  // Access configuration state using custom hook
  const { configState } = useConfig();

  // State variables to manage mineral data fetching and error handling
  const [minerals, setMinerals] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Effect hook to fetch mineral data when component mounts
  useEffect(() => {
    // Function to fetch minerals data
    async function fetchMinerals() {
      // Reset error and loading states
      setIsError(false);
      setIsLoading(true);

      try {
        // Send request to API to fetch minerals data
        const response = await axiosClient.get(
          `/platform/minerals/clean_energy/home`
        );
        // Update state with fetched minerals data
        setMinerals(response.data.minerals);
      } catch (error) {
        // Set error state to true and log the error
        setIsError(true);
        console.error("Error fetching minerals:", error);
      } finally {
        // Set loading state to false
        setIsLoading(false);
      }
    }

    // Call the fetchMinerals function when component mounts
    fetchMinerals();
  }, []);

  // Render the component
  return (
    <div className="app-container pt-16 px-6 overflow-hidden">
      {/* Title component */}
      <Title data-aos="fade-left" data-aos-duration="500">
        {configState.translation.HOME_PAGE_CLEAN_MINERALS_ENERGY_TITLE}
      </Title>
      {/* Loading component to show loading animation */}
      <CleanEnergyMineralsHomeSliderLoading isLoading={isLoading} />
      {/* Error component to show error message if fetching fails */}
      <CleanEnergyMineralsHomeSliderError isError={isError} />
      {/* Component to display clean energy minerals slider */}
      <CleanEnergyMineralsHomeSlider minerals={minerals} />
    </div>
  );
}
