import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../config/axios-client";
import Spacing from "../componenets/UI/Spacing";
import HeadingSmall from "../componenets/UI/HeadingSmall";
import { useConfig } from "../contexts/config/ConfigContext";
import CleanEnergyMineralDetailsloading from "../componenets/clean-energy-mineral-details/CleanEnergyMineralDetailsloading";
import { Helmet } from "react-helmet";
import { IoIosArrowBack } from "react-icons/io";
import { useSearchParams } from "react-router-dom";

export default function CleanEnergyMineralDetails() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const { configState } = useConfig();
  const navigate = useNavigate();
  const { mineral_slug } = useParams();
  const [mineral, setMineral] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMineralBySlug() {
      setIsLoading(true);
      axiosClient
        .get(`/platform/minerals/clean_energy/${mineral_slug}/details`)
        .then(({ data }) => {
          setMineral(data.mineral);
        })
        .catch((error) => {
          // Handle error, e.g., redirect to error page
          navigate("/not-found", {
            replace: true,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    fetchMineralBySlug();
  }, [mineral_slug, navigate]);

  if (isLoading) {
    return <CleanEnergyMineralDetailsloading />;
  }
  if (mineral) {
    const title =
      configState.language == "en"
        ? `${mineral[`name_${configState.language}`]} - ${
            mineral.symbol
          } | Arab Platform for Future Minerals`
        : `${mineral[`name_${configState.language}`]} - ${
            mineral.symbol
          } | المنصة العربية لمعادن المستقبل`;
    const description =
      configState.language == "en"
        ? `Learn all about ${mineral[`name_${configState.language}`]} (${
            mineral.symbol
          }), including its history, source, and compounds.`
        : `تعرف على كل شيء عن ${mineral[`name_${configState.language}`]} (${
            mineral.symbol
          }), بما في ذلك تاريخه ومصدره ومركباته.`;
    return (
      <>
        {/* Meta tags and Open Graph (OG) for SEO and social media */}
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content={`mineral, ${mineral.symbol}, ${
              mineral[`name_${configState.language}`]
            }, compounds, history, source`}
          />

          {/* Open Graph tags for social media */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />

          {/* Twitter Card tags for social media */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`}
          />

          {/* Schema.org structured data for better SEO */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              name: title,
              description: description,
              url: window.location.href,
              image: `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`,
              publisher: {
                "@type": "Organization",
                name: configState.translation.PLATFORM_NAME,
                url: "https://arabpfm.org/",
                logo: {
                  "@type": "ImageObject",
                  url: "https://arabpfm.org/assets/logos/app_logo.svg",
                },
              },
            })}
          </script>
        </Helmet>

        <Spacing />
        <div className={`py-16 px-6 bg-white overflow-hidden`}>
          <div className={`app-container lg:px-0`}>
            <div className="flex">
              <Link
                to={page ? `/clean-energy-minerals/${page}` : "/"}
                className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
              >
                <IoIosArrowBack size={32} />
              </Link>
            </div>
            <article>
              <div
                className={`mb-6 flex flex-col lg:flex-row lg:justify-between lg:items-end lg:space-x-4 space-y-4 lg:space-y-0`}
              >
                <div>
                  {/* Mineral name and symbol */}
                  <h2
                    className={`text-4xl font-bold lg:text-5xl lg:border-b-4 lg:border-warning capitalize`}
                  >
                    {mineral[`name_${configState.language}`]} (
                    <span className="text-warning capitalize">
                      {mineral.symbol}
                    </span>
                    )
                  </h2>
                </div>
                <div
                  className={`active-animation p-4 flex items-center justify-center relative`}
                >
                  {/* Mineral image */}
                  <img
                    width={300}
                    height={300}
                    className={`hover:scale-150 transform transition duration-300 ease-in-out`}
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`}
                    alt={`${mineral[`name_${configState.language}`]} image`}
                  />
                </div>
              </div>
              {/* Mineral description */}
              <div
                className={`mb-6`}
                dangerouslySetInnerHTML={{
                  __html: mineral[`body_${configState.language}`],
                }}
              />
              {/* Mineral history */}
              <HeadingSmall>
                {configState.translation.SINGLE_MINERAL_PAGE_HISTORY}{" "}
                {mineral[`name_${configState.language}`]}
              </HeadingSmall>
              <div
                className={`mb-6`}
                dangerouslySetInnerHTML={{
                  __html: mineral[`history_body_${configState.language}`],
                }}
              />
              {/* Mineral source */}
              <HeadingSmall>
                {configState.translation.SINGLE_MINERAL_PAGE_SOURCE.replace(
                  "####",
                  mineral[`name_${configState.language}`]
                )}
              </HeadingSmall>
              <div className={`mb-6 flex`}>
                <div className="border-corner p-6 w-full md:w-auto flex justify-center">
                  {/* Source image */}
                  <img
                    width={300}
                    height={300}
                    className={`hover:scale-150 transform transition duration-300 ease-in-out`}
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.source_image}`}
                    alt={`${mineral[`name_${configState.language}`]} source`}
                  />
                </div>
              </div>
              {/* Source details */}
              <div
                className={`text-lg leading-loose mb-6 !text-justify`}
                dangerouslySetInnerHTML={{
                  __html: mineral[`source_body_${configState.language}`],
                }}
              />
              {/* Mineral compounds */}
              {mineral.compound_body_ar && (
                <div>
                  <HeadingSmall>
                    {configState.language === "ar"
                      ? `${
                          configState.translation.SINGLE_MINERAL_PAGE_COMPOUNDS
                        } ${mineral[`name_${configState.language}`]}`
                      : `${mineral[`name_${configState.language}`]} ${
                          configState.translation.SINGLE_MINERAL_PAGE_COMPOUNDS
                        }`}
                  </HeadingSmall>
                  <div
                    className={`text-lg leading-loose mb-6 !text-justify`}
                    dangerouslySetInnerHTML={{
                      __html: mineral[`compound_body_${configState.language}`],
                    }}
                  />
                </div>
              )}
              {/* Studies link */}
              {mineral.studies &&
                mineral.studies[`link_${configState.language}`] && (
                  <div
                    className={`rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-4 ltr:pl-4`}
                  >
                    <h4 className={`mb-1 font-bold`}>
                      {configState.translation.FOR_MORE_INFORMATION}
                    </h4>
                    {/* Link to studies file */}
                    <Link
                      target={`_blank`}
                      className={`hover:underline-none hover:text-secondary`}
                      to={mineral.studies[`link_${configState.language}`]}
                    >
                      {configState.translation.DOWNLOAD_STUDIES_FILE}
                    </Link>
                  </div>
                )}
            </article>
          </div>
        </div>
      </>
    );
  }
}
