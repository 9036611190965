import moment from "moment";

export default function CustomChartTooltip({ active, payload, unit }) {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;
    return (
      <div className="bg-white py-2 px-4 text-left border-primary" dir="ltr">
        <p>
          <span className="font-bold">Date :</span>{" "}
          <span>{moment(dataPoint.date).format("YYYY MMM DD")}</span>
        </p>
        <p>
          <span className="font-bold">Price :</span>{" "}
          <span>
            $ {parseFloat(dataPoint.price).toFixed(4)}/{unit}
          </span>
        </p>
      </div>
    );
  }

  return null;
}
