import React, { useEffect, useRef, useState } from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import CleanEnergyMineralsRegionDetailsMapCountries from "./CleanEnergyMineralsRegionDetailsMapCountries";
import CleanEnergyMineralsRegionDetailsCountry from "./CleanEnergyMineralsRegionDetailsCountry";

const WIDTH = 1522;
const HEIGHT = 813;
export default function CleanEnergyMineralsRegionDetailsMap({ mineral }) {
  const { configState } = useConfig();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: WIDTH,
    height: HEIGHT,
  });

  const containerRef = useRef(null);
  const imageMapRef = useRef(null);

  function handleOnResize() {
    if (imageMapRef.current && imageMapRef.current.width) {
      setImageSize({
        width: imageMapRef.current.width,
        height: imageMapRef.current.height,
      });
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleOnResize);
    return () => window.removeEventListener("resize", handleOnResize);
  }, []);

  if (mineral && mineral.countries[0].id !== 22) {
    return (
      <>
        <h2 className={`text-3xl font-bold md:text-5xl capitalize`}>
          {mineral[`name_${configState.language}`]} (
          <span className={`text-warning`}>{mineral.symbol}</span>)
        </h2>
        <div className={`relative w-full h-full mt-6 p-6 border`}>
          <div
            style={{
              zIndex: 0,
            }}
            className={`m-6 absolute top-0 left-0 w-full h-full`}
          >
            <CleanEnergyMineralsRegionDetailsMapCountries
              countries={mineral.countries}
              language={configState.language}
              imageSize={imageSize}
              selectedCountry={selectedCountry}
              onChange={(country) => {
                setSelectedCountry(country);
                containerRef.current.scrollIntoView({
                  behavior: `smooth`,
                  block: `start`,
                });
              }}
            />
          </div>
          <img
            style={{
              zIndex: -1,
            }}
            ref={imageMapRef}
            onLoad={handleOnResize}
            src={`/assets/images/region_map.webp`}
            alt={`Map showing distribution of ${
              mineral[`name_${configState.language}`]
            } mineral`}
          />
          <div ref={containerRef} />
          <CleanEnergyMineralsRegionDetailsCountry
            country={selectedCountry}
            language={configState.language}
            sourceLabel={configState.translation.SOURCE}
          />
        </div>
      </>
    );
  }
}
