import React, { useEffect, useRef, useState } from "react";
import { useConfig } from "../contexts/config/ConfigContext";
import Spacing from "../componenets/UI/Spacing";
import Title from "../componenets/UI/Title";
import PriceChartSelectType from "../componenets/minerals-prices/PriceChartSelectType";
import axiosClient from "../config/axios-client";
import MineralPricesChart from "../componenets/minerals-prices/MineralPricesChart";
import MineralPricesTimeIntervalSelect from "../componenets/minerals-prices/MineralPricesTimeIntervalSelect";
import MineralPricesMineralsSelect from "../componenets/minerals-prices/MineralPricesMineralsSelect";
import MineralPricesChartLoading from "../componenets/minerals-prices/MineralPricesChartLoading";
import moment from "moment";
import { BiSolidUpArrow } from "react-icons/bi";
import { GoDash } from "react-icons/go";

export default function MineralsPrices() {
  const { configState } = useConfig();

  const chartRef = useRef(null);
  const [chartType, setChartType] = useState("AREA");
  const [chartInterval, setChartInterval] = useState("1M");

  const [selectedMineral, setSelectedMineral] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [mineral, setMineral] = useState();

  function handleMineralPricesMineralsSelect(selectedMineral) {
    setMineral(null);
    setSelectedMineral(selectedMineral);
  }

  useEffect(() => {
    function fetchMineral() {
      // Base URL
      let url = `/platform/minerals/prices/${selectedMineral.value}`;

      // Current date
      const now = moment(); // Current date and time

      // Add URL parameters based on chartInterval
      if (chartInterval === "1M") {
        const startDate = now.clone().subtract(1, "month"); // 1 month earlier
        const endDate = now; // Current date

        url += `/started/${startDate.format(
          "YYYY-MM-DD"
        )}/ended/${endDate.format("YYYY-MM-DD")}`;
      } else if (chartInterval === "3M") {
        const startDate = now.clone().subtract(3, "months"); // 3 months earlier
        const endDate = now; // Current date

        url += `/started/${startDate.format(
          "YYYY-MM-DD"
        )}/ended/${endDate.format("YYYY-MM-DD")}`;
      } else {
        // Default interval (fallback option)
        const startDate = now.clone().subtract(30, "days"); // 30 days earlier
        const endDate = now; // Current date

        url += `/started/${startDate.format(
          "YYYY-MM-DD"
        )}/ended/${endDate.format("YYYY-MM-DD")}`;
      }

      axiosClient
        .get(url)
        .then(({ data }) => {
          setMineral(data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
          chartRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
    }
    if (selectedMineral) {
      setIsLoading(true);
      fetchMineral();
    }
  }, [selectedMineral, chartInterval]);

  return (
    <>
      <Spacing />
      <div
        ref={chartRef}
        className={`app-container py-16 px-6 lg:px-0 bg-white`}
      >
        <Title data-aos={`fade-left`} data-aos-duration={`500`}>
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES
          }
        </Title>
        <div
          className={`flex lg:flex-row flex-col  lg:items-end justify-between mb-6`}
        >
          {mineral ? (
            <div>
              <div
                className={`flex items-center space-x-2 rtl:space-x-reverse mb-4`}
              >
                <img
                  className={`w-20 h-20`}
                  src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${selectedMineral.image}`}
                  alt={selectedMineral.image}
                />
                <h3 className={`text-5xl font-bold capitalize`}>
                  {selectedMineral.label}
                </h3>
              </div>
              <div
                className={`flex items-center space-x-4 rtl:space-x-reverse `}
              >
                <h2 dir={`ltr`} className={`text-4xl font-bold`}>
                  $
                  <span className={`text-warning`}>
                    {mineral.latest.price.toFixed(4)}
                  </span>
                  /{mineral.latest.unit}
                </h2>
                <div className={`flex text-2xl`}>
                  (
                  {mineral.latest.change > 0 && (
                    <div
                      className={`flex items-center space-x-2 rtl:space-x-reverse text-success group-hover:text-white`}
                    >
                      <div dir="ltr">{mineral.latest.change.toFixed(5)} %</div>
                      <BiSolidUpArrow
                        className={`${
                          mineral.latest.change > 0 ? "" : "rotate-180"
                        }`}
                      />
                    </div>
                  )}
                  {mineral.latest.change < 0 && (
                    <div
                      className={`flex items-center space-x-2 rtl:space-x-reverse text-danger group-hover:text-white`}
                    >
                      <div dir="ltr">{mineral.latest.change.toFixed(5)} %</div>
                      <BiSolidUpArrow
                        className={`${
                          mineral.latest.change > 0 ? "" : "rotate-180"
                        }`}
                      />
                    </div>
                  )}
                  {mineral.latest.change == 0 && (
                    <div
                      className={`flex items-center space-x-2 rtl:space-x-reverse `}
                    >
                      <div dir="ltr">0.00 %</div>
                      <GoDash strokeWidth={2} />
                    </div>
                  )}
                  )
                </div>
              </div>

              <p>{moment(mineral.latest.date).format("MMM DD HH:mmA")}</p>
            </div>
          ) : (
            <div></div>
          )}
          <div
            className={`flex lg:flex-row flex-col lg:items-end py-2 lg:space-x-4 lg:rtl:space-x-reverse space-y-4 lg:space-y-0`}
          >
            <PriceChartSelectType onChange={(type) => setChartType(type)} />
            <MineralPricesTimeIntervalSelect
              onChange={(type) => setChartInterval(type)}
            />
            <MineralPricesMineralsSelect
              onChange={handleMineralPricesMineralsSelect}
              language={configState.language}
            />
          </div>
        </div>
        <MineralPricesChartLoading isLoading={isLoading} />
        <MineralPricesChart type={chartType} mineral={mineral} />
      </div>
    </>
  );
}
