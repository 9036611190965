import moment from "moment";
import {
  Area,
  AreaChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  Line,
  LineChart,
  BarChart,
  Brush,
  ReferenceLine,
  CartesianGrid,
  Text,
} from "recharts";
import CustomChartTooltip from "../UI/CustomChartTooltip";

const formatValue = (value) => {
  // Parse the value as a number
  const num = parseFloat(value);
  // Limit to a maximum of 5 decimal places
  const limitedDecimals = num.toFixed(5);

  // Remove trailing zeros and unnecessary decimal points
  const cleanedValue = limitedDecimals.replace(/(\.0+|0+)$/, "");

  return cleanedValue;
};

const CustomTick = (props) => {
  const { x, y, payload } = props;
  const formattedValue = `$${formatValue(payload.value)}`; // Format value with prefix "$" and three decimal places
  return (
    <Text x={x - 48} y={y} angle={0} fontSize={14} fill="#067697">
      {formattedValue}
    </Text>
  );
};

export default function MineralPricesChart({ mineral, type, unit }) {
  if (mineral)
    return (
      <div className={`h-[600px]`} dir={`ltr`}>
        <ResponsiveContainer>
          {type === "AREA" && (
            <AreaChart
              margin={{ top: 20, right: 0, bottom: 20 }}
              data={mineral.prices}
            >
              {/* CartesianGrid for X-axis only */}
              <CartesianGrid
                strokeDasharray="5 5"
                stroke="#191A23"
                strokeWidth={0.2}
                vertical={false}
              />

              {/* Add a horizontal ReferenceLine to the Y-axis */}
              <ReferenceLine y={0} stroke="#067697" strokeDasharray="3 3" />

              <XAxis
                dataKey="date"
                tickFormatter={(tick) => moment(tick).format("MMM. DD")}
                stroke="#067697"
                strokeWidth={0.5}
              />

              {/* YAxis with color and opacity */}
              <YAxis
                domain={["auto", "auto"]}
                tick={<CustomTick />}
                stroke="#067697"
                strokeWidth={0}
                orientation="left"
                padding={{ top: 40 }}
              />

              <Tooltip
                content={<CustomChartTooltip unit={mineral.latest.unit} />}
              />

              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#dfb662" stopOpacity={1} />
                  <stop offset="95%" stopColor="#dfb662" stopOpacity={0.12} />
                </linearGradient>
              </defs>

              {/* Applying the gradient */}
              <Area
                type="linear"
                dataKey="price"
                stroke="#067697"
                fill="url(#colorUv)"
                strokeWidth={2}
              />

              <Brush dataKey="name" height={40} stroke="#067697" />
            </AreaChart>
          )}

          {type === "BAR" && (
            <BarChart
              margin={{ top: 20, right: 0, bottom: 20 }}
              data={mineral.prices}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#dfb662" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#dfb662" stopOpacity={0.6} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="colorStrokeUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="10%" stopColor="#067697" stopOpacity={0.9} />
                  <stop offset="98%" stopColor="#067697" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <Bar
                dataKey="price"
                fill="#dfb662"
                sssssstroke="url(#colorStrokeUv)"
                strokeWidth={2}
              />
              <Brush dataKey="name" height={40} stroke="#067697" />
              {/* CartesianGrid for X-axis only */}
              <CartesianGrid
                strokeDasharray="5 5"
                stroke="#191A23"
                strokeWidth={0.2}
                vertical={false}
              />

              {/* Add a horizontal ReferenceLine to the Y-axis */}
              <ReferenceLine y={0} stroke="#067697" strokeDasharray="3 3" />

              <XAxis
                dataKey="date"
                tickFormatter={(tick) => moment(tick).format("MMM. DD")}
                stroke="#067697"
                strokeWidth={0.5}
              />

              {/* YAxis with color and opacity */}
              <YAxis
                domain={["auto", "auto"]}
                tick={<CustomTick />}
                stroke="#067697"
                strokeWidth={0}
                orientation="left"
                padding={{ top: 40 }}
              />

              <Tooltip
                content={<CustomChartTooltip unit={mineral.latest.unit} />}
              />
            </BarChart>
          )}

          {type === "LINE" && (
            <LineChart
              margin={{ top: 20, right: 0, bottom: 20 }}
              data={mineral.prices}
            >
              <Line
                type="linear"
                dataKey="price"
                stroke="#dfb662"
                strokeWidth={2}
              />

              {/* CartesianGrid for X-axis only */}
              <CartesianGrid
                strokeDasharray="5 5"
                stroke="#191A23"
                strokeWidth={0.2}
                vertical={false}
              />

              {/* Add a horizontal ReferenceLine to the Y-axis */}
              <ReferenceLine y={0} stroke="#067697" strokeDasharray="3 3" />

              <XAxis
                dataKey="date"
                tickFormatter={(tick) => moment(tick).format("MMM. DD")}
                stroke="#067697"
                strokeWidth={0.5}
              />

              {/* YAxis with color and opacity */}
              <YAxis
                domain={["auto", "auto"]}
                tick={<CustomTick />}
                stroke="#067697"
                strokeWidth={0}
                orientation="left"
                padding={{ top: 40 }}
              />

              <Tooltip
                content={<CustomChartTooltip unit={mineral.latest.unit} />}
              />
              <Brush dataKey="name" height={40} stroke="#067697" />
            </LineChart>
          )}
        </ResponsiveContainer>
      </div>
    );
}
