import CleanEnergyMineralsTechnologiesGrid from "../componenets/clean-energy-mineral-technologies/CleanEnergyMineralsTechnologiesGrid";
import Title from "../componenets/UI/Title";
import { useConfig } from "../contexts/config/ConfigContext";

export default function CleanEnergyMineralsTechnologies() {
  const { configState } = useConfig();
  return (
    <>
      <Title data-aos={`fade-in`} data-aos-duration={`500`}>
        {
          configState.translation
            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES
        }
      </Title>
      <CleanEnergyMineralsTechnologiesGrid />
    </>
  );
}
