import React from "react";

export default function CleanEnergyMineralsRegionProductionPowerBI({
  language,
}) {
  if (language === "ar") {
    return (
      <iframe
        data-aos="fade-down"
        data-aos-duration="500"
        title={`الانتاج العربي`}
        className={`border-2 border-muted w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]`}
        src={`https://app.powerbi.com/view?r=eyJrIjoiYjY2NDIzZjQtYTRiYS00OTdhLTliNWQtNWM4OWY3NTFhNWE0IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
      />
    );
  }

  if (language === "en") {
    return (
      <iframe
        data-aos="fade-down"
        data-aos-duration="500"
        title={`Clean Energy Minerals Region Production PowerBI`}
        className={`border-2 border-muted w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]`}
        src={`https://app.powerbi.com/view?r=eyJrIjoiODA2OTkyYjEtNjU3Yi00ZDNhLTkyZDEtMGJiM2U4NzQzMDA4IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
      />
    );
  }
}
