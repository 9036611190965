import { useEffect, useLayoutEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";
import Header from "../componenets/includes/Header";
import MobileFooter from "../componenets/includes/mobile/MobileFooter";
import DesktopFooter from "../componenets/includes/DesktopFooter";
import BackToTopButton from "../componenets/UI/BackToTopButton";
import { useConfig } from "../contexts/config/ConfigContext";
import Aos from "aos";

export default function Layout() {
  const { configState } = useConfig();
  const location = useLocation();

  useEffect(() => {
    Aos.init({
      once: true,
    });
    return () => {};
  }, []);
  

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div dir={configState.language === "ar" ? "rtl" : "ltr"}>
      <Header lng={configState.language} />
      <Outlet />
      <MobileFooter />
      <DesktopFooter />
      <BackToTopButton />
    </div>
  );
}
