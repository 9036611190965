export default function RegionMap() {
  return (
    <div className="w-full h-screen relative">
      <iframe
        title="المنصة العربية لمعادن المستقبل map"
        className="w-full h-full"
        src="https://gis.aidmo.org/portal/apps/webappviewer/index.html?id=8ec7ff8605e046459da6ac0643c3f440"
      />
    </div>
  );
}
