import React, { useEffect, useState } from "react";
import Spacing from "../componenets/UI/Spacing";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useConfig } from "../contexts/config/ConfigContext";
import axiosClient from "../config/axios-client";
import CleanEnergyMineralsRegionDetailsLoading from "../componenets/clean-energy-mineral-region-details/CleanEnergyMineralsRegionDetailsLoading";
import CleanEnergyMineralsRegionDetailsMap from "../componenets/clean-energy-mineral-region-details/CleanEnergyMineralsRegionDetailsMap";
import CleanEnergyMineralsRegionDetailsParagraph from "../componenets/clean-energy-mineral-region-details/CleanEnergyMineralsRegionDetailsParagraph";
import { Helmet } from "react-helmet";
import { IoIosArrowBack } from "react-icons/io";

export default function CleanEnergyMineralsRegionDetails() {
  const { configState } = useConfig();
  const { mineral_slug } = useParams();
  const navigate = useNavigate();

  const [mineral, setMineral] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMineralBySlug() {
      try {
        const { data } = await axiosClient.get(
          `/platform/minerals/clean_energy/${mineral_slug}/region/details`
        );
        setMineral(data.mineral);
      } catch (error) {
        navigate("/not-found", {
          replace: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchMineralBySlug();
  }, [mineral_slug]);

  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `${
          mineral
            ? `${mineral[`name_${configState.language}`]} (${mineral.symbol}) -`
            : ""
        } المعادن في المنطقة العربية | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `${
          mineral
            ? `${mineral[`name_${configState.language}`]} (${mineral.symbol}) -`
            : ""
        } Minerals in the Arab Region | Arab Platform for Future Minerals`;
      default:
        return `${
          mineral
            ? `${mineral[`name_${configState.language}`]} (${mineral.symbol}) -`
            : ""
        } المعادن في المنطقة العربية | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
      case "en":
        return `Explore the world of minerals in the Arab region through the Arab Platform for the Future of Minerals. We offer insights and investment opportunities in the mining sector, focusing on the development and exploration of mineral resources. Learn about the latest technologies in mineral extraction and how to develop metal industries for sustainable growth`;
      default:
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `المعادن في المنطقة العربية, المنصة العربية لمعادن المستقبل, التعدين في العالم العربي, الصناعات المعدنية العربية, فرص الاستثمار في التعدين, استكشاف الموارد المعدنية, تطوير الموارد الطبيعية, التكنولوجيا في التعدين, مستقبل التعدين في الشرق الأوسط, استدامة التعدين, استخراج المعادن في الوطن العربي, تعزيز التعاون العربي في مجال التعدين`;
      case "en":
        return `minerals in the Arab region, Arab platform for the future of minerals, mining in the Arab world, Arab metal industries, mining investment opportunities, mineral resource exploration, natural resource development, technology in mining, future of mining in the Middle East, sustainable mining, mineral extraction in the Arab world, promoting Arab cooperation in mining`;
      default:
        return `المعادن في المنطقة العربية, المنصة العربية لمعادن المستقبل, التعدين في العالم العربي, الصناعات المعدنية العربية, فرص الاستثمار في التعدين, استكشاف الموارد المعدنية, تطوير الموارد الطبيعية, التكنولوجيا في التعدين, مستقبل التعدين في الشرق الأوسط, استدامة التعدين, استخراج المعادن في الوطن العربي, تعزيز التعاون العربي في مجال التعدين`;
    }
  };

  return (
    <>
      <div className="px-6 py-14 lg:px-0 overflow-hidden app-container">
        <Helmet>
          <title>{getTitle()}</title>
          <meta name="description" content={getDescription()} />
          <meta name="keywords" content={getKeywords()} />

          {/* Open Graph tags for social media */}
          <meta property="og:title" content={getTitle()} />
          <meta property="og:description" content={getDescription()} />
          <meta
            property="og:image"
            content="https://arabpfm.org/assets/logos/app_logo.svg"
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />

          {/* Twitter Card tags for social media */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={getTitle()} />
          <meta name="twitter:description" content={getDescription()} />
          <meta
            name="twitter:image"
            content="https://arabpfm.org/assets/logos/app_logo.svg"
          />

          {/* Schema.org structured data for better SEO */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              name: getTitle(),
              description: getDescription(),
              url: window.location.href,
              image: "https://arabpfm.org/assets/logos/app_logo.svg",
              publisher: {
                "@type": "Organization",
                name: "Arab Platform for Future Minerals",
                url: "https://arabpfm.org/",
                logo: {
                  "@type": "ImageObject",
                  url: "https://arabpfm.org/assets/logos/app_logo.svg",
                },
              },
            })}
          </script>
        </Helmet>
        <Spacing />
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/region`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <CleanEnergyMineralsRegionDetailsLoading isLoading={isLoading} />
        <CleanEnergyMineralsRegionDetailsMap mineral={mineral} />
        <CleanEnergyMineralsRegionDetailsParagraph mineral={mineral} />
        <div className="mt-6 flex">
          {mineral &&
            mineral.studies &&
            mineral.studies[`link_${configState.language}`] && (
              <div className="rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-4 ltr:pl-4">
                <h4 className="mb-1 font-bold">
                  {configState.translation.FOR_MORE_INFORMATION}
                </h4>
                {/* Link to studies file with descriptive text */}
                <Link
                  target="_blank"
                  className="hover:underline hover:text-secondary"
                  to={mineral.studies[`link_${configState.language}`]}
                >
                  {configState.translation.DOWNLOAD_STUDIES_FILE}
                </Link>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
