// Component to display the message paragraph in the platform message section
export default function PlatformMessageParagraphe({ lng }) {
  // Render different paragraphs based on the selected language
  switch (lng) {
    case "ar":
      // Arabic paragraph
      return (
        <p
          // Animation attributes for fading in from the left
          data-aos={`fade-left`}
          data-aos-duration={`500`}
          data-aos-delay={`600`}
          // CSS classes for styling
          className={`text-5xl ltr:font-semibold rtl:font-bold leading-relaxed`}
        >
          {/* Arabic text */}
          <span className={`text-warning`}>الموارد المعدنية العربية</span>
          <br />
          <span>جسر التنمية لمستقبل</span>
          <br />
          <span className="font-black text-secondary">أفضل ومستدام</span>
        </p>
      );
    case "en":
      // English paragraph
      return (
        <p
          // Animation attributes for fading in from the left
          data-aos={`fade-left`}
          data-aos-duration={`500`}
          data-aos-delay={`600`}
          // CSS classes for styling
          className={`text-5xl ltr:font-semibold rtl:font-bold leading-relaxed`}
        >
          {/* English text */}
          <span className={`font-black text-warning text-5xl`}>
            Arab Mineral Resources
          </span>
          <br />
          <span className={`font-bold`}>A bridge to a better</span>
          <br />
          <span className={`font-bold text-secondary`}>
            & sustainable future
          </span>
        </p>
      );
    default:
      return null; // Return null if language is not specified
  }
}
