import Title from "../componenets/UI/Title";
import Spacing from "../componenets/UI/Spacing";
import { useConfig } from "../contexts/config/ConfigContext";
import CleanEnergyMineralsOpportunitiesCountries from "../componenets/clean-energy-minerals-opportunities/CleanEnergyMineralsOpportunitiesCountries";
import CleanEnergyMineralsOpportunitiesContainer from "../componenets/clean-energy-minerals-opportunities/CleanEnergyMineralsOpportunitiesContainer";
import { Helmet } from "react-helmet";

export default function CleanEnergyMineralsOpportunities() {
  const { configState } = useConfig();
  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `الفرص الاستثمارية التعدينية العربية | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Arab Mining Investment Opportunities | Arab Platform for Future Minerals`;

      default:
        return `الفرص الاستثمارية التعدينية العربية | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `استكشف الفرص الاستثمارية التعدينية العربية مع المنصة العربية لمعادن المستقبل. نعمل على تعزيز التعاون العربي في مجال التعدين وتطوير الموارد المعدنية. استكشاف واستثمار في المشاريع التعدينية للموارد الطبيعية في الوطن العربي، بهدف تحقيق تنمية مستدامة وبيئة نظيفة.`;
      case "en":
        return `Discover Arab mining investment opportunities with the Arab Platform for the Future of Minerals. We work to promote Arab cooperation in mining and the development of mineral resources. Explore and invest in mining projects for natural resources in the Arab world, with the goal of achieving sustainable development and a clean environment`;

      default:
        return `استكشف الفرص الاستثمارية التعدينية العربية مع المنصة العربية لمعادن المستقبل. نعمل على تعزيز التعاون العربي في مجال التعدين وتطوير الموارد المعدنية. استكشاف واستثمار في المشاريع التعدينية للموارد الطبيعية في الوطن العربي، بهدف تحقيق تنمية مستدامة وبيئة نظيفة.`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `الفرص الاستثمارية التعدينية, فرص الاستثمار في التعدين العربي, المنصة العربية لمعادن المستقبل, الاستثمار في مشاريع التعدين, تطوير الصناعات التعدينية, استكشاف المعادن العربية, تطوير موارد التعدين, مشاريع المعادن في الشرق الأوسط, فرص الاستثمار في التعدين, البنية التحتية للصناعات التعدينية, التكنولوجيا في التعدين, الشركات التعدينية العربية, أسواق المعادن العربية, التنافسية في قطاع التعدين, تعزيز استثمار الموارد المعدنية, التعاون العربي في مجال التعدين, البيئة والتنمية المستدامة في التعدين, مستقبل التعدين في العالم العربي, الاستثمار في الموارد الطبيعية, استخراج المعادن في الوطن العربي`;
      case "en":
        return `Mining investment opportunities, opportunities in Arab mining, Arab platform for the future of minerals, investment in mining projects, development of mining industries, exploration of Arab minerals, development of mining resources, Middle East mining projects, mining investment opportunities, mining infrastructure, technology in mining, Arab mining companies, Arab metals markets, competitiveness in the mining sector, promoting mineral resource investment, Arab cooperation in mining, environmental sustainability in mining, future of mining in the Arab world, investment in natural resources, mineral extraction in the Arab world`;
      default:
        return `الفرص الاستثمارية التعدينية, فرص الاستثمار في التعدين العربي, المنصة العربية لمعادن المستقبل, الاستثمار في مشاريع التعدين, تطوير الصناعات التعدينية, استكشاف المعادن العربية, تطوير موارد التعدين, مشاريع المعادن في الشرق الأوسط, فرص الاستثمار في التعدين, البنية التحتية للصناعات التعدينية, التكنولوجيا في التعدين, الشركات التعدينية العربية, أسواق المعادن العربية, التنافسية في قطاع التعدين, تعزيز استثمار الموارد المعدنية, التعاون العربي في مجال التعدين, البيئة والتنمية المستدامة في التعدين, مستقبل التعدين في العالم العربي, الاستثمار في الموارد الطبيعية, استخراج المعادن في الوطن العربي`;
    }
  };
  return (
    <div className="pt-16 px-6 bg-white overflow-hidden">
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: "https://arabpfm.org/assets/logos/app_logo.svg",
            publisher: {
              "@type": "Organization",
              name: "Arab Platform for Future Minerals",
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className="lg:px-0 app-container">
        <Title data-aos="fade-left" data-aos-duration="500">
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_OPPORTUNITIES
          }
        </Title>
        <div
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="500"
          className="h-[321px] w-full overflow-hidden mb-6"
        >
          <img
            className={`object-cover object-bottom w-full h-full`}
            src={`/assets/images/banners/opportunities-banner.webp`}
            alt={"opportunities banner"}
          />
        </div>
        <CleanEnergyMineralsOpportunitiesCountries />
        <CleanEnergyMineralsOpportunitiesContainer />
      </div>
    </div>
  );
}
