import { useConfig } from "../../contexts/config/ConfigContext";
import CleanEnergyMineralGridItem from "./CleanEnergyMineralGridItem";
import CleanEnergyMineralsGridError from "./CleanEnergyMineralsGridError";
import CleanEnergyMineralsGridLoading from "./CleanEnergyMineralsGridLoading";

export default function CleanEnergyMineralsGrid({
  minerals,
  isError,
  isLoading,
}) {
  const { configState } = useConfig();
  if (isLoading)
    return <CleanEnergyMineralsGridLoading isLoading={isLoading} />;
  if (isError)
    return (
      <CleanEnergyMineralsGridError
        isError={isError}
        language={configState.language}
      />
    );

  if (minerals)
    return minerals.map((mineral) => (
      <CleanEnergyMineralGridItem
        key={mineral.id}
        mineral={mineral}
        language={configState.language}
      />
    ));
}
