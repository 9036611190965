import { useConfig } from "../../../contexts/config/ConfigContext";

// Component to display the paragraph in the platform overview banner
export default function PlatformOverviewBannerParagraph() {
  // Accessing configuration state using the useConfig hook
  const { configState } = useConfig();

  // Rendering different text content based on the selected language
  switch (configState.language) {
    // For Arabic language
    case "ar":
      return (
        <p
          data-aos="fade-left" // Animation effect: fade-left
          data-aos-duration="500" // Animation duration: 500ms
          className="text-4xl font-black leading-relaxed lg:leading-[100px] group-hover:text-warning duration-500 ease-in-out transform" // Styling classes
        >
          {/* First line */}
          <span className="font-black">الفرص الاستثمارية التعدينية </span>
          <br />
          {/* Second line */}
          <span className="text-[46px] tv:text-[116px] text-white">
            لمعادن الطاقة النظيفة
          </span>
          <br />
          {/* Third line */}
          في الدول العربية
        </p>
      );

    // For English language
    case "en":
      return (
        <p
          data-aos="fade-left" // Animation effect: fade-left
          data-aos-duration="500" // Animation duration: 500ms
          className="text-4xl font-black leading-relaxed lg:leading-[100px] group-hover:text-warning duration-500 ease-in-out transform" // Styling classes
        >
          {/* First line */}
          <span className="font-black ">
            Mining Investment Opportunities for{" "}
          </span>
          <br />
          {/* Second line */}
          <span className="rtl:text-[46px] ltr:text-[50px] text-white">
            Clean Energy Minerals
          </span>
          <br />
          {/* Third line */}
          in the Arab countries
        </p>
      );

    // Default case (if language is neither Arabic nor English)
    default:
      return null; // Return null if language is not specified
  }
}
