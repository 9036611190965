import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useConfig } from "../contexts/config/ConfigContext";
import axiosClient from "../config/axios-client";
import Spacing from "../componenets/UI/Spacing";
import Title from "../componenets/UI/Title";
import CleanEnergyMineralRegionGrid from "../componenets/clean-energy-minerals-region/CleanEnergyMineralRegionGrid";
import { Helmet } from "react-helmet";

export default function CleanEnergyMineralsRegion() {
  // Context and router hook setup
  const { configState } = useConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQueryValue = searchParams.get("search_query");

  // State management
  const searchRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [minerals, setMinerals] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Handlers for input and form submission
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length === 0) {
      setSearchQuery("");
      navigate("/clean-energy-minerals/region");
    }
  };

  const handleSearchButtonClick = () => {
    setSearchQuery(searchRef.current.value);
  };

  const handleSubmitChange = (event) => {
    event.preventDefault();
    if (searchQuery.length !== 0) {
      setSearchQuery(searchRef.current.value);
    }
  };

  // Effect to fetch minerals on mount and when the searchQueryValue changes
  useEffect(() => {
    function fetchMineralsWithCountries() {
      setIsLoading(true);
      setIsError(false);
      let URL = `/platform/minerals/clean_energy/region`;
      if (searchQueryValue != null) {
        URL += `?search_query=${searchQueryValue}`;
      }
      axiosClient
        .get(URL)
        .then(({ data }) => {
          setMinerals(data.minerals);
        })
        .catch((error) => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    fetchMineralsWithCountries();
  }, [searchQueryValue]);

  // Effect to update URL when searchQuery changes
  useEffect(() => {
    if (searchQuery !== "") {
      navigate(`/clean-energy-minerals/region?search_query=${searchQuery}`);
    }
    return () => {};
  }, [searchQuery, navigate]);

  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `المعادن في المنطقة العربية | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Minerals in the Arab Region | Arab Platform for Future Minerals`;

      default:
        return `المعادن في المنطقة العربية | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
      case "en":
        return `Explore the world of minerals in the Arab region through the Arab Platform for the Future of Minerals. We offer insights and investment opportunities in the mining sector, focusing on the development and exploration of mineral resources. Learn about the latest technologies in mineral extraction and how to develop metal industries for sustainable growth`;

      default:
        return `اكتشف عالم المعادن في المنطقة العربية من خلال المنصة العربية لمعادن المستقبل. نقدم رؤى وفرص استثمارية في مجال التعدين ونركز على تطوير الموارد المعدنية واستكشافها. تعرف على أحدث التقنيات في مجال استخراج المعادن وكيفية تطوير الصناعات المعدنية لتحقيق التنمية المستدامة`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `المعادن في المنطقة العربية, المنصة العربية لمعادن المستقبل, التعدين في العالم العربي, الصناعات المعدنية العربية, فرص الاستثمار في التعدين, استكشاف الموارد المعدنية, تطوير الموارد الطبيعية, التكنولوجيا في التعدين, مستقبل التعدين في الشرق الأوسط, استدامة التعدين, استخراج المعادن في الوطن العربي, تعزيز التعاون العربي في مجال التعدين`;
      case "en":
        return `minerals in the Arab region, Arab platform for the future of minerals, mining in the Arab world, Arab metal industries, mining investment opportunities, mineral resource exploration, natural resource development, technology in mining, future of mining in the Middle East, sustainable mining, mineral extraction in the Arab world, promoting Arab cooperation in mining`;
      default:
        return `المعادن في المنطقة العربية, المنصة العربية لمعادن المستقبل, التعدين في العالم العربي, الصناعات المعدنية العربية, فرص الاستثمار في التعدين, استكشاف الموارد المعدنية, تطوير الموارد الطبيعية, التكنولوجيا في التعدين, مستقبل التعدين في الشرق الأوسط, استدامة التعدين, استخراج المعادن في الوطن العربي, تعزيز التعاون العربي في مجال التعدين`;
    }
  };

  return (
    <section className={`py-16 px-6 bg-white overflow-hidden`}>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: "https://arabpfm.org/assets/logos/app_logo.svg",
            publisher: {
              "@type": "Organization",
              name: "Arab Platform for Future Minerals",
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className="lg:px-0 app-container">
        {/* Page heading */}
        <Title data-aos={`fade-left`} data-aos-duration={`500`}>
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION
          }
        </Title>
        <div className={`grid grid-cols-12 gap-6`}>
          {/* Search form */}
          <form
            onSubmit={handleSubmitChange}
            className={`flex col-span-12 gap-6`}
            data-aos={`fade-up`}
            data-aos-duration={`500`}
            data-aos-delay={`600`}
          >
            <input
              defaultValue={searchQueryValue}
              onChange={handleInputChange}
              ref={searchRef}
              type={`search`}
              placeholder={
                configState.translation.CLEAN_ENERGY_MINERALS_PAGE_PLACEHOLDER
              }
              className={`w-full outline-none border-2 border-muted py-3 px-6 text-primary`}
            />
            <button
              onClick={handleSearchButtonClick}
              className={`bg-primary hover:bg-primary/70 text-white px-6`}
            >
              {configState.translation.HOME_PAGE_NAVBAR_SEARCH_BUTTON}
            </button>
          </form>
          {/* Minerals grid component */}
          <CleanEnergyMineralRegionGrid
            isLoading={isLoading}
            isError={isError}
            minerals={minerals}
            language={configState.language}
          />
        </div>
      </div>
    </section>
  );
}
