import React from "react";

export default function CleanEnergyMineralsOpportunitiesContainerLoading() {
  return (
    <div className="grid grid-cols-2 gap-8 mb-12">
      <div className="h-[128px] bg-muted animate-pulse col-span-2 lg:col-span-1" />
      <div className="h-[128px] bg-muted animate-pulse col-span-2 lg:col-span-1" />
      <div className="h-[500px] bg-muted animate-pulse col-span-2" />
    </div>
  );
}
