import { useState } from "react";
import {
  MdAreaChart,
  MdOutlineBarChart,
  MdOutlineShowChart,
} from "react-icons/md";
import { useConfig } from "../../contexts/config/ConfigContext";

export default function PriceChartSelectType({ onChange }) {
  const { configState } = useConfig();
  const [selectedType, setSelectedType] = useState("AREA");

  function handleOnClick(type) {
    setSelectedType(type);
    onChange(type);
  }
  return (
    <div className="flex flex-col space-y-2 rtl:space-x-reverse">
      <label className={`font-bold`}>{configState.translation.BAR_STYLE}</label>
      <div className="flex space-x-2 rtl:space-x-reverse">
        <button
          onClick={() => handleOnClick("AREA")}
          className={`font-bold border-2 ${
            selectedType == "AREA"
              ? "border-primary bg-primary text-white"
              : "hover:border-primary hover:bg-primary hover:text-white"
          } p-1.5`}
        >
          <MdAreaChart size={22} />
        </button>
        <button
          onClick={() => handleOnClick("BAR")}
          className={`font-bold border-2 ${
            selectedType == "BAR"
              ? "border-primary bg-primary text-white"
              : "hover:border-primary hover:bg-primary hover:text-white"
          } p-1.5`}
        >
          <MdOutlineBarChart size={22} />
        </button>
        <button
          onClick={() => handleOnClick("LINE")}
          className={`font-bold border-2 ${
            selectedType == "LINE"
              ? "border-primary bg-primary text-white"
              : "hover:border-primary hover:bg-primary hover:text-white"
          } p-1.5`}
        >
          <MdOutlineShowChart size={22} />
        </button>
      </div>
    </div>
  );
}
