import { Link } from "react-router-dom";

export default function FooterMenuItem({ to, label }) {
  return (
    <li>
      <Link to={to} className="hover:text-warning hover:underline">
        {label}
      </Link>
    </li>
  );
}
