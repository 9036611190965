import { useEffect, useState } from "react";
import Title from "../componenets/UI/Title";

import { useConfig } from "../contexts/config/ConfigContext";
import axiosClient from "../config/axios-client";

import Spacing from "../componenets/UI/Spacing";
import Banner from "../componenets/UI/Banner";
import CleanEnergyMineralsValueChainsSlider from "../componenets/clean-energy-minerals-value-chains/CleanEnergyMineralsValueChainsSlider";
import CleanEnergyMineralsValueChainsSliderLoading from "../componenets/clean-energy-minerals-value-chains/CleanEnergyMineralsValueChainsSliderLoading";
import CleanEnergyMineralsValueChainsSliderError from "../componenets/clean-energy-minerals-value-chains/CleanEnergyMineralsValueChainsSliderError";
import CleanEnergyMineralsValueChainsImage from "../componenets/clean-energy-minerals-value-chains/CleanEnergyMineralsValueChainsImage";
import { Helmet } from "react-helmet";

export default function CleanEnergyMineralsValueChains() {
  const { configState } = useConfig();
  const [selectedMineral, setSelectedMineral] = useState(null);
  const [minerals, setMinerals] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    function fetchValueChains() {
      setIsLoading(true);
      setIsError(false);

      axiosClient
        .get(`platform/minerals/clean_energy/value_chains`)
        .then(({ data }) => {
          setMinerals(data.minerals);
        })
        .catch((error) => {
          setIsError(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    fetchValueChains();
  }, []);

  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return ` ${
          selectedMineral
            ? `${selectedMineral[`name_${configState.language}`]} (${
                selectedMineral.symbol
              }) -`
            : ""
        } سلسلة القيمة | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `${
          selectedMineral
            ? `${selectedMineral[`name_${configState.language}`]} (${
                selectedMineral.symbol
              }) -`
            : ""
        } Value Chain | Arab Platform for Future Minerals`;
      default:
        return ` ${
          selectedMineral
            ? `${selectedMineral[`name_${configState.language}`]} (${
                selectedMineral.symbol
              }) -`
            : ""
        } سلسلة القيمة | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `استكشف سلسلة القيمة الكاملة للمعادن في المنطقة العربية، بدءًا من استكشاف الموارد حتى إنتاج المعادن. تعرّف على كيفية استغلال العالم العربي لموارده المعدنية الغنية، مع التركيز على ممارسات التعدين المستدامة وخلق القيمة. اكتشف تأثير مشاريع التعدين وتطوير الصناعات المعدنية العربية على الاقتصاد الإقليمي`;
      case "en":
        return `Explore the complete value chain of minerals in the Arab region, from resource exploration to metal production. Learn how the Arab world is capitalizing on its rich mineral resources, focusing on sustainable mining practices and value creation. Discover the impact of mining projects and the development of the Arab metal industries on the regional economy.`;
      default:
        return `استكشف سلسلة القيمة الكاملة للمعادن في المنطقة العربية، بدءًا من استكشاف الموارد حتى إنتاج المعادن. تعرّف على كيفية استغلال العالم العربي لموارده المعدنية الغنية، مع التركيز على ممارسات التعدين المستدامة وخلق القيمة. اكتشف تأثير مشاريع التعدين وتطوير الصناعات المعدنية العربية على الاقتصاد الإقليمي`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `سلسلة القيمة, المعادن في المنطقة العربية, التعدين في العالم العربي, الصناعات المعدنية العربية, استكشاف الموارد المعدنية, سلسلة القيمة المعدنية, قطاع التعدين في الشرق الأوسط, التعدين المستدام, استخراج المعادن في العالم العربي, مشاريع التعدين العربية, تطوير الموارد العربية`;
      case "en":
        return `Value chain, minerals in the Arab region, mining in the Arab world, Arab metal industries, mineral resource exploration, mineral value chain, mining sector in the Middle East, sustainable mining, mineral extraction in the Arab world, Arab mining projects, Arab resource development`;
      default:
        return `سلسلة القيمة, المعادن في المنطقة العربية, التعدين في العالم العربي, الصناعات المعدنية العربية, استكشاف الموارد المعدنية, سلسلة القيمة المعدنية, قطاع التعدين في الشرق الأوسط, التعدين المستدام, استخراج المعادن في العالم العربي, مشاريع التعدين العربية, تطوير الموارد العربية`;
    }
  };

  return (
    <div className={`py-16 px-6 bg-white overflow-hidden`}>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: "https://arabpfm.org/assets/logos/app_logo.svg",
            publisher: {
              "@type": "Organization",
              name: "Arab Platform for Future Minerals",
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className={`lg:px-0 app-container`}>
        <Title data-aos={`fade-left`} data-aos-duration={`500`}>
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_VALUE_CHAIN
          }
        </Title>
        <Banner
          image={`/assets/images/banners/value-chain-banner.webp`}
          alt={`value chain banner`}
          position={`center`}
        />
        <CleanEnergyMineralsValueChainsSliderLoading isLoading={isLoading} />
        <CleanEnergyMineralsValueChainsSliderError
          language={configState.language}
          isError={isError}
        />
        {!isLoading && (
          <CleanEnergyMineralsValueChainsSlider
            minerals={minerals}
            onChange={(mineral) => setSelectedMineral(mineral)}
          />
        )}
        <CleanEnergyMineralsValueChainsImage
          language={configState.language}
          mineral={selectedMineral}
        />
      </div>
    </div>
  );
}
