import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useConfig } from "../../../contexts/config/ConfigContext";
import PlatformMineralPricesMarqueeItem from "./PlatformMineralPricesMarqueeItem";
import axiosClient from "../../../config/axios-client";

export default function PlatformMineralPricesMarquee() {
  const { configState } = useConfig();

  const [prices, setPrices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    fetchPrices(); // Initial data fetch
    const intervalId = setInterval(fetchPrices, 60000); // Fetch data every second

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function fetchPrices() {
    axiosClient
      .get(`/platform/minerals/prices/live`)
      .then(({ data }) => {
        setPrices(data.prices);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div dir="ltr" className="bg-white h-[60px] w-full flex">
        {[...Array(8)].map((_, i) => (
          <div className="animate-pulse flex h-full w-[200px] bg-muted after:h-10 after:flex after:my-auto after:w-[1px] after:bg-light">
            <div></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div dir={`ltr`} className={`bg-white`}>
      <Marquee
        speed={26}
        direction={configState.language === `ar` ? `left` : `right`}
        pauseOnHover
      >
        {prices &&
          prices.map(
            (price, index) =>
              price.latest && (
                <PlatformMineralPricesMarqueeItem
                  language={configState.language}
                  price={price}
                />
              )
          )}
      </Marquee>
    </div>
  );
}
