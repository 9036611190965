import React from "react";
import { Link } from "react-router-dom";

export default function CleanEnergyMineralsOpportunitiesContainerSource({
  source,
  language,
}) {
  if (source && source[`source_${language}`] && language == "en") {
    return (
      <div className="border-warning border-l-4 pl-4 mb-12">
        <h4 className="mb-1 font-bold">Source</h4>
        <Link
          target="_blank"
          className="hover:underline-none hover:text-secondary"
          to={source.source_link}
        >
          {source[`source_${language}`]}
        </Link>
      </div>
    );
  }
  if (source && source[`source_${language}`] && language == "ar") {
    return (
      <div className="border-r-4 border-warning pr-4 mb-12">
        <h4 className="mb-1 font-bold">المصدر</h4>
        <Link
          target="_blank"
          className="hover:underline-none hover:text-secondary"
          to={source.source_link}
        >
          {source[`source_${language}`]}
        </Link>
      </div>
    );
  }
}
