import { Link } from "react-router-dom";

// Component for displaying a card in the platform overview section
export default function PlatformOverviewCard({
  to,
  image,
  icon,
  label,
  className,
}) {
  return (
    <div
      className={`relative group col-span-4 md:col-span-2 xl:col-span-1 group overflow-hidden ${className}`} // CSS classes for styling
      data-aos={`fade-up`} // Animation effect: fade-up
      data-aos-duration={`500`} // Animation duration: 500ms
      data-aos-delay={`1100`} // Animation delay: 1100ms
    >
      <Link to={to}>
        {" "}
        {/* Link to the specified route */}
        {/* Image */}
        <img
          className={`w-full h-[200px] object-cover group-hover:scale-110 duration-500 ease-in-out transform`}
          src={image}
          alt={`overview card`}
        />
        {/* Overlay */}
        <div
          className={`absolute -bottom-1 left-0 right-0 bg-warning group-hover:bg-primary text-white font-bold py-3 px-6 duration-500 ease-in-out transform`}
        >
          <div className={`flex flex-col`}>
            {/* Icon */}
            <div
              className={`absolute bg-warning group-hover:bg-primary p-2 rounded-full xl:-top-10 -top-14 rtl:right-5 ltr:left-5 duration-500 ease-in-out transform`}
            >
              <img
                className={`w-[90px] h-[90px] xl:w-[64px] xl:h-[64px]`}
                src={icon}
                alt={`overview card icon`}
              />
            </div>
            {/* Label */}
            <div
              className={`rtl:mr-[110px] ltr:ml-[110px] xl:rtl:mr-[90px] xl:ltr:ml-[90px] whitespace-nowrap`}
            >
              {label}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
