import CleanEnergyMineralRegionGridError from "./CleanEnergyMineralRegionGridError";
import CleanEnergyMineralRegionGridItem from "./CleanEnergyMineralRegionGridItem";
import CleanEnergyMineralRegionGridLoading from "./CleanEnergyMineralRegionGridLoading";

export default function CleanEnergyMineralRegionGrid({
  minerals,
  isLoading,
  isError,
  language,
}) {
  // Loading state: Show loading placeholders
  if (isLoading) {
    return <CleanEnergyMineralRegionGridLoading />;
  }

  // Error state: Show an error message
  if (isError) {
    return <CleanEnergyMineralRegionGridError language={language} />;
  }

  // Data loaded successfully: Display minerals using MineralsWithCountriesCard
  return minerals.map((mineral) => (
    <CleanEnergyMineralRegionGridItem
      key={mineral.id}
      mineral={mineral}
      language={language}
    />
  ));
}
