import React from "react";

export default function CleanEnergyMineralRegionGridLoading() {
  return [...Array(6)].map((n, index) => (
    <div
      className={`col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3  animate-pulse h-[200px] bg-muted`}
      key={index}
    />
  ));
}
