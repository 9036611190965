export default function CleanEnergyMineralsHomeSliderError({ isError }) {
  // Render the error message if isError is true
  if (isError) {
    return (
      // Container for error message
      <div
        className={`flex items-center justify-center border border-muted p-8 h-[258px]`}
      >
        {/* Error message */}
        <h2 dir={`ltr`} className={`font-bold text-xl`}>
          Something went wrong!
        </h2>
      </div>
    );
  }
}
