export default function Banner({ image, alt, position }) {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="500"
      className="h-[321px] tv:h-[642px] w-full overflow-hidden mb-6"
    >
      <img
        className={`object-cover ${
          position === "center" ? "object-center" : "object-bottom"
        } w-full h-full`}
        src={image}
        alt={alt}
      />
    </div>
  );
}
