import { TiWarningOutline } from "react-icons/ti";

export default function CleanEnergyMineralsValueChainsSliderError({
  isError,
  language,
}) {
  if (isError && language == "ar") {
    return (
      <div className="flex items-center justify-center border-2 bg-muted p-8 h-[250px] col-span-12">
        <div className="flex flex-col items-center space-y-4">
          <TiWarningOutline size={64} />
          <h2 className="font-bold text-xl">
            عذراً، لا يمكن العثور على سلسلة القيمة
          </h2>
          <p className="text-lg text-center">الرجاء محاولة مرة أخرى.</p>
        </div>
      </div>
    );
  }

  if (isError && language == "en") {
    return (
      <div className="flex items-center justify-center border-2 bg-muted p-8 h-[250px] col-span-12">
        <div className="flex flex-col items-center space-y-4">
          <TiWarningOutline size={64} />
          <h2 className="font-bold text-xl">
            Sorry, the mineral value chain can't be found.
          </h2>
          <p className="text-lg text-center">Please try again.</p>
        </div>
      </div>
    );
  }
}
