import React, { useEffect, useState } from "react";
import { useConfig } from "../../../contexts/config/ConfigContext";
import axiosClient from "../../../config/axios-client";

import Title from "../../UI/Title";
import PlatformNumbersLoading from "./PlatformNumbersLoading";
import PlatformNumbersError from "./PlatformNumbersError";
import PlatformNumbersCards from "./PlatformNumbersCards";

export default function PlatformNumbers() {
  // Accessing global configuration state
  const { configState } = useConfig();

  // State variables for numbers, loading status, and error status
  const [numbers, setNumbers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Fetch number of (countries, minerals, locations, opportunities) count
    async function fetchPlatformNumbers() {
      setIsError(false);
      setIsLoading(true); // Set loading to true while fetching
      axiosClient
        .get(`/platform/statistics/numbers`)
        .then(({ data }) => {
          setNumbers(data); // Set fetched numbers data
        })
        .catch((error) => {
          setIsError(true); // Set error flag if there's an error
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when fetching is done
        });
    }

    // Trigger the fetching when the component mounts
    fetchPlatformNumbers();
  }, []); // Empty dependency array to fetch data only once when the component mounts

  return (
    <div className={`app-container px-6 py-14 overflow-hidden`}>
      {/* Section Title */}
      <Title
        data-aos={`fade-left`}
        data-aos-duration={500}
        data-aos-delay={600}
      >
        {configState.translation.HOME_PAGE_PLATFORM_NUMBERS_TITLE}
      </Title>

      {/* Display loading spinner while fetching data */}
      <PlatformNumbersLoading isLoading={isLoading} />
      {/* Display error message if there's an error */}
      <PlatformNumbersError isError={isError} />
      {/* Display numbers cards if data is fetched successfully */}
      <PlatformNumbersCards items={numbers} />
    </div>
  );
}
