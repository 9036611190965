import { CONFIG_ACTIONS } from "./configActions";
import ar from "./lng/ar";
import en from "./lng/en";

export const configReducer = (state, action) => {
  switch (action.type) {
    case CONFIG_ACTIONS.CHANGE_LANGUAGE:
      localStorage.setItem("language", action.payload.language);

      return {
        ...state,
        language: action.payload.language,
        translation: action.payload.language.toLowerCase() == "ar" ? ar : en,
        dir: action.payload.language.toLowerCase() == "ar" ? "rtl" : "ltr",
      };

    default:
      return state;
  }
};
