import React from "react";

export default function HeadingSmall({ children, ...otherProps }) {
  return (
    <h3
      {...otherProps}
      className="mb-3 text-2xl font-bold rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-2 ltr:pl-2 capitalize"
    >
      {children}
    </h3>
  );
}
