const en = {
  PLATFORM_NAME: `Arab Platform for Future Minerals`,

  NEXT: `Next`,
  PREVIOUS: `Previous`,

  MINERAL: `Mineral`,
  TIME_INTERVAL: `Time interval`,
  BAR_STYLE: `Bar's style`,
  // Navbar
  HOME_PAGE_NAVBAR_HOME: "Home",
  HOME_PAGE_NAVBAR_ABOUT: "About",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY: "Clean Energy Minerals",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION: "Minerals in the Arab Region",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_OPPORTUNITIES:
    "Arab Mining Investment Opportunities",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRODUCTION: "Arab Production",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_VALUE_CHAIN: "Value Chain",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_GLOBAL_DEMAND: "Global Demand",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES:
    "Clean Energy Technologies",
  HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES: "Minerals Prices",
  HOME_PAGE_NAVBAR_REGION_MAP: "Arab Map",
  HOME_PAGE_NAVBAR_SEARCH_BUTTON: "Search",
  HOME_PAGE_NAVBAR_SEARCH_PLACEHOLDER: "Search...",

  // Hero

  // Number
  HOME_PAGE_PLATFORM_NUMBERS_TITLE: "Platform in Numbers",
  HOME_PAGE_PLATFORM_NUMBERS_ARAB_COUNTRIES_TITLE: "Arab countries",
  HOME_PAGE_PLATFORM_NUMBERS_CLEAN_ENEGY_MINERALS_TITLE:
    "Clean Energy Minerals",
  HOME_PAGE_PLATFORM_NUMBERS_SITES_TITLE: "Occurrences/Sites",
  HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_TITLE:
    "Mining Investment Opportunities",
  HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_SUB_TITLE: "Available now",
  HOME_PAGE_PLATFORM_NUMBERS_SITES_SUB_TITLE: "Available now",

  // Clean minerals energy
  HOME_PAGE_CLEAN_MINERALS_ENERGY_TITLE: "Clean Energy Minerals",
  HOME_PAGE_CLEAN_MINERALS_ENERGY_MORE: "More",

  // Region minerals
  HOME_PAGE_REGION_MINERALS_TITLE: "Minerals in the Arab Region",
  HOME_PAGE_REGION_MINERALS_MORE: "More",

  // MAP
  HOME_PAGE_REGION_MAP_TITLE: "Arab map",

  // Overview
  HOME_PAGE_OVERVIEW_MINERALS_PRICES: "Minerals Prices",
  HOME_PAGE_OVERVIEW_GLOBAL_DEMAND: "Global Demand",
  HOME_PAGE_OVERVIEW_VALUE_CHAIN: "Value Chain",
  HOME_PAGE_OVERVIEW_ARAB_PRODUCTION: "Arab Production",

  // Footer
  HOME_PAGE_FOOTER_WEBSITE_MAP_TITLE: "Website map",
  HOME_PAGE_FOOTER_WEBSITE_MAP_HOME: "Home",
  HOME_PAGE_FOOTER_WEBSITE_MAP_ABOUT: "About",
  HOME_PAGE_FOOTER_WEBSITE_MAP_CLEAN_ENERGY_MINERALS: "Clean Energy Minerals",
  HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MINERALS: "Minerals in the Arab Region",
  HOME_PAGE_FOOTER_WEBSITE_MAP_PRODUCTION: "Arab Production",
  HOME_PAGE_FOOTER_WEBSITE_MAP_VALUE_CHAIN: "Value Chain",
  HOME_PAGE_FOOTER_WEBSITE_MAP_OPPORTUNITIES:
    "Arab Mining Investment Opportunities",
  HOME_PAGE_FOOTER_WEBSITE_MAP_GLOBAL_DEMAND: "Global Demand",
  HOME_PAGE_FOOTER_WEBSITE_MAP_TECHNOLOGIES: "Clean Energy Technologies",
  HOME_PAGE_FOOTER_WEBSITE_MAP_PRICES: "Minerals Prices",
  HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MAP: "Arab map",

  HOME_PAGE_FOOTER_WEBSITE_CONTACT_TITLE: "Contacts",
  HOME_PAGE_FOOTER_ADDRESS_TITLE: "Addess",
  HOME_PAGE_FOOTER_ADDRESS:
    "khatawat junction on France Street 10102<br /> Agdal-Rabat,, Kingdom of Morocco",
  HOME_PAGE_FOOTER_PHONE_TITLE: "Phone",
  HOME_PAGE_FOOTER_FAX_TITLE: "Fax",
  HOME_PAGE_FOOTER_EMAIL_TITLE: "E-mail",

  // About
  ABOUT_PAGE_INTRODUCTION_TITLE: "INTRODUCTION",
  ABOUT_PAGE_INTRODUCTION_FIRST_PARAGRAPH: `The Arab region is rich in many mineral resources that are required in future industries to keep up with global trends. The world is striving to achieve carbon neutrality and create a clean, sustainable environment, as well as to provide modern technologies. This leads to an increased demand for mineral resources, which represent a primary source for establishing advanced industries such as clean energy technologies (photovoltaics, wind turbines, electric vehicles, electric grids, green hydrogen...), communication systems, phones, artificial intelligence, the Internet of Things, and others. Accordingly, and in line with digital transformation and the implementation of the outputs of the Arab initiative for minerals used in clean energy technologies, the Arab Industrial Development, Standardization, and Mining Organization (AIDSMO) has prepared the "Arab Platform for Future Minerals" to serve stakeholders and those interested in the energy, industry, and mining sectors both regionally and internationally.`,
  ABOUT_PAGE_INTRODUCTION_SECOND_PARAGRAPH: `<p class="w-full md:w-3/5 lg:w-4/5 md:mt-0 mt-3 tv:mt-6 tv:text-4xl tv:leading-loose"><span data-aos="fade-left" data-aos-duration="600" data-aos-delay="700" class="aos-init aos-animate">The first digital and interactive database for future minerals such as strategic and critical minerals in the Arab region includes:</span><ul><li data-aos="fade-left" data-aos-duration="600" data-aos-delay="800" class="aos-init aos-animate">
  Technical information (such as mineral identification, their presence and investment opportunities in Arab countries, value chain, global demand, mineral prices, etc.),
  </li><li data-aos="fade-left" data-aos-duration="600" data-aos-delay="900" class="aos-init aos-animate">
  Arab map (as an interactive window using ArcGIS Server to provide geospatial data about future minerals in the Arab region and other related information).
  </li></ul></p>`,

  ABOUT_PAGE_PLATFORM_MESSAGE_TITLE: `Platform's mission`,
  ABOUT_PAGE_PLATFORM_MESSAGE_FIRST_CARD:
    "Highlighting the role of minerals and mining in keeping pace with global trends towards sustainable development.",
  ABOUT_PAGE_PLATFORM_MESSAGE_SECOND_CARD:
    "Promoting minerals with a strategic dimension and their investment opportunities in Arab countries.",
  ABOUT_PAGE_PLATFORM_MESSAGE_THIRD_CARD: "Introducing future minerals.",

  ABOUT_PAGE_PLATFORM_FIRST_PHASE_TITLE: `First phase: "Clean energy minerals"`,
  ABOUT_PAGE_PLATFORM_FIRST_PHASE_PARAGRAPH: `The first phase of the platform's realization is based on "Clean energy minerals," in alignment with the outputs of the Arab Initiative for Minerals used in Clean Energy Technologies proposed by the Arab Industrial Development, Standardization and Mining Organization. The official launch of the platform in its first edition was carried out by Their Excellencies the Arab Ministers concerned with mineral resources during their ninth consultative meeting on January 9, 2024, in Riyadh, Saudi Arabia.`,

  ABOUT_PAGE_PLATFORM_OBJECTIVES_TITLE: `Objectives of the first phase`,
  ABOUT_PAGE_PLATFORM_OBJECTIVES_PARAGRAPH: `<ul>
    <li>Implementing policies aimed at enhancing the green economy within the framework of sustainable development through leveraging Arab mineral resources.</li>
    <li>Keeping pace with global trends towards addressing climate change and achieving carbon neutrality.</li>
    <li>Exploring and promoting the Arab mining sector's investment opportunities, especially those related to clean energy minerals.</li>
    <li>Boosting the Arab region's standing in supporting global demand and establishing it as a sustainable global mining center.</li>
    <li>Supporting the value chain for clean energy minerals.</li>
    </ul>`,

  ABOUT_PAGE_PLATFORM_COPYRIGHTING_MESSAGE: `The data available on the platform originates from entities concerned with the mineral resources sector in Arab countries, official websites, and related international reports.`,

  // Clean energy minerals
  CLEAN_ENERGY_MINERALS_PAGE_PLACEHOLDER: `Aluminum, lithium, cobalt...`,

  // Technologies
  TECHNOLOGIES_PAGE_SOLAR_ENERGY_TITLE: `Solar Energy`,
  TECHNOLOGIES_PAGE_WIND_ENERGY_TITLE: `Wind Energy`,
  TECHNOLOGIES_PAGE_BATTERY_TITLE: `Batteries`,
  TECHNOLOGIES_PAGE_ELECTRICAL_GRID_TITLE: `Electric networks`,
  TECHNOLOGIES_PAGE_HYDROGEN_TITLE: `Hydrogen`,
  TECHNOLOGIES_PAGE_ELECTRIC_CAR_TITLE: `Electric vehicles`,

  // Global
  COPYRIGHT: `©️ Copyright 2024 Arab Industrial Development, Standardization and Mining Organization (AIDSMO). All rights reserved`,
  SOURCE: `Source`,
  FOR_MORE_INFORMATION: `For more information`,
  DOWNLOAD_STUDIES_FILE: `You can download the study of strategic minerals in Arab countries`,

  // Opportunities
  OPPORTUNITIES_PAGE_SUB_TITLE: "Investment Opportunities Currently Available",
  OPPORTUNITIES_PAGE_ROW_MENIRALS: "Raw minerals:",
  OPPORTUNITIES_PAGE_AREA: "Area (km²):",
  OPPORTUNITIES_PAGE_DATE: "Opportunity date:",
  OPPORTUNITIES_PAGE_TYPE: "Opportunity type:",
  OPPORTUNITIES_PAGE_ADDITIONAL_INFORMATION: "Additional information",
  OPPORTUNITIES_PAGE_NOT_SPECIFIED: "Not specified",

  // Search
  SEARCH_PAGE_TITLE: `Search results`,

  // Single mineral
  SINGLE_MINERAL_PAGE_HISTORY: `History of`,
  SINGLE_MINERAL_PAGE_SOURCE: `#### sources in nature`,
  SINGLE_MINERAL_PAGE_COMPOUNDS: `compounds`,
};

export default en;
