import Marquee from "react-fast-marquee";
import { Link, useSearchParams } from "react-router-dom";

export default function CleanEnergyMineralRegionGridItem({
  mineral,
  language,
}) {
  return (
    <div
      className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 group overflow-hidden"
      data-aos="zoom-in"
      data-aos-duration="500"
      data-aos-delay={500}
    >
      <div
        className={`
          border-2 
          border-dashed 
          hover:border-solid
          border-primary/70
          bg-primary/10
          hover:bg-warning/50
          hover:text-white
          text-center
          transform 
          transition
          duration-300
          ease-in-out
        `}
      >
        {/* Link to the countries page for the specific mineral */}
        <Link to={`/clean-energy-minerals/region/${mineral.slug}/details`}>
          <div className="flex flex-col space-y-6 md:space-y-1 items-center">
            {/* Mineral name */}
            <h3 className="text-xl font-bold pt-3 md:text-lg capitalize">
              {mineral[`name_${language}`]}
            </h3>
            {/* Mineral image */}
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
              alt={mineral[`name_${language}`]}
              className="h-[128px] pb-2 group-hover:scale-110 transform transition duration-300 ease-in-out"
            />
            <div dir="ltr" className="w-full">
              {/* Marquee for displaying country flags */}
              {mineral.slug !== "altylyryom-tellurium" ? (
                <Marquee
                  speed={20}
                  play={true}
                  direction={language === "ar" ? "left" : "right"}
                >
                  {mineral.countries &&
                    language === "ar" &&
                    mineral.countries.map((country) => (
                      <img
                        key={country.id}
                        className="h-[32px] mr-4"
                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                        alt={country.name_ar}
                      />
                    ))}

                  {mineral.countries &&
                    language !== "ar" &&
                    mineral.countries
                      .slice() // Create a shallow copy of the array
                      .reverse()
                      .map((country) => (
                        <img
                          key={country.id}
                          className="h-[32px] mr-4"
                          src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                          alt={country.name_ar}
                        />
                      ))}
                </Marquee>
              ) : (
                <div className="h-[32px]" />
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
