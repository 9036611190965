import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../../contexts/config/ConfigContext";
import CleanEnergyMineralsRegionHomeGridItem from "./CleanEnergyMineralsRegionHomeGridItem";

/**
 * Component for displaying a grid of minerals in the region home page.
 * This grid showcases minerals relevant to the clean energy sector.
 * It includes a link to view more minerals for better user engagement and SEO.
 */
export default function CleanEnergyMineralsRegionHomeGrid({ minerals }) {
  // Access configuration state using custom hook
  const { configState } = useConfig();

  // Check if minerals data is available
  if (minerals && minerals.length > 0) {
    // Render the grid of minerals if data is available
    return minerals.map((mineral) => (
      <CleanEnergyMineralsRegionHomeGridItem
        key={mineral.id}
        mineral={mineral}
      />
    ));
  } else {
    // Return null if minerals data is not available
    return null;
  }
}
