import React from "react";
import { Link } from "react-router-dom";

export default function MobileNavbarItem({ islastItem, to, label }) {
  return (
    <li className="relative py-2">
      <Link
        className={`
                hover:text-warning 
                ltr:pl-[15px] 
                ltr:before:left-0 
                ltr:after:left-0 
                rtl:pr-[15px] 
                rtl:before:right-0 rtl:after:right-0 
                before:absolute 
                before:w-[12px] 
                before:mx-auto
                before:bg-white
                after:absolute
                after:top-0
                after:bottom-0
                ${
                  islastItem
                    ? `after:top-0 
                    after:bottom-0 
                    ltr:after:left-0 
                    rtl:pr-[15px] 
                    rtl:before:right-0 
                    rtl:after:right-0 
                    before:top-[12px] 
                    before:w-[12px] 
                    before:h-[1px] 
                    after:w-[1px] 
                    after:h-[12px] 
                    after:bg-white`
                    : `before:top-[18px] 
                    before:h-[1px] 
                    after:w-[1px] 
                    h-[12px] 
                    after:bg-white`
                }
            `}
        to={to}
      >
        {label}
      </Link>
    </li>
  );
}
