import { IoIosArrowUp } from "react-icons/io";
import { useEffect, useState } from "react";

export default function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      setIsVisible(scrolled > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <div className="fixed right-6 bottom-6">
        <button
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="bg-primary/60 hover:bg-primary p-2 text-white"
        >
          <IoIosArrowUp size={28} stroke="4" />
        </button>
      </div>
    )
  );
}
