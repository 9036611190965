import { Link } from "react-router-dom"; // Importing necessary hooks from React Router
import { useConfig } from "../../contexts/config/ConfigContext"; // Importing configuration context hook
import { useParams } from "react-router-dom";

export default function CleanEnergyMineralGridItem({ mineral, language }) {
  const { page } = useParams();

  // JSX rendering
  return (
    <div
      className={`col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 hover:shadow-b-xl`}
      data-aos={`zoom-in`}
      data-aos-duration={500}
      data-aos-delay={500}
    >
      {/* Conditional styling based on development mode */}
      <div
        className={`hover:bg-muted hover:border-solid hover:border-warning text-center border-2 border-muted border-dashed transform transition duration-300 ease-in-out`}
        role="griditem"
      >
        {/* Link to individual mineral page */}
        <Link
          to={`/clean-energy-minerals/${mineral.slug}/details?page=${page}`}
          className={`flex flex-col space-y-1 items-center p-2 md:p-4`}
          aria-label={`View details of ${mineral.name_en}`}
        >
          {/* Mineral image */}
          <img
            width={128}
            height={128}
            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
            alt={mineral.name_ar}
          />
          {/* Mineral name */}
          <h3
            className={`font-bold md:text-lg capitalize`}
            aria-label={`Name: ${mineral.name_en}`}
          >
            {mineral[`name_${language}`]}
          </h3>
          {/* Mineral symbol */}
          <h4
            className={`text-warning font-bold`}
            aria-label={`Symbol: ${mineral.symbol}`}
          >
            {mineral.symbol}
          </h4>
        </Link>
      </div>
    </div>
  );
}
