/**
 * Error component for displaying an error message when there's a problem fetching data.
 * This component will be displayed on the home page if there's an error in the server response.
 */
export default function CleanEnergyMineralsRegionHomeError({ isError }) {
  // Check if isError flag is true
  if (isError) {
    // Render error message if isError is true
    return (
      <div className="col-span-12 flex items-center justify-center bg-white p-8 h-[256px]">
        <h2 className="font-bold text-xl">
          .حدث خطأ ما! الرجاء المحاولة مرة أخرى في وقت لاحق
        </h2>
      </div>
    );
  } else {
    // If isError is false, return null (component will not render anything)
    return null;
  }
}
