import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Spacing from "../componenets/UI/Spacing";
import { useConfig } from "../contexts/config/ConfigContext";
import CleanEnergyMineralsResultContainer from "../componenets/search/clean-energy-minerals/CleanEnergyMineralsResultContainer";
import CleanEnergyMineralsRegionResultContainer from "../componenets/search/clean-energy-minerals/CleanEnergyMineralsRegionResultContainer";
import axios from "axios";
import axiosClient from "../config/axios-client";

export default function Search() {
  const { configState } = useConfig();

  const { search } = useParams();
  const [minerals, setMinerals] = useState([]);
  const [mineralsWithCountries, setMineralsWithCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);
      axiosClient
        .get(`/platform/search/${search}`)
        .then(({ data }) => {
          setMinerals(data.minerals);
          setMineralsWithCountries(data.minerals_countries);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    fetchData();
    return () => {};
  }, [search]);

  return (
    <>
      <Spacing />
      <div className="py-16 px-6 bg-white overflow-hidden">
        <div className="lg:px-0 app-container">
          <h1
            data-aos="fade-left"
            data-aos-duration="500"
            className={`border-warning mb-8 text-3xl lg:text-3xl ltr:font-bold ltr:border-l-8 ltr:pl-2 rtl:font-black rtl:border-r-8 rtl:pr-2`}
          >
            {configState.translation.SEARCH_PAGE_TITLE}
          </h1>
          <div>
            <h2 className="text-2xl font-bold mb-2">
              {configState.translation.HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY}
            </h2>
            <div className="space-y-4">
              <CleanEnergyMineralsResultContainer
                minerals={minerals}
                language={configState.language}
                isLoading={isLoading}
                isError={isError}
              />
            </div>
          </div>
          <div className="mt-6">
            <h2 className="text-2xl font-bold mb-2">
              {
                configState.translation
                  .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION
              }
            </h2>
            <div className="grid grid-cols-12 gap-4">
              <CleanEnergyMineralsRegionResultContainer
                minerals={mineralsWithCountries}
                language={configState.language}
                isLoading={isLoading}
                isError={isError}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
