import { Link } from "react-router-dom";

export default function Source({ language, label, link }) {
  if (language === "ar") {
    return (
      <p
        className={`!font-semibold !mt-6 !border-r-4 border-warning pr-2 cursor-default`}
      >
        <b>المصدر</b>
        <br />
        {link && (
          <Link
            className="underline hover:text-warning"
            to={link}
            target="_blank"
          >
            {label}
          </Link>
        )}
        {!link && <u>{label}</u>}
      </p>
    );
  }
  if (language === "en") {
    return (
      <p
        className={`!font-semibold !mt-6 !border-l-4 border-warning pl-2 cursor-default`}
      >
        <b>Source</b>
        <br />
        {link && (
          <Link
            target="_blank"
            className="underline hover:text-warning"
            to={link}
          >
            {label}
          </Link>
        )}
        {!link && <u>{label}</u>}
      </p>
    );
  }
}
