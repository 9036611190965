const cleanEnergyMineralsHomeSliderOptions = {
  direction: "rtl",
  perMove: 1,
  autoplay: true,
  loop: true,
  interval: 3000,
  arrows: false,
  pagination: false,
  rewind: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  gap: 16,
  breakpoints: {
    640: {
      perPage: 1,
    },
    768: {
      perPage: 2,
    },
    1024: {
      perPage: 3,
    },
    1280: {
      perPage: 4,
    },
  },
  perPage: 5,
};

const cleanEnergyMineralsValueChainsSliderOptions = {
  direction: "rtl",
  perMove: 1,
  autoplay: true,
  loop: true,
  interval: 3000,
  arrows: false,
  pagination: false,
  rewind: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  gap: 16,
  breakpoints: {
    640: {
      perPage: 1,
    },
    768: {
      perPage: 2,
    },
    1024: {
      perPage: 3,
    },
    1280: {
      perPage: 4,
    },
  },
  perPage: 5,
};

const cleanEnergyMineralsOpportunitiesCountriesSilderRTLOptions = {
  direction: "rtl",
  perPage: 6,
  perMove: 1,
  autoplay: true,
  loop: true,
  interval: 2500,
  arrows: false,
  pagination: false,
  rewind: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  gap: 16,
  breakpoints: {
    640: {
      perPage: 2,
    },
    768: {
      perPage: 3,
    },
    1024: {
      perPage: 4,
    },
  },
};
const cleanEnergyMineralsOpportunitiesCountriesSilderLTROptions = {
  direction: "ltr",
  perPage: 6,
  perMove: 1,
  autoplay: true,
  loop: true,
  interval: 2500,
  arrows: false,
  pagination: false,
  rewind: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  gap: 16,
  breakpoints: {
    640: {
      perPage: 2,
    },
    768: {
      perPage: 3,
    },
    1024: {
      perPage: 4,
    },
  },
};

const cleanEnergyMineralsOpportunitiesMineralFilterOptions = {
  direction: "ltr",
  perPage: 4,
  perMove: 1,
  autoplay: false,
  loop: true,
  interval: 2500,
  arrows: false,
  pagination: false,
  rewind: true,
  gap: 12,
  breakpoints: {
    640: {
      perPage: 2,
    },
    768: {
      perPage: 3,
    },
    1024: {
      perPage: 4,
    },
  },
};

export {
  cleanEnergyMineralsHomeSliderOptions,
  cleanEnergyMineralsValueChainsSliderOptions,
  cleanEnergyMineralsOpportunitiesCountriesSilderRTLOptions,
  cleanEnergyMineralsOpportunitiesCountriesSilderLTROptions,
  cleanEnergyMineralsOpportunitiesMineralFilterOptions,
};
