import React from "react";
import CleanEnergyMineralsResultContainerListItem from "./CleanEnergyMineralsResultContainerListItem";
import { useConfig } from "../../../contexts/config/ConfigContext";

export default function CleanEnergyMineralsResultContainer({
  minerals,
  isLoading,
  isError,
  language,
}) {
  const { configState } = useConfig();

  if (isLoading) {
    return [...Array(2)].map((n, index) => (
      <div
        key={index}
        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 bg-gray-300 h-[150px] animate-pulse"
      />
    ));
  }
  if (isError) {
    return (
      <div className="flex items-center justify-center border border-muted p-8 h-[258px]">
        <h2 className="font-bold text-xl">Something went wrong!</h2>
      </div>
    );
  }
  return minerals.map((mineral) => (
    <CleanEnergyMineralsResultContainerListItem
      mineral={mineral}
      language={language}
    />
  ));
}
