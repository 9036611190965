import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spacing from "../componenets/UI/Spacing"; // Importing Spacing component for layout
import { useEffect, useRef, useState } from "react"; // Importing necessary hooks from React
import CleanEnergyMineralsGrid from "../componenets/clean-energy-minerals/CleanEnergyMineralsGrid"; // Importing grid component for displaying minerals
import { GrFormNext, GrFormPrevious } from "react-icons/gr"; // Importing icons for navigation
import { useConfig } from "../contexts/config/ConfigContext"; // Importing configuration context hook
import Title from "../componenets/UI/Title"; // Importing Title component for page title
import axiosClient from "../config/axios-client"; // Importing Axios client for HTTP requests
import { Helmet } from "react-helmet";

export default function CleanEnergyMinerals() {
  const { configState } = useConfig();
  const navigate = useNavigate();
  const { page } = useParams();
  const [searchParams] = useSearchParams();
  const searchQueryValue = searchParams.get("search_query");
  const searchRef = useRef(null);

  const [minerals, setMinerals] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isNaN(page)) {
      navigate(`/not-found`);
    }
    function fetchCleanEnergyMinerals() {
      setIsLoading(true);
      setIsError(false);
      let URL = `/platform/minerals/clean_energy/${page}/limit/12`;
      if (searchQueryValue != null) {
        URL += `?search_query=${searchQueryValue}`;
      }
      axiosClient
        .get(URL)
        .then(({ data }) => {
          setIsError(data.total_items === 0);
          setMinerals(data.minerals);
          setTotalPages(data.total_pages);
        })
        .catch((error) => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    fetchCleanEnergyMinerals();
  }, [page, searchQueryValue]);

  useEffect(() => {
    if (searchQuery !== "") {
      navigate(`/clean-energy-minerals/1?search_query=${searchQuery}`);
    }
    return () => {};
  }, [searchQuery, navigate]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length === 0) {
      navigate(`/clean-energy-minerals/1`);
    }
  };

  const handleSubmitChange = (event) => {
    event.preventDefault();
    if (searchQuery.length !== 0) {
      setSearchQuery(searchRef.current.value);
    }
  };

  const handleSearchButtonClick = () => {
    setSearchQuery(searchRef.current.value);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      window.scrollTo(0, 0);
      let URL = `/clean-energy-minerals/${page - 1}`;
      if (searchQueryValue != null) {
        URL += `?search_query=${searchQueryValue}`;
      }
      navigate(URL);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      window.scrollTo(0, 0);
      let URL = `/clean-energy-minerals/${parseInt(page) + 1}`;
      if (searchQueryValue != null) {
        URL += `?search_query=${searchQueryValue}`;
      }
      navigate(URL);
    }
  };

  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `معادن الطاقة النظيفة | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Clean Energy Minerals | Arab Platform for Future Minerals`;

      default:
        return ``;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `اكتشف معادن الطاقة النظيفة واستخداماتها | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Discover clean energy minerals and their uses | Arab Platform For Future Minerals`;

      default:
        return `اكتشف معادن الطاقة النظيفة واستخداماتها | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة`;
      case "en":
        return `Renewable Energy, Clean Energy Solutions, Solar Power Systems, Wind Energy Technology, Energy Resources, Sustainable Energy, Green Energy, Clean Energy Sources, Renewable Energy Technologies, Clean Energy Resources, Solar Energy, Wind Energy, Geothermal Energy, Biomass Energy, Energy Transition, Clean Energy Future, Mineral Resources, Energy Efficiency, Sustainable Resources, Eco-friendly Energy, Energy Sustainability, Clean Technology, Solar Panels, Renewable Energy Resources, Wind Turbines, Hydroelectric Energy, Green Technology, Clean Energy Innovation, Future Energy, Renewable Resources, Alternative Energy, Energy Conservation`;
      default:
        return `الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة`;
    }
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content="https://arabpfm.org/assets/logos/app_logo.svg"
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: "https://arabpfm.org/assets/logos/app_logo.svg",
            publisher: {
              "@type": "Organization",
              name: "Arab Platform for Future Minerals",
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className={`py-16 px-6 bg-white overflow-hidden`}>
        <div className={`lg:px-0 app-container`}>
          <Title data-aos="fade-left" data-aos-duration="500">
            {configState.translation.HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY}
          </Title>
          <div
            data-aos="fade-down"
            data-aos-duration="500"
            className={`grid grid-cols-12 gap-[16px] w-full`}
          >
            <form
              onSubmit={handleSubmitChange}
              className={`flex gap-6 col-span-12`}
            >
              <input
                defaultValue={searchQueryValue}
                onChange={handleInputChange}
                ref={searchRef}
                type={`search`}
                name={`clean_energy_minerals`}
                placeholder={
                  configState.translation.CLEAN_ENERGY_MINERALS_PAGE_PLACEHOLDER
                }
                className={`w-full outline-none border-2 border-muted py-3 px-6 text-primary`}
              />
              <button
                onClick={handleSearchButtonClick}
                className={`bg-primary hover:bg-primary/70 text-white px-6`}
              >
                {configState.translation.HOME_PAGE_NAVBAR_SEARCH_BUTTON}
              </button>
            </form>
            <CleanEnergyMineralsGrid
              minerals={minerals}
              isLoading={isLoading}
              isError={isError}
            />
          </div>
          {totalPages > 1 && (
            <div className={`flex space-x-4 mt-4 rtl:space-x-reverse`}>
              <button
                title={configState.translation.PREVIOUS}
                onClick={handlePreviousPage}
              >
                <span
                  className={`flex border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out ${
                    page > 1 ? `` : "bg-muted cursor-not-allowed"
                  }`}
                >
                  <GrFormPrevious className={`w-8 h-8 rtl:rotate-180`} />
                </span>
              </button>
              <button
                title={configState.translation.NEXT}
                onClick={handleNextPage}
              >
                <span
                  className={`flex border-2 border-muted hover:bg-muted p-2 transform transition duration-300 ease-in-out ${
                    page < totalPages ? `` : "bg-muted cursor-not-allowed"
                  }`}
                >
                  <GrFormNext className={`w-8 h-8 rtl:rotate-180`} />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
