import { useConfig } from "../../contexts/config/ConfigContext";
import Source from "../UI/Source";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function CleanEnergyMineralsTechnologiesElectricVehiclesArticle() {
  const { configState } = useConfig();
  if (configState.language === "en")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article itemscope itemtype="http://schema.org/Article">
          <Helmet>
            {/* Meta Tags */}
            <title>
              Electric Vehicles: Advancements and Global Mineral Demand | Arab
              Platform for Future Minerals
            </title>
            <meta
              name="description"
              content="Electric Vehicles: Advancements and Global Mineral Demand. Explore the increasing demand for Minerals in EV production and battery technology."
            />
            <meta
              name="keywords"
              content="electric vehicles, EVs, Minerals, global demand, sustainability, transportation, future minerals, Arab Platform, mineral resources, sustainable transportation"
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="Electric Vehicles: Advancements and Global Mineral Demand | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="Electric vehicles (EVs) represent a significant shift towards sustainable transportation. Explore the increasing demand for Minerals in EV production and battery technology."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/electric-vehicles"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src="/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp"
              alt="A lineup of electric vehicles charging at stations, symbolizing sustainable transportation."
              itemprop="image"
            />
          </div>
          <h1
            className="text-3xl font-bold border-l-8 border-warning pl-2 mb-6"
            itemprop="headline"
          >
            Electric Vehicles
          </h1>
          <p className="mb-6" itemprop="description">
            The metallic requirements for the electric vehicle sector and
            battery storage represent the largest share of demand for clean
            energy technology Minerals over the next two decades. The demand for
            Minerals used in this sector has increased nearly tenfold in the
            Stated Policies Scenario and about 30 times in the Sustainable
            Development Scenario for the period until 2040.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            The Minerals Used In Electric Vehicles
          </h2>
          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-compound-en.webp`}
              alt={`Minerals used in electric vehicles`}
            />
          </div>

          <h2 className="text-2xl font-bold mb-4">
            Global Demand For Minerals Used In Electric Vehicles
          </h2>
          <iframe
            title={`Global demand for Minerals used in electric vehicles`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiMjhjZDFkZjEtNGIzYy00YjU0LWI0ZGYtNjMwYjM4OTJkNWE3IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <Source
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (configState.language === "ar")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article itemscope itemtype="http://schema.org/Article">
          <Helmet>
            {/* Meta Tags */}
            <title>
              المركبات الكهربائية: التطورات والطلب العالمي على المعادن | Arab
              Platform for Future Minerals
            </title>
            <meta
              name="description"
              content="تمثل المركبات الكهربائية تحولًا هامًا نحو النقل المستدام. استكشف الطلب المتزايد على المعادن في إنتاج المركبات الكهربائية وتكنولوجيا البطاريات."
            />
            <meta
              name="keywords"
              content="المركبات الكهربائية, المعادن, الطلب العالمي, الطاقة المستدامة, النقل, مستقبل المعادن, الطاقة النظيفة"
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="المركبات الكهربائية: التطورات والطلب العالمي على المعادن | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="تمثل المركبات الكهربائية تحولًا هامًا نحو النقل المستدام. استكشف الطلب المتزايد على المعادن في إنتاج المركبات الكهربائية وتكنولوجيا البطاريات."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/electric-vehicles"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp`}
              alt="مجموعة من السيارات الكهربائية التي يتم شحنها في المحطات، ترمز إلى النقل المستدام."
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-r-8 border-warning pr-2 mb-6"
            itemprop="headline"
          >
            المركبات الكهربائية
          </h1>

          <p className="mb-6" itemprop="description">
            تمثل المتطلبات المعدنية لقطاع المركبات الكهربائية وتخزين البطاريات
            النصيب الأكبرمن الطلب على معادن تقنيات الطاقة النظيفة على مدى
            العقدين المقبلين، حيث زاد الطلب على المعادن المستخدمة في هذا القطاع
            ما يقرب من عشرة أضعاف في سيناريو السياسات المعلنة وحوالي 30 مرة في
            سيناريو التنمية المستدامة خلال الفترة حتى عام 2040.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في المركبات الكهربائية
          </h2>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-compound-ar.webp`}
              alt="صورة المعادن المستخدمة في المركبات الكهربائية"
            />
          </div>

          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في المركبات الكهربائية
          </h2>

          <iframe
            title="الطلب العالمي على المعادن المستخدمة في المركبات الكهربائية"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiY2JmNDFjODMtMTIzZS00NTVkLThjMjktZjIxZGJjZTA1MTYwIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <Source
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
