import { useConfig } from "../../../contexts/config/ConfigContext";
import PlatformNumbersCard from "./PlatformNumbersCard";

// Component to display platform numbers cards
export default function PlatformNumbersCards({ items }) {
  // Accessing global configuration state
  const { configState } = useConfig();

  // Data loaded successfully: Display statistics cards
  if (items) {
    return (
      <div className="grid grid-cols-12 gap-[16px] w-full">
        {/* Statistics cards */}
        <PlatformNumbersCard
          number={items.countries_count}
          title={
            configState.translation
              .HOME_PAGE_PLATFORM_NUMBERS_ARAB_COUNTRIES_TITLE
          }
          icon={`/assets/icons/arabic_map.svg`}
        />
        <PlatformNumbersCard
          number={items.minerals_count}
          title={
            configState.translation
              .HOME_PAGE_PLATFORM_NUMBERS_CLEAN_ENEGY_MINERALS_TITLE
          }
          icon={`/assets/icons/clean_energy_resources.svg`}
        />
        <PlatformNumbersCard
          number={items.locations_count}
          title={configState.translation.HOME_PAGE_PLATFORM_NUMBERS_SITES_TITLE}
          subTitle={
            configState.translation.HOME_PAGE_PLATFORM_NUMBERS_SITES_SUB_TITLE
          }
          icon={`/assets/icons/location.svg`}
        />
        <PlatformNumbersCard
          number={items.opportunities_count}
          title={
            configState.translation
              .HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_TITLE
          }
          subTitle={
            configState.translation
              .HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_SUB_TITLE
          }
          icon={`/assets/icons/opportunities.svg`}
        />
      </div>
    );
  }
}
