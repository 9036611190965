// Import necessary dependencies from React
import { useEffect, useState } from "react";

// Import custom hooks and components
import { useConfig } from "../../../contexts/config/ConfigContext";
import axiosClient from "../../../config/axios-client";
import Title from "../../UI/Title";
import CleanEnergyMineralsRegionHomeLoading from "./CleanEnergyMineralsRegionHomeLoading";
import CleanEnergyMineralsRegionHomeError from "./CleanEnergyMineralsRegionHomeError";
import CleanEnergyMineralsRegionHomeGrid from "./CleanEnergyMineralsRegionHomeGrid";
import { Link } from "react-router-dom";

// Define the main component
export default function CleanEnergyMineralsRegionHome() {
  // Access configuration state using custom hook
  const { configState } = useConfig();

  // State variables to manage data fetching and error handling
  const [minerals, setMinerals] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Effect hook to fetch data when component mounts
  useEffect(() => {
    // Function to fetch minerals data
    function fetchMinerals() {
      // Start loading animation
      setIsLoading(true);
      // Reset error state
      setIsError(false);

      // Send request to API to fetch minerals data
      axiosClient
        .get("/platform/minerals/clean_energy/region/home")
        .then(({ data }) => {
          // Update state with fetched minerals data
          setMinerals(data.minerals);
        })
        .catch((error) => {
          // If there's an error, set error state to true
          setIsError(true);
        })
        .finally(() => {
          // Regardless of success or failure, stop loading animation
          setIsLoading(false);
        });
    }

    // Call the fetchMinerals function when component mounts
    fetchMinerals();
  }, []); // Empty dependency array ensures this effect runs only once when component mounts

  // Render the component
  return (
    <section className={`py-16 px-6 bg-muted relative`}>
      <div className={`app-container overflow-hidden`}>
        <div
          data-aos={`fade-left`}
          data-aos-duration={`500`}
          data-aos-delay={`400`}
          className={`space-y-4 mb-6`}
        >
          {/* Title component with dynamic text */}
          <Title data-aos={`fade-left`} data-aos-duration={`500`}>
            {configState.translation.HOME_PAGE_REGION_MINERALS_TITLE}
          </Title>
        </div>
        <div
          className={`grid grid-cols-12 gap-4`}
          role={`grid`}
          aria-label={`Clean Energy Minerals in the Region Home grid`}
        >
          {/* Loading component to show loading animation */}
          <CleanEnergyMineralsRegionHomeLoading isLoading={isLoading} />
          {/* Error component to show error message if fetching fails */}
          <CleanEnergyMineralsRegionHomeError isError={isError} />
          {/* Grid component to display fetched minerals data */}
          <CleanEnergyMineralsRegionHomeGrid minerals={minerals} />
        </div>
        {/* Link to view more minerals */}
        <div className={`mt-6`} data-aos={`fade-in`} data-aos-duration={`500`}>
          <Link
            to={`/clean-energy-minerals/region`}
            className={`flex justify-center py-3 px-6 bg-primary hover:bg-warning text-white font-bold transition duration-300 ease-in-out`}
            aria-label={`View More Minerals in the Region`}
          >
            {/* Text for viewing more minerals */}
            {configState.translation.HOME_PAGE_REGION_MINERALS_MORE}
          </Link>
        </div>
      </div>
    </section>
  );
}
