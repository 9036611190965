import React from "react";

export default function CleanEnergyMineralsRegionDetailsLoading({ isLoading }) {
  if (isLoading) {
    return (
      <section>
        <div
          className={`w-[180px] bg-muted h-[50px] animate-pulse mb-4`}
          role={`presentation`}
          aria-hidden={`true`}
        />
        <img
          src={`/assets/images/region_map.webp`}
          alt={`Loading Animation`}
          className={`animate-pulse`}
        />
      </section>
    );
  }
}
