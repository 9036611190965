export default function CleanEnergyMineralsGridLoading({ isLoading }) {
  if (isLoading) {
    return [...Array(12)].map((_, index) => (
      <div
        key={index}
        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
      >
        <div className="animate-pulse h-[200px] w-full p-4 bg-muted "></div>
      </div>
    ));
  } else {
    return null; // Return null if loading is not active
  }
}
