import React from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import Source from "../UI/Source";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function CleanEnergyMineralsTechnologiesBatteriesArticle() {
  const { configState } = useConfig();
  if (configState.language === "en")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article
          className="mb-8"
          itemscope
          itemtype="http://schema.org/Article"
        >
          {/* Metadata */}
          <Helmet>
            {/* Meta Tags */}
            <title>
              Batteries: Advancements and Global Minerals Demand | Arab Platform
              for Future Minerals
            </title>
            <meta
              name="description"
              content="Batteries: Advancements and Global Minerals Demand. Explore the use of minerals in batteries and the global demand for these minerals."
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="Batteries: Advancements and Global Minerals Demand | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="Explore the use of minerals in batteries and the global demand for these minerals."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/batteries/battery-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/batteries"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/batteries/battery-banner.webp`}
              alt="A display of battery storage units, essential for storing renewable energy and ensuring grid stability."
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-l-8 border-warning pl-2 mb-6"
            itemprop="headline"
          >
            Batteries
          </h1>

          <p className={`mb-6`} itemprop="description">
            Batteries are a means of storing and producing electrical energy,
            typically consisting of electrical cells that contain chemical
            materials that react to produce electricity. LFP batteries, which
            are safer and less expensive, are expected to dominate the battery
            storage market, requiring Lithium and Phosphorus, at least in the
            near term. High-density Nickel, Manganese, and Cobalt batteries
            could take a small market share, despite some potential cost
            factors. Additionally, Vanadium batteries are expected to play a
            role in the energy market, starting commercially with a small share
            and then gradually increasing to capture a larger share in the
            energy storage market for large renewable energy projects.
            <br />
            Minerals used in batteries usually contain a variety of Minerals and
            chemical compounds used in the processes of storing and generating
            electrical energy, including Copper, Nickel, Cobalt, Lithium,
            Manganese, Silicon, and Vanadium. These Minerals are used in
            different ways and react with other chemical materials inside
            batteries to generate and store electricity. Researchers are
            continually developing battery technology to increase its capacity
            and environmental friendliness.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Minerals used in batteries
          </h2>

          <p className="mb-6">
            It usually contains a variety of minerals and chemical compounds
            used in the processes of storing and generating electrical energy.
            Among the most important are:
          </p>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/batteries/battery-compound-en.webp`}
              alt="A battery compound housing energy storage systems, vital for stabilizing renewable energy grids."
            />
          </div>

          <p className={`mb-6`}>
            These minerals are used in various ways and react with other
            chemical materials inside batteries to generate and store
            electricity. Researchers continuously develop battery technology to
            enhance its capacity and environmental friendliness.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Global demand for Minerals used in batteries
          </h2>
          <iframe
            title={`Global demand for Minerals used in batteries`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiMjQzZTIyNGYtNjhmNC00OTkzLWE2MjQtM2M3ODM4YTY4ZDRjIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <Source
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (configState.language === "ar")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article itemscope itemtype="http://schema.org/Article">
          {/* Metadata */}
          <Helmet>
            {/* Meta Tags */}
            <title>
              البطاريات: التطورات والطلب العالمي على المعادن | Arab Platform for
              Future Minerals
            </title>
            <meta
              name="description"
              content="البطاريات: التطورات والطلب العالمي على المعادن. استكشف استخدام المعادن في البطاريات والطلب العالمي على هذه المعادن."
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="البطاريات: التطورات والطلب العالمي على المعادن | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="استكشف استخدام المعادن في البطاريات والطلب العالمي على هذه المعادن."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/batteries/battery-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/batteries"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/batteries/battery-banner.webp`}
              alt="عرض لوحدات تخزين البطاريات الضرورية لتخزين الطاقة المتجددة وضمان استقرار الشبكة"
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-r-8 border-warning pr-2 mb-6"
            itemprop="headline"
          >
            البطاريات
          </h1>

          <p className="mb-6" itemprop="description">
            البطاريات هي وسيلة لتخزين الطاقة الكهربائية وإنتاجها، وتتألف عادة من
            خلايا كهربائية تحتوي على مواد كيميائية تتفاعل لإنتاج الكهرباء. يتوقع
            أن تسيطر البطاريات LFP الآمنة والأقل تكلفة على سوق تخزين البطاريات،
            والتي تحتاج إلى الليثيوم والفسفور، على الأقل في الوقت القريب. بينما
            يمكن أن تأخذ بطاريات النيكل والمنجنيز والكوبالت عالية الكثافة من
            الطاقة حصة صغيرة من السوق، على الرغم من بعض العوامل التكلفة
            المحتملة. بالإضافة إلى ذلك، من المتوقع أن تصبح بطاريات الفاناديوم
            لها دور في سوق الطاقة وتبدأ تجاريًا بحصة صغيرة، ومن ثم تزيد تدريجيًا
            لالتقاط حصة أكبر في سوق تخزين الطاقة في مشاريع الطاقة المتجددة
            الكبيرة.
            <br />
            المعادن المستخدمة في البطاريات، تحتوي عادة على مجموعة متنوعة من
            المعادن والمركبات الكيميائية المستخدمة في عمليات تخزين وتوليد الطاقة
            الكهربائية ومن أهمها: النحاس، النيكل ، الكوبالت، الليثيوم، المنغنيز،
            السيليكون والفاناديوم. هذه المعادن تستخدم بطرق مختلفة وتتفاعل مع
            مواد كيميائية أخرى داخل البطاريات لتوليد وتخزين الكهرباء. يُطور
            الباحثون باستمرار تكنولوجيا البطاريات لزيادة قدرتها ومدى صديقتها
            للبيئة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في البطاريات
          </h2>

          <p className="mb-6">
            تحتوي عادة على مجموعة متنوعة من المعادن والمركبات الكيميائية
            المستخدمة في عمليات تخزين وتوليد الطاقة الكهربائية ومن أهمها:
          </p>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/batteries/battery-compound-ar.webp`}
              alt="مجمع بطاريات يضم أنظمة تخزين الطاقة، وهو أمر حيوي لتحقيق الاستقرار في شبكات الطاقة المتجددة."
            />
          </div>

          <p className="mb-6">
            هذه المعادن تستخدم بطرق مختلفة وتتفاعل مع مواد كيميائية أخرى داخل
            البطاريات لتوليد وتخزين الكهرباء. يُطور الباحثون باستمرار تكنولوجيا
            البطاريات لزيادة قدرتها ومدى صديقتها للبيئة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في البطاريات
          </h2>
          <iframe
            title="الطلب العالمي على معادن المستخدمة في البطاريات"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiMTg0MGMwYWUtMjhhMi00MjhkLWJlNjYtOWRiMzgzMWZiOWYzIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <Source
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
