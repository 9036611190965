import { useConfig } from "../../contexts/config/ConfigContext";
import Source from "../UI/Source";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function CleanEnergyMineralsTechnologiesHydrogenArticle() {
  const { configState } = useConfig();
  if (configState.language === "en")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article
          className="mb-8"
          itemscope
          itemtype="http://schema.org/Article"
        >
          {/* Metadata */}
          <Helmet>
            {/* Meta Tags */}
            <title>
              Hydrogen: Clean and Renewable Fuel | Arab Platform for Future
              Minerals
            </title>
            <meta
              name="description"
              content="Learn about hydrogen, a clean and renewable fuel that produces energy without harmful emissions. Explore its use in storing renewable energy and reducing reliance on fossil fuels."
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="Hydrogen: Clean and Renewable Fuel | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="Learn about hydrogen, a clean and renewable fuel that produces energy without harmful emissions. Explore its use in storing renewable energy and reducing reliance on fossil fuels."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/hydrogen/hydrogen-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/hydrogen"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/hydrogen/hydrogen-banner.webp`}
              alt="Hydrogen technology showcasing production and storage facilities."
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-l-8 border-warning pl-2 mb-6"
            itemprop="headline"
          >
            Hydrogen
          </h1>

          <p className={`mb-6`} itemprop="description">
            Hydrogen is a clean and renewable fuel that produces energy without
            harmful emissions when burned, and it can be produced using
            renewable sources. It can also be used to store excess energy from
            renewable sources and serves as a fuel for hybrid vehicles and fuel
            cells, reducing reliance on fossil fuels and carbon emissions.
            Hydrogen is a vital element in the field of energy and sustainable
            development. Hydrogen technologies require less amounts of Minerals
            compared to other technologies, but these Minerals can be rare, such
            as platinum group Minerals, which may remain a potential weakness in
            their development. For example, electrochemical cells and fuel cells
            require critical minerals.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Minerals used in Hydrogen technologies
          </h2>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/hydrogen/hydrogen-compound-en.webp`}
              alt={`Minerals used in Hydrogen technologies`}
            />
          </div>
          <p className={`mb-6`}>
            Minerals used in hydrogen technologies, specifically in
            electrochemical cells, include nickel, platinum, zirconium, and rare
            earth elements such as lanthanum or yttrium. Fuel cells require
            elements from the group of Minerals known as the platinum group,
            such as platinum and palladium. However, the automotive industry
            drives the demand for platinum and palladium primarily for their use
            in catalyst converters, which increases global demand for them.
          </p>
          <h2 className="text-2xl font-bold mb-4">
            Global demand for Minerals used in Hydrogen technologies
          </h2>
          <iframe
            title={`Global demand for Minerals used in Hydrogen technologies`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiMDkzNGNjNzUtOWNlZC00NjBjLTkxYmQtMDExYmEyZjAyY2VkIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <Source
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (configState.language === "ar")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article
          className="mb-8"
          itemscope
          itemtype="http://schema.org/Article"
        >
          <Helmet>
            {/* Meta Tags */}
            <title>
              الهيدروجين: وقود نظيف ومتجدد | المنصة العربية لمعادن المستقبل
            </title>
            <meta
              name="description"
              content="اكتشف الهيدروجين، وقودًا نظيفًا ومتجددًا يُنتج طاقة بدون انبعاثات ضارة. تعرف على استخداماته في تخزين الطاقة وتقليل الاعتماد على الوقود الأحفوري."
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="الهيدروجين: وقود نظيف ومتجدد | المنصة العربية لمعادن المستقبل"
            />
            <meta
              property="og:description"
              content="اكتشف الهيدروجين، وقودًا نظيفًا ومتجددًا يُنتج طاقة بدون انبعاثات ضارة. تعرف على استخداماته في تخزين الطاقة وتقليل الاعتماد على الوقود الأحفوري."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/hydrogen/hydrogen-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/hydrogen"
            />
          </Helmet>
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/hydrogen/hydrogen-banner.webp`}
              alt="تكنولوجيا الهيدروجين تعرض مرافق الإنتاج والتخزين"
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-r-8 border-warning pr-2 mb-6"
            itemprop="headline"
          >
            الهيدروجين
          </h1>

          <p className="mb-6" itemprop="description">
            الهيدروجين هو وقود نظيف ومتجدد يُنتج طاقة دون انبعاثات ضارة عند
            احتراقه، ويمكن إنتاجه باستخدام مصادر متجددة. يمكن استخدامه أيضًا
            لتخزين الطاقة الزائدة من مصادر الطاقة المتجددة ويُعتبر وقودًا
            للمركبات الهجينة والخلية الوقودية، مما يقلل من الاعتماد على الوقود
            الأحفوري وانبعاثات الكربون. الهيدروجين يعتبر عنصرًا حيويًا في مجال
            الطاقة والتنمية المستدامة. تتطلب تقنيات الهيدروجين كميات أقل من
            المعادن مقارنة بالتقنيات الأخرى، ولكن يمكن أن تكون هذه المعادن
            نادرة، مثل معادن مجموعة البلاتين، مما يجعلها نقطة ضعف محتملة في
            تطويرها. فعلى سبيل المثال، تتطلب الخلايا الكهروكيميائية وخلايا
            الوقود معادن حرجة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في تقنيات الهيدروجين
          </h2>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/hydrogen/hydrogen-compound-ar.webp`}
              alt="صورة المعادن المستخدمة في تقنيات الهيدروجين"
            />
          </div>
          <p className="mb-6">
            المعادن المستخدمة في تقنيات الهيدروجين، وتحديدا في الخلايا
            الكهروكيميائية، هي النيكل والبلاتين والزركونيوم والعناصر الأرضية
            النادرة مثل اللانثانوم أو الإتريوم. أما خلايا الوقود فتحتاج إلى
            عناصر من مجموعة المعادن المعروفة بمجموعة البلاتين، مثل البلاتين
            والبالاديوم. ومع ذلك، فإن صناعة السيارات تقود الطلب على البلاتين
            والبالاديوم بشكل رئيسي لاستخدامها في محولات العوامل الحفازة، مما
            يزيد من الطلب العالمي عليهما.
          </p>
          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في الهيدروجين
          </h2>
          <iframe
            title="الطلب العالمي على المعادن المستخدمة في الهيدروجين"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiNDBiYmE5NDYtZjlmNy00YzliLWIxNDQtZDVjMDNlYjA5OWJmIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <Source
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
