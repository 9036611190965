import React from "react";
import { TiWarningOutline } from "react-icons/ti";

export default function CleanEnergyMineralsOpportunitiesCountriesError({
  language,
}) {
  if (language == "ar") {
    return (
      <div
        className={`flex items-center justify-center border-2 bg-muted p-8 h-[128px] col-span-12 mb-6`}
      >
        <div className={`flex flex-col items-center space-y-2`}>
          <TiWarningOutline size={32} />
          <h2 className={`font-bold text-lg`}>
            عذراً، لا يمكن العثور على الدول
          </h2>
          <p className={`text-center`}>الرجاء المحاولة مرة أخرى.</p>
        </div>
      </div>
    );
  }
  if (language == "en") {
    return (
      <div
        className={`flex items-center justify-center border-2 bg-muted p-8 h-[128px] col-span-12 mb-6`}
      >
        <div className={`flex flex-col items-center space-y-2`}>
          <TiWarningOutline size={32} />
          <h2 className={`font-bold text-lg`}>
            Sorry, the country- can't be found.
          </h2>
          <p className={`text-center`}>
            Please try different keywords or try again.
          </p>
        </div>
      </div>
    );
  }
}
