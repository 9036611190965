import React from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import Source from "../UI/Source";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function CleanEnergyMineralsTechnologiesElectricityNetworksArticle() {
  const { configState } = useConfig();
  if (configState.language === "en")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article
          className="mb-8"
          itemscope
          itemtype="http://schema.org/Article"
        >
          {/* Metadata */}
          <Helmet>
            {/* Meta Tags */}
            <title>
              Electricity Networks: Advancements and Global Mineral Demand |
              Arab Platform for Future Minerals
            </title>
            <meta
              name="description"
              content="Electricity Networks: Advancements and Global Mineral Demand. Explore the use of Minerals in electricity networks and the global demand for these Minerals."
            />
            <meta
              name="keywords"
              content="electricity networks, Minerals, global demand, sustainability, transmission, energy efficiency"
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="Electricity Networks: Advancements and Global Mineral Demand | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="Explore the use of Minerals in electricity networks and the global demand for these Minerals."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/electricity-networks/electricity-networks-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/electricity-networks"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover object-bottom"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-banner.webp`}
              alt="A network of electricity transmission lines and towers, powering communities with clean energy."
              itemprop="image"
            />
          </div>
          <h1
            className="text-3xl font-bold border-l-8 border-warning pl-2 mb-6"
            itemprop="headline"
          >
            Electricity Networks
          </h1>

          <p className={`mb-6`} itemprop="description">
            Electricity networks are distribution systems that transmit
            electrical energy from power stations to homes, factories, and other
            facilities. They aim to safely and sustainably deliver electricity,
            relying on advanced technologies to ensure the stability of the
            electrical current and improve energy efficiency.
            <br />
            The Minerals primarily used in electricity networks are copper and
            aluminum. These Minerals are used in the structure of electrical
            networks and their connections to ensure the efficient and
            sustainable transmission of electricity.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            The Minerals used in electricity networks
          </h2>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-compound-en.webp`}
              alt="An electricity distribution compound, a hub of interconnected infrastructure ensuring reliable power supply."
            />
          </div>

          <p className="mb-6">
            These Minerals are used in the structure of electrical networks and
            their connections to ensure the efficient and sustainable
            transmission of electricity.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Global demand for Minerals used in electricity networks
          </h2>
          <iframe
            title={`Global demand for Minerals used in electricity networks`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiOWQ4OTQ1YzgtNGYyNi00NzRiLTlmMjctMDg0NDhjMGU1NDE5IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <Source
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (configState.language === "ar")
    return (
      <>
        <div className="flex">
          <Link
            to={`/clean-energy-minerals/technologies`}
            className=" bg-primary hover:bg-primary/80 text-white p-2 mb-4"
          >
            <IoIosArrowBack size={32} />
          </Link>
        </div>
        <article itemscope itemtype="http://schema.org/Article">
          {/* Metadata */}
          <Helmet>
            {/* Meta Tags */}
            <title>
              شبكات الكهرباء: التطورات والطلب العالمي على المعادن | Arab
              Platform for Future Minerals
            </title>
            <meta
              name="description"
              content="شبكات الكهرباء: التطورات والطلب العالمي على المعادن. استكشف استخدام المعادن في شبكات الكهرباء والطلب العالمي على هذه المعادن."
            />
            {/* Open Graph Tags */}
            <meta
              property="og:title"
              content="شبكات الكهرباء: التطورات والطلب العالمي على المعادن | Arab Platform for Future Minerals"
            />
            <meta
              property="og:description"
              content="استكشف استخدام المعادن في شبكات الكهرباء والطلب العالمي على هذه المعادن."
            />
            <meta
              property="og:image"
              content="/assets/images/technologies/electricity-networks/electricity-networks-banner.webp"
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content="https://arabpfm.org/clean-energy-minerals/technologies/electricity-networks"
            />
          </Helmet>

          {/* Content */}
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover object-bottom"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-banner.webp`}
              alt="شبكة من خطوط وأبراج نقل الكهرباء، تمد المجتمعات بالطاقة النظيفة."
              itemprop="image"
            />
          </div>

          <h1
            className="text-3xl font-bold border-r-8 border-warning pr-2 mb-6"
            itemprop="headline"
          >
            شبكات الكهرباء
          </h1>

          <p className="mb-6" itemprop="description">
            شبكات الكهرباء هي أنظمة توزيع الكهرباء التي تنقل الطاقة الكهربائية
            من محطات توليد الكهرباء إلى المنازل والمصانع وغيرها من المنشأت. تهدف
            إلى توصيل الكهرباء بأمان واستدامة وتعتمد على تقنيات متقدمة لضمان
            استقرار التيار الكهربائي وتحسين الكفاءة الطاقية. المعادن المستخدمة
            بشكل رئيسي في شبكات الكهرباء هي النحاس والألومنيوم. وهذه المعادن
            تستخدم في بنية الشبكات الكهربائية وتوصيلاتها لضمان نقل الكهرباء
            بكفاءة واستدامة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في شبكات الكهرباء
          </h2>

          <div className="mb-6 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-compound-ar.webp`}
              alt="مجمع لتوزيع الكهرباء، وهو مركز للبنية التحتية المترابطة التي تضمن إمدادات طاقة موثوقة."
            />
          </div>

          <p className="mb-6">
            وهذه المعادن تستخدم في بنية الشبكات الكهربائية وتوصيلاتها لضمان نقل
            الكهرباء بكفاءة واستدامة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في شبكات الكهرباء
          </h2>
          <iframe
            title="الطلب العالمي على المعادن المستخدمة في شبكات الكهرباء"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiYzc1ZTNjYjItMTMxMi00NzBkLWIwMDctOWQxYjc0OTlhMGJlIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <Source
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
