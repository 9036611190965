import { Splide } from "@splidejs/react-splide";
import React, { useRef } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useConfig } from "../../contexts/config/ConfigContext";
import {
  cleanEnergyMineralsOpportunitiesCountriesSilderLTROptions,
  cleanEnergyMineralsOpportunitiesCountriesSilderRTLOptions,
} from "../../config/sliders/slider-option";
import CleanEnergyMineralsOpportunitiesCountriesSilderItem from "./CleanEnergyMineralsOpportunitiesCountriesSilderItem";

export default function CleanEnergyMineralsOpportunitiesCountriesSilder({
  countries,
}) {
  const { configState } = useConfig();
  const countriesSliderRef = useRef(null);

  const handleOnClickNextButton = () => {
    countriesSliderRef.current.splide.go("+1");
  };

  const handleOnClickPreviousButton = () => {
    countriesSliderRef.current.splide.go("-1");
  };

  return (
    <>
      <div className="flex justify-end ltr:justify-start rtl:space-x-reverse space-x-4 mb-3">
        <div className={`flex space-x-4 rtl:flex-row-reverse`}>
          {/* Button for previous slide */}
          <button
            className={`flex`}
            onClick={handleOnClickPreviousButton}
            title={`السابق`}
            data-aos={`zoom-in`}
            data-aos-duration={`500`}
            data-aos-delay={`400`}
          >
            <span
              className={`p-2 border-2 border-muted hover:bg-muted hover:border-warning transform transition duration-300 ease-in-out`}
            >
              <GrFormPrevious className={`w-6 h-6`} />
            </span>
          </button>
          {/* Button for next slide */}
          <button
            className={`flex`}
            onClick={handleOnClickNextButton}
            title={`التالي`}
            data-aos={`zoom-in`}
            data-aos-duration={`500`}
            data-aos-delay={`200`}
          >
            <span
              className={`p-2 border-2 border-muted hover:bg-muted hover:border-warning transform transition duration-300 ease-in-out`}
            >
              <GrFormNext className={`w-6 h-6`} />
            </span>
          </button>
        </div>
      </div>
      <div className="mb-4">
        {configState.language === "ar" && (
          <Splide
            ref={countriesSliderRef}
            options={cleanEnergyMineralsOpportunitiesCountriesSilderRTLOptions}
            aria-label="Countries"
          >
            {countries.map((country) => (
              <CleanEnergyMineralsOpportunitiesCountriesSilderItem
                key={country.id}
                language={configState.language}
                country={country}
              />
            ))}
          </Splide>
        )}
        {configState.language === "en" && (
          <Splide
            ref={countriesSliderRef}
            options={cleanEnergyMineralsOpportunitiesCountriesSilderLTROptions}
            aria-label="Countries"
          >
            {countries.map((country) => (
              <CleanEnergyMineralsOpportunitiesCountriesSilderItem
                key={country.id}
                language={configState.language}
                country={country}
              />
            ))}
          </Splide>
        )}
      </div>
    </>
  );
}
