import { createContext, useContext, useEffect, useReducer } from "react";

import { configInitialState } from "./configInitialState";
import { configReducer } from "./configReducer";
import { CONFIG_ACTIONS } from "./configActions";

const ConfigContext = createContext({
  state: configInitialState,
  dispatch: () => {},
});

export const ConfigProvider = ({ children }) => {
  const [configState, configDispatch] = useReducer(
    configReducer,
    configInitialState
  );

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      configDispatch({
        type: CONFIG_ACTIONS.CHANGE_LANGUAGE,
        payload: {
          language: "ar",
        },
      });
    }
  }, []);

  return (
    <ConfigContext.Provider value={{ configState, configDispatch }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;

export const useConfig = () => useContext(ConfigContext);
