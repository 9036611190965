import React from "react";
import { Outlet } from "react-router-dom";
import Spacing from "../UI/Spacing";

export default function ArticlesContainer() {
  return (
    <div className="py-16 px-6 bg-white overflow-hidden">
      <Spacing />
      <div className="lg:px-0 app-container">
        <Outlet />
      </div>
    </div>
  );
}
