import React from "react";
import Spacing from "../UI/Spacing";

export default function CleanEnergyMineralDetailsloading() {
  return (
    <div className={`py-16 px-6 overflow-hidden`}>
      <Spacing />
      <div className={`lg:px-0 app-container`}>
        <article>
          {/* Loading skeleton for mineral details */}
          <div
            className={`mb-6 flex flex-col lg:flex-row lg:justify-between lg:items-end lg:space-x-4 space-y-4 lg:space-y-0`}
          >
            {/* Skeleton for mineral name container */}
            <div className={`w-[180px] bg-muted h-[64px] animate-pulse`} />
            {/* Skeleton for mineral image container */}
            <div className={`bg-muted animate-pulse w-[320px] h-[320px]`} />
          </div>
          {/* Skeleton for mineral description */}
          <div className={`animate-pulse mb-6`}>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-1/2 bg-muted h-[14px] mb-3`}></div>
          </div>
          {/* Skeleton for mineral history */}
          <div
            className={`mb-4 w-[135px] bg-muted h-[32px] animate-pulse`}
          ></div>
          <div className={`animate-pulse mb-6`}>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-2/3 bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-1/2 bg-muted h-[14px] mb-3`}></div>
          </div>
          {/* Skeleton for mineral source */}
          <div
            className={`w-[135px] mb-4 bg-muted h-[32px] animate-pulse`}
          ></div>
          <div className={`mb-6 bg-muted w-[320px] h-[320px] animate-pulse`} />
          <div className={`animate-pulse mb-6`}>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-2/3 bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-full bg-muted h-[14px] mb-3`}></div>
            <div className={`w-1/2 bg-muted h-[14px] mb-3`}></div>
            <div className={`w-4/5 bg-muted h-[14px] mb-3`}></div>
          </div>
        </article>
      </div>
    </div>
  );
}
