// Component for displaying loading animation
export default function CleanEnergyMineralsRegionHomeLoading({ isLoading }) {
  // Render loading animation if isLoading is true
  if (isLoading) {
    {
      /* Create 6 placeholder elements for loading animation */
    }
    return [...Array(6)].map((_, index) => (
      <div
        key={index}
        className={`animate-pulse flex flex-col items-center justify-center space-y-1 border border-white  h-[200px] w-full col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-2 bg-white`}
      />
    ));
  } else {
    // If isLoading is false, return null (component will not render anything)
    return null;
  }
}
