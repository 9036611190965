import { Navigate, createBrowserRouter } from "react-router-dom";
import Layout from "../pages/Layout";
import About from "../pages/About";
import Home from "../pages/Home";
import MineralsPrices from "../pages/MineralsPrices";
import Error404 from "../pages/Error404";
import CleanEnergyMinerals from "../pages/CleanEnergyMinerals";
import CleanEnergyMineralDetails from "../pages/CleanEnergyMineralDetails";
import CleanEnergyMineralsRegion from "../pages/CleanEnergyMineralsRegion";
import CleanEnergyMineralsRegionDetails from "../pages/CleanEnergyMineralsRegionDetails";
import CleanEnergyMineralsTechnologies from "../pages/CleanEnergyMineralsTechnologies";
import CleanEnergyMineralsTechnologiesSolarEnergyArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesSolarEnergyArticle";
import ArticlesContainer from "../componenets/articles/ArticlesContainer";
import CleanEnergyMineralsRegionProduction from "../pages/CleanEnergyMineralsRegionProduction";
import CleanEnergyMineralsGlobalDemand from "../pages/CleanEnergyMineralsGlobalDemand";
import CleanEnergyMineralsValueChains from "../pages/CleanEnergyMineralsValueChains";
import CleanEnergyMineralsTechnologiesWindEnergyArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesWindEnergyArticle";
import CleanEnergyMineralsTechnologiesBatteriesArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesBatteriesArticle";
import CleanEnergyMineralsTechnologiesElectricityNetworksArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesElectricityNetworksArticle";
import CleanEnergyMineralsTechnologiesElectricVehiclesArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesElectricVehiclesArticle";
import CleanEnergyMineralsTechnologiesHydrogenArticle from "../componenets/articles/CleanEnergyMineralsTechnologiesHydrogenArticle";
import CleanEnergyMineralsOpportunities from "../pages/CleanEnergyMineralsOpportunities";
import RegionMap from "../pages/RegionMap";
import Search from "../pages/Search";
import FormPage from "../pages/FormPage";

const router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },

      {
        path: "about",
        element: <About />,
      },
      {
        path: "search/:search",
        element: <Search />,
      },
      {
        path: "prices/:mineral_slug?",
        element: <MineralsPrices />,
      },

      {
        path: "clean-energy-minerals",
        children: [
          {
            path: "value-chains",
            element: <CleanEnergyMineralsValueChains />,
          },
          {
            path: "opportunities",
            element: <CleanEnergyMineralsOpportunities />,
          },
          {
            path: ":page",
            element: <CleanEnergyMinerals />,
          },
          {
            path: ":mineral_slug/details",
            element: <CleanEnergyMineralDetails />,
          },
          {
            path: "region",
            children: [
              {
                path: "",
                element: <CleanEnergyMineralsRegion />,
              },
              {
                path: "map",
                element: <RegionMap />,
              },
              {
                path: "production",
                element: <CleanEnergyMineralsRegionProduction />,
              },
              {
                path: ":mineral_slug/details",
                element: <CleanEnergyMineralsRegionDetails />,
              },
            ],
          },
          {
            path: "global",
            children: [
              {
                path: "demand",
                element: <CleanEnergyMineralsGlobalDemand />,
              },
            ],
          },
          {
            path: "technologies",
            element: <ArticlesContainer />,
            children: [
              {
                path: "",
                element: <CleanEnergyMineralsTechnologies />,
              },
              {
                path: "solar-energy",
                element: <CleanEnergyMineralsTechnologiesSolarEnergyArticle />,
              },
              {
                path: "wind-energy",
                element: <CleanEnergyMineralsTechnologiesWindEnergyArticle />,
              },
              {
                path: "batteries",
                element: <CleanEnergyMineralsTechnologiesBatteriesArticle />,
              },
              {
                path: "electricity-networks",
                element: (
                  <CleanEnergyMineralsTechnologiesElectricityNetworksArticle />
                ),
              },
              {
                path: "electric-vehicles",
                element: (
                  <CleanEnergyMineralsTechnologiesElectricVehiclesArticle />
                ),
              },
              {
                path: "hydrogen",
                element: <CleanEnergyMineralsTechnologiesHydrogenArticle />,
              },
            ],
          },
        ],
      },
      {
        path: "/*",
        element: <Navigate to={`not-found`} />,
      },
    ],
  },
  {
    path: "region",
    children: [
      {
        path: "map",
        element: <RegionMap />,
      },
    ],
  },
  {
    path: "form",
    element: <FormPage />,
  },
  {
    path: "not-found",
    element: <Error404 />,
  },
]);

export default router;
