import React, { useEffect, useState } from "react";
import CleanEnergyMineralsOpportunitiesCountriesError from "./CleanEnergyMineralsOpportunitiesCountriesError";
import CleanEnergyMineralsOpportunitiesCountriesLoading from "./CleanEnergyMineralsOpportunitiesCountriesLoading";
import axiosClient from "../../config/axios-client";
import CleanEnergyMineralsOpportunitiesCountriesSilder from "./CleanEnergyMineralsOpportunitiesCountriesSilder";

export default function CleanEnergyMineralsOpportunitiesCountries() {
  const [countries, setCountries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axiosClient
      .get(`/platform/countries`)
      .then(({ data }) => {
        setCountries(data.countries);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <CleanEnergyMineralsOpportunitiesCountriesLoading />;
  }
  if (isError) {
    return <CleanEnergyMineralsOpportunitiesCountriesError />;
  }
  if (countries) {
    return (
      <CleanEnergyMineralsOpportunitiesCountriesSilder countries={countries} />
    );
  }
}
