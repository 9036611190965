export default function CleanEnergyMineralsHomeSliderLoading({ isLoading }) {
  // Render loading animation if isLoading is true
  if (isLoading) {
    return (
      // Grid layout for loading animation
      <div
        className={`grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-12 gap-6`}
      >
        {/* Loading animation for small screens */}
        <div
          className={`animate-pulse flex flex-col items-center justify-center space-y-1 border border-light col-span-1 w-full h-[218px] bg-light`}
        />
        {/* Loading animation for small and medium screens */}
        <div
          className={`animate-pulse sm:flex flex-col items-center justify-center space-y-1 border border-light hidden sm:col-span-1 w-full h-[218px] bg-light`}
        />
        {/* Loading animation for small, medium, and large screens */}
        <div
          className={`animate-pulse md:flex flex-col items-center justify-center space-y-1 border border-light hidden md:col-span-1 w-full h-[218px] bg-light`}
        />
        {/* Loading animation for small, medium, large, and extra large screens */}
        <div
          className={`animate-pulse lg:flex flex-col items-center justify-center space-y-1 border border-light hidden lg:col-span-1 w-full h-[218px] bg-light`}
        />
        {/* Loading animation for extra large screens */}
        <div
          className={`animate-pulse xl:flex flex-col items-center justify-center space-y-1 border border-light hidden xl:col-span-1 w-full h-[218px] bg-light`}
        />
      </div>
    );
  }
}
