import { useState } from "react";
import { useConfig } from "../../contexts/config/ConfigContext";

const timeIntervalItems = [
  "1M",
  "3M",
  // "6M"
];

export default function MineralPricesTimeIntervalSelect({ onChange }) {
  const { configState } = useConfig();
  const [selectedInterval, setSelectedInterval] = useState("1M");
  function handleOnClick(type) {
    setSelectedInterval(type);
    onChange(type);
  }

  return (
    <div className={`flex flex-col space-y-2 rtl:space-x-reverse`}>
      <label className={`font-bold`}>
        {configState.translation.TIME_INTERVAL}
      </label>
      <div className={`flex space-x-2 rtl:space-x-reverse`}>
        {timeIntervalItems.map((item) => (
          <button
            onClick={() => handleOnClick(item)}
            className={`font-bold border-2 ${
              selectedInterval == item
                ? "border-primary bg-primary text-white"
                : "hover:border-primary hover:bg-primary hover:text-white"
            } p-1.5`}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
}
