import React from "react";
import Title from "../UI/Title";

export default function CleanEnergyMineralsRegionDetailsCountry({
  country,
  language,
  sourceLabel,
}) {
  if (country) {
    return (
      <section className={`mt-12`}>
        <article>
          <div className={`mb-6`}>
            <Title>{country[`name_${language}`]}</Title>
            <img
              className={`border-2 border-muted h-[128px]`}
              src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
              alt={country[`name_${language}`]}
              aria-hidden={`true`} // If decorative image
            />
          </div>
          <div className={`text-lg leading-loose text-justify`}>
            <p
              dangerouslySetInnerHTML={{ __html: country[`body_${language}`] }}
            />
          </div>
          {country[`source_${language}`] && (
            <div
              className={`rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-4 ltr:pl-4 cursor-default mt-6`}
            >
              <h4 className={`mb-0.5 text-xl font-bold`}>{sourceLabel}:</h4>
              <p
                className={`underline`}
                dangerouslySetInnerHTML={{
                  __html: country[`source_${language}`],
                }}
              />
            </div>
          )}
        </article>
      </section>
    );
  }
}
