import { Link, useLocation } from "react-router-dom";
import { LuSearch } from "react-icons/lu";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useConfig } from "../../contexts/config/ConfigContext";
import { CONFIG_ACTIONS } from "../../contexts/config/configActions";

export default function DesktopNavbar() {
  const { configState, configDispatch } = useConfig();

  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  function handleSearch(event) {
    event.preventDefault();
    if (search) {
      navigate(`/search/${search}`);
    }
  }

  function handleOnChangeLanguage() {
    if (configState.language == "ar") {
      configDispatch({
        type: CONFIG_ACTIONS.CHANGE_LANGUAGE,
        payload: {
          language: "en",
        },
      });
    } else {
      configDispatch({
        type: CONFIG_ACTIONS.CHANGE_LANGUAGE,
        payload: {
          language: "ar",
        },
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={`hidden lg:block text-white bg-secondary/70 font-semibold py-2.5 backdrop-blur-xl ${
        configState.language == "en" && "text-[17px]"
      }`}
    >
      <div className={`app-container`}>
        <div className={`flex justify-between items-center`}>
          <ul className={`flex space-x-6 rtl:space-x-reverse`}>
            <li>
              <Link className="hover:text-warning" to={``}>
                {configState.translation.HOME_PAGE_NAVBAR_HOME}
              </Link>
            </li>
            <li>
              <Link className="hover:text-warning" to={"/about"}>
                {configState.translation.HOME_PAGE_NAVBAR_ABOUT}
              </Link>
            </li>
            <li>
              <div className="group relative">
                <Link
                  className="hover:text-warning"
                  to={`/clean-energy-minerals/1`}
                >
                  {
                    configState.translation
                      .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY
                  }
                </Link>
                <div
                  style={{
                    zIndex: 9999,
                  }}
                  className="pt-8 absolute transition group-hover:translate-y-0 -translate-y-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform min-w-[500px] rtl:right-0 ltr:left-0"
                >
                  <ul className="flex-col space-y-3 w-full backdrop-blur-xl bg-secondary/90 p-6">
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/region"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION
                        }
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/opportunities"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_OPPORTUNITIES
                        }
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/region/production"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRODUCTION
                        }
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/value-chains"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_VALUE_CHAIN
                        }
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/global/demand"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_GLOBAL_DEMAND
                        }
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-warning"
                        to={"/clean-energy-minerals/technologies"}
                      >
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES
                        }
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:text-warning" to={"/prices"}>
                        {
                          configState.translation
                            .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES
                        }
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link className="hover:text-warning" to={"/region/map"}>
                {configState.translation.HOME_PAGE_NAVBAR_REGION_MAP}
              </Link>
            </li>
          </ul>

          <div className="flex gap-4">
            <form onSubmit={handleSearch} className="flex">
              <div className="relative">
                <LuSearch className="absolute rtl:right-0 ltr:left-0 z-5 text-primary/70 mt-[8px] rtl:mr-3 ltr:ml-3 w-[26px] h-[26px]" />
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={
                    configState.translation.HOME_PAGE_NAVBAR_SEARCH_PLACEHOLDER
                  }
                  type="search"
                  className="flex w-[280px] outline-none py-2 px-4 rtl:pr-12 ltr:pl-12 bg-white/70 text-primary focus:bg-white"
                />
              </div>
            </form>
            <button
              onClick={handleOnChangeLanguage}
              className="py-1.5 px-4 bg-primary "
            >
              {configState.language == "en" ? "العربية" : "English"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
