import React from "react";

export default function CleanEnergyMineralsValueChainsSliderLoading({
  isLoading,
}) {
  if (isLoading) {
    return (
      <div
        className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-12 gap-6"
        role="status"
        aria-label="Loading content"
      >
        <div
          className={`animate-pulse flex flex-col items-center justify-center space-y-1 border border-gray-200 col-span-1`}
          aria-hidden="true"
        >
          <div className="h-[180px] w-full bg-gray-300"></div>
        </div>
        <div
          className={`animate-pulse sm:flex flex-col items-center justify-center space-y-1 border border-gray-200 hidden sm:col-span-1`}
          aria-hidden="true"
        >
          <div className="h-[180px] w-full bg-gray-300"></div>
        </div>
        <div
          className={`animate-pulse md:flex flex-col items-center justify-center space-y-1 border border-gray-200 hidden md:col-span-1`}
          aria-hidden="true"
        >
          <div className="h-[180px] w-full bg-gray-300"></div>
        </div>
        <div
          className={`animate-pulse lg:flex flex-col items-center justify-center space-y-1 border border-gray-200 hidden lg:col-span-1`}
          aria-hidden="true"
        >
          <div className="h-[180px] w-full bg-gray-300"></div>
        </div>
        <div
          className={`animate-pulse xl:flex flex-col items-center justify-center space-y-1 border border-gray-200 hidden xl:col-span-1`}
          aria-hidden="true"
        >
          <div className="h-[180px] w-full bg-gray-300"></div>
        </div>
      </div>
    );
  }
}
