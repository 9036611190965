import React from "react";
import Title from "../componenets/UI/Title";
import Spacing from "../componenets/UI/Spacing";
import { useConfig } from "../contexts/config/ConfigContext";
import CleanEnergyMineralsGlobalDemandPowerBI from "../componenets/clean-energy-minerals-global-demand/CleanEnergyMineralsGlobalDemandPowerBI";
import { Helmet } from "react-helmet";
export default function CleanEnergyMineralsGlobalDemand() {
  const { configState } = useConfig();
  const getTitle = () => {
    switch (configState.language) {
      case "ar":
        return `الطلب العالمي | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Global Demand | Arab Platform for Future Minerals`;

      default:
        return `الطلب العالمي | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getDescription = () => {
    switch (configState.language) {
      case "ar":
        return `اكتشف طلب العالمي على معادن الطاقة النظيفة | المنصة العربية لمعادن المستقبل`;
      case "en":
        return `Discover global demand for clean energy minerals | Arab Platform For Future Minerals`;

      default:
        return `اكتشف طلب العالمي على معادن الطاقة النظيفة | المنصة العربية لمعادن المستقبل`;
    }
  };

  const getKeywords = () => {
    switch (configState.language) {
      case "ar":
        return `الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة`;
      case "en":
        return `Renewable Energy, Clean Energy Solutions, Solar Power Systems, Wind Energy Technology, Energy Resources, Sustainable Energy, Green Energy, Clean Energy Sources, Renewable Energy Technologies, Clean Energy Resources, Solar Energy, Wind Energy, Geothermal Energy, Biomass Energy, Energy Transition, Clean Energy Future, Mineral Resources, Energy Efficiency, Sustainable Resources, Eco-friendly Energy, Energy Sustainability, Clean Technology, Solar Panels, Renewable Energy Resources, Wind Turbines, Hydroelectric Energy, Green Technology, Clean Energy Innovation, Future Energy, Renewable Resources, Alternative Energy, Energy Conservation`;
      default:
        return `الطاقة المتجددة، حلول الطاقة النظيفة، أنظمة الطاقة الشمسية، تكنولوجيا طاقة الرياح، موارد الطاقة، الطاقة المستدامة، الطاقة الخضراء، مصادر الطاقة النظيفة، تقنيات الطاقة المتجددة، موارد الطاقة النظيفة، الطاقة الشمسية، طاقة الرياح، الطاقة الحرارية الجوفية، طاقة الكتلة الحيوية، انتقال الطاقة، مستقبل الطاقة النظيفة، الموارد المعدنية، كفاءة الطاقة، الموارد المستدامة، الطاقة الصديقة للبيئة، استدامة الطاقة، التكنولوجيا النظيفة، الألواح الشمسية، موارد الطاقة المتجددة، توربينات الرياح، الطاقة الكهرومائية، التكنولوجيا الخضراء، ابتكار الطاقة النظيفة، الطاقة المستقبلية، الموارد المتجددة، الطاقة البديلة، الحفاظ على الطاقة`;
    }
  };
  return (
    <div className="py-16 px-6 bg-white overflow-hidden">
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta name="keywords" content={getKeywords()} />
        {/* Open Graph tags for social media */}
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta
          property="og:image"
          content={`https://arabpfm.org/assets/logos/app_logo.svg`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card tags for social media */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta
          name="twitter:image"
          content={`https://arabpfm.org/assets/logos/app_logo.svg`}
        />

        {/* Schema.org structured data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: getTitle(),
            description: getDescription(),
            url: window.location.href,
            image: `https://arabpfm.org/assets/logos/app_logo.svg`,
            publisher: {
              "@type": "Organization",
              name: configState.translation.PLATFORM_NAME,
              url: "https://arabpfm.org/",
              logo: {
                "@type": "ImageObject",
                url: "https://arabpfm.org/assets/logos/app_logo.svg",
              },
            },
          })}
        </script>
      </Helmet>
      <Spacing />
      <div className="lg:px-0 app-container">
        <Title data-aos="fade-left" data-aos-duration="500">
          {
            configState.translation
              .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_GLOBAL_DEMAND
          }
        </Title>
        <CleanEnergyMineralsGlobalDemandPowerBI
          language={configState.language}
        />
      </div>
    </div>
  );
}
