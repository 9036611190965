import React, { useEffect, useState } from "react";
import Select from "react-select";
import axiosClient from "../../config/axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useConfig } from "../../contexts/config/ConfigContext";

const CustomOption = ({ innerProps, innerRef, data, isSelected }) => (
  <div
    ref={innerRef}
    {...innerProps}
    className={`flex cursor-pointer space-x-2 py-1.5 px-3 ${
      isSelected ? "bg-primary text-white" : "hover:bg-primary hover:text-white"
    }`}
  >
    <div>
      <img
        className="w-8 h-8"
        src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${data.image}`}
        alt={data.label}
      />
    </div>
    <div>{data.label}</div>
  </div>
);

export default function MineralPricesMineralsSelect({ language, onChange }) {
  const { configState } = useConfig();
  const [mineralOptions, setMineralOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultOption, setDefaultOption] = useState(null);

  const { mineral_slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    function fetchMinerals() {
      setIsLoading(true);
      axiosClient
        .get(`/platform/minerals/prices/options`)
        .then(({ data }) => {
          const options = [];
          data.mineral_options.forEach((option) => {
            options.push({
              value: option.mineral.slug,
              label: option.mineral[`name_${language}`],
              image: option.mineral.image,
            });
          });
          setMineralOptions(options);
          if (!defaultOption) {
            setDefaultOption(options[0]);
          }
          onChange(options[0]);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
          // nas dyal takhtit mochkial f imprement
        });
    }
    fetchMinerals();
    return () => {};
  }, [language]);

  useEffect(() => {
    if (
      Array.isArray(mineralOptions) &&
      mineralOptions.length > 0 &&
      mineral_slug
    ) {
      // Filter mineral options by the specific slug
      const filteredOptions = mineralOptions.filter(
        (option) => option.value == mineral_slug
      );
      setDefaultOption(filteredOptions[0]);
      onChange(filteredOptions[0]);
    }
  }, [mineral_slug, mineralOptions]);

  const handleSelectChange = (selectedOption) => {
    // Call the onChange funconChange(selectedOption);tion passed from props
    // onChange(selectedOption);
    navigate(`/prices/${selectedOption.value}`);
  };

  return (
    <div className={`flex flex-col space-y-2 rtl:items-end`}>
      <label className={`font-bold`}>{configState.translation.MINERAL}</label>
      <Select
        isLoading={isLoading}
        isDisabled={isLoading}
        options={mineralOptions}
        components={{ Option: CustomOption }}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: 0,
            border: "2px solid #e0ebef",
            color: "#067697",
            width: "256px",
          }),
        }}
        onChange={handleSelectChange}
        value={defaultOption}
      />
    </div>
  );
}
