import CleanEnergyMineralsHomePricesAreaChartCard from "./CleanEnergyMineralsHomePricesAreaChartCard";

// Component to display clean energy minerals home price charts
export default function CleanEnergyMineralsHomePriceCharts({ mineralPrices }) {
  // Render the price charts if mineralPrices is not null
  if (mineralPrices) {
    return (
      // Grid layout for displaying price charts
      <div
        data-aos="fade-up"
        data-aos-duration="300"
        className={`grid grid-cols-6 gap-6 mt-6`}
      >
        {/* Mapping through mineralPrices to render each price chart */}
        {mineralPrices.map((mineralPrice) => (
          <CleanEnergyMineralsHomePricesAreaChartCard
            key={mineralPrice.id}
            mineralPrice={mineralPrice}
          />
        ))}
      </div>
    );
  }
}
