import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosClient from "../../config/axios-client";
import { useConfig } from "../../contexts/config/ConfigContext";
import Title from "../UI/Title";
import CleanEnergyMineralsOpportunitiesContainerCountryHasNoData from "./CleanEnergyMineralsOpportunitiesContainerCountryHasNoData";
import CleanEnergyMineralsOpportunitiesContainerLoading from "./CleanEnergyMineralsOpportunitiesContainerLoading";
import CleanEnergyMineralsOpportunitiesContainerSource from "./CleanEnergyMineralsOpportunitiesContainerSource";
import CleanEnergyMineralsOpportunitiesContainerGrid from "./CleanEnergyMineralsOpportunitiesContainerGrid";

export default function CleanEnergyMineralsOpportunitiesContainer() {
  const { configState } = useConfig();
  const [searchParams] = useSearchParams();
  const countryId = searchParams.get("country_id");

  const [opportunities, setOpportunities] = useState(null);
  const [country, setCountry] = useState(null);
  const [minerals, setMinerals] = useState(null);
  const [source, setSource] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axiosClient
      .get(
        `/platform/minerals/clean_energy/countries/${countryId}/opportunities`
      )
      .then(({ data }) => {
        setOpportunities(data.data);
        setCountry(data.country);
        setSource(data.source);
        setMinerals(data.minerals);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {};
  }, [countryId]);

  if (isLoading) {
    return <CleanEnergyMineralsOpportunitiesContainerLoading />;
  }

  return (
    <>
      <div className="">
        <CleanEnergyMineralsOpportunitiesContainerCountryHasNoData
          hasData={opportunities && opportunities.length !== 0}
          country={country}
          language={configState.language}
        />
        <CleanEnergyMineralsOpportunitiesContainerGrid
          country={country}
          opportunities={opportunities}
          minerals={minerals}
        />
        <CleanEnergyMineralsOpportunitiesContainerSource
          source={source}
          language={configState.language}
        />
      </div>
    </>
  );
}
