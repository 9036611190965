import SubTitle from "../UI/SubTitle";
import Source from "../UI/Source";

export default function CleanEnergyMineralsValueChainsImage({
  mineral,
  language,
}) {
  if (mineral)
    return (
      <div className={`mt-8`}>
        <SubTitle data-aos={`fade-left`} data-aos-duration={`500`}>
          {mineral[`name_${language}`]}
        </SubTitle>
        <div
          className={`my-6 flex items-center justify-center border-2 p-2 border-muted`}
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="700"
        >
          <img
            className="h-full lg:h-[612px] tv:h-[1200px]"
            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${
              mineral.value_chains[`image_${language}`]
            }`}
            alt={"Value chain of " + mineral[`name_${language}`]}
            aria-hidden={true}
          />
        </div>
        {mineral.value_chains[`source_${language}`] && (
          <Source
            language={language}
            label={mineral.value_chains[`source_${language}`]}
            link={mineral.value_chains.link}
          />
        )}
      </div>
    );
}
