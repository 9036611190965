import React from 'react'
import { Link } from 'react-router-dom'

export default function CleanEnergyMineralsResultContainerListItem({ mineral, language }) {
    return (
        <div
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-delay={500}
            key={mineral.id}
        >
            <div className='cursor-pointer hover:bg-muted py-3 px-6 transform transition duration-300 ease-in-out border-2 border-dashed hover:border-solid hover:border-warning'>
                <Link to={`/clean-energy-minerals/${mineral.slug}/details`} className='flex gap-3'>
                    <div >
                        <img
                            className='h-[128px] min-w-[128px]'
                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                            alt={mineral[`name_${language}`]} />
                    </div>
                    <div className='flex flex-col'>
                        <h3 className='flex font-bold text-xl capitalize'>
                            {mineral[`name_${language}`]} (<span className='text-warning'>{mineral.symbol}</span>)
                        </h3>
                        <div className='line-clamp-3' dangerouslySetInnerHTML={{ __html: mineral[`body_${language}`] }} />
                    </div>
                </Link>
            </div>
        </div>
    )
}
