import React from "react";

export default function CleanEnergyMineralsGlobalDemandPowerBI({ language }) {
  if (language === "ar") {
    return (
      <>
        <iframe
          title={`الطلب العالمي`}
          className={`border-2 border-muted w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]`}
          src={`https://app.powerbi.com/view?r=eyJrIjoiMGYxYjQwNjAtMDY5Ny00ODU0LWJmMWEtYjU3MTIzNDYzMDhiIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
        />
        <p
          className={`font-semibold mt-6 rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-2 ltr:pl-2`}
        >
          <b>المصدر</b>
          <br />
          <u> الوكالة الدولية للطاقة 2023</u>
        </p>
      </>
    );
  }

  if (language === "en") {
    return (
      <>
        <iframe
          title={`الطلب العالمي`}
          className={`border-2 border-muted w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]`}
          src={`https://app.powerbi.com/view?r=eyJrIjoiNGY4OGMxMzAtZmQ4Yi00ZjI3LWI3MWUtMDQxMWJjZDcwMzVlIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
        />
        <p
          className={`font-semibold mt-6 rtl:border-r-4 ltr:border-l-4 border-warning rtl:pr-2 ltr:pl-2`}
        >
          <b>Source</b>
          <br />
          <u>International Energy Agency 2023</u>
        </p>
      </>
    );
  }
}
