import { useRef } from "react";
import { Link } from "react-router-dom";
import { Splide } from "@splidejs/react-splide";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useConfig } from "../../../../contexts/config/ConfigContext";
import CleanEnergyMineralsHomeSliderItem from "./CleanEnergyMineralsHomeSliderItem";
import { cleanEnergyMineralsHomeSliderOptions } from "../../../../config/sliders/slider-option";

export default function CleanEnergyMineralsHomeSlider({ minerals }) {
  // Accessing configuration state using custom hook
  const { configState } = useConfig();

  // Reference for accessing the Splide instance
  const cleanEnergyMineralSliderRef = useRef(null);

  // Function to handle click event for next button
  const handleOnClickNextButton = () => {
    // Using Splide API to move to the next slide
    cleanEnergyMineralSliderRef.current.splide.go("+1");
  };

  // Function to handle click event for previous button
  const handleOnClickPreviousButton = () => {
    // Using Splide API to move to the previous slide
    cleanEnergyMineralSliderRef.current.splide.go("-1");
  };
  if (minerals)
    // Render the component
    return (
      <>
        {/* "More" link */}
        <div className={`flex w-full justify-between mb-4`}>
          <div
            className={`text-center`}
            data-aos={`zoom-in`}
            data-aos-duration={`500`}
            data-aos-delay={`800`}
          >
            {/* Link to navigate to the page displaying more minerals */}
            <Link to={`/clean-energy-minerals/1`} className={`flex`}>
              {/* Button to show more minerals */}
              <span
                className={`px-4 py-2 border-2 font-bold border-muted hover:border-warning hover:bg-muted transform transition duration-300 ease-in-out`}
              >
                {configState.translation.HOME_PAGE_CLEAN_MINERALS_ENERGY_MORE}
              </span>
            </Link>
          </div>

          {/* Navigation buttons */}
          <div className={`flex space-x-4 rtl:flex-row-reverse`}>
            {/* Button for previous slide */}
            <button
              className={`flex`}
              onClick={handleOnClickPreviousButton}
              title={`السابق`}
              data-aos={`zoom-in`}
              data-aos-duration={`500`}
              data-aos-delay={`400`}
            >
              <span
                className={`p-2 border-2 border-muted hover:bg-muted hover:border-warning transform transition duration-300 ease-in-out`}
              >
                <GrFormPrevious className={`w-6 h-6`} />
              </span>
            </button>
            {/* Button for next slide */}
            <button
              className={`flex`}
              onClick={handleOnClickNextButton}
              title={`التالي`}
              data-aos={`zoom-in`}
              data-aos-duration={`500`}
              data-aos-delay={`200`}
            >
              <span
                className={`p-2 border-2 border-muted hover:bg-muted hover:border-warning transform transition duration-300 ease-in-out`}
              >
                <GrFormNext className={`w-6 h-6`} />
              </span>
            </button>
          </div>
        </div>

        {/* Slider component */}
        <div
          className={`w-full`}
          data-aos={`fade-up`}
          data-aos-duration={`600`}
        >
          {/* Splide slider component for displaying mineral items */}
          <Splide
            ref={cleanEnergyMineralSliderRef}
            options={cleanEnergyMineralsHomeSliderOptions}
            aria-label={`Clean Energy minerals Slider`}
          >
            {/* Mapping through minerals to render each slider item */}
            {minerals.map((mineral) => (
              <CleanEnergyMineralsHomeSliderItem
                key={mineral.id}
                mineral={mineral}
                language={configState.language}
              />
            ))}
          </Splide>
        </div>
      </>
    );
}
