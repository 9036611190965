// Component to display loading placeholders for platform numbers
export default function PlatformNumbersLoading({ isLoading }) {
  if (isLoading) {
    return (
      <div className={`grid grid-cols-12 gap-[16px] w-full`}>
        {/* Mapping over an array to create loading placeholders */}
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className={`group col-span-12 sm:col-span-6 lg:col-span-3 border`}
          >
            {/* Loading placeholder element */}
            <div className={`w-full h-[278px] bg-gray-300 animate-pulse`} />
          </div>
        ))}
      </div>
    );
  }
}
