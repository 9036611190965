import moment from "moment-timezone";
import { BiSolidUpArrow } from "react-icons/bi";
import { GoDash } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";

export default function PlatformMineralPricesMarqueeItem({ price, language }) {
  const location = useLocation();
  const change = price.latest.change
    ? parseFloat(price.latest.change.toFixed(5))
    : 0;

  const toDateTime = (utcDate) => {
    // Parse the UTC timestamp with the specified format, if provided
    const utcMoment = moment.utc(utcDate);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert UTC to user's local time
    const localMoment = utcMoment.clone().tz(userTimeZone);

    // Format the local date and time
    const localDate = localMoment.format("hh:mm A");

    return localDate;
  };

  // Function to generate class name
  const getClassName = () => {
    let className =
      "flex bg-white after:h-10 after:flex after:my-auto after:w-[1px] after:bg-light duration-150 ease-in-out transform group";

    if (change > 0) {
      className += " hover:bg-success";
    } else if (change < 0) {
      className += " hover:bg-danger";
    } else {
      // If change is zero
    }

    if (change !== 0) {
      className += " hover:text-white";
    }

    if (location.pathname !== "/") {
      className += " border-b-[2px] border-light/50";
    }

    return className;
  };
  return (
    <Link to={`/prices/${price.mineral.slug}`} className={getClassName()}>
      <div className={`py-1 px-4 min-w-[256px]`}>
        <div className={`flex flex-col`}>
          <div className={`flex justify-between rtl:flex-row-reverse`}>
            <div className={`font-bold capitalize rtl:text-right`}>
              {price.mineral[`name_${language}`]}
            </div>
            <div className={`font-bold text-[18px] text-warning`}>
              {price.latest.price.toFixed(5)}
            </div>
          </div>
          <div
            className={`flex justify-between items-end rtl:flex-row-reverse`}
          >
            {/* <div>{moment(price.latest.date).format("hh:mm A")}</div> */}
            <div>{toDateTime(price.latest.date)}</div>
            {price.latest.change > 0 && (
              <div
                className={`flex items-center space-x-2 rtl:flex-row-reverse rtl:space-x-reverse text-success group-hover:text-white`}
              >
                <div dir="ltr">{price.latest.change.toFixed(5)} %</div>
                <BiSolidUpArrow
                  className={`${price.latest.change > 0 ? "" : "rotate-180"}`}
                />
              </div>
            )}
            {price.latest.change < 0 && (
              <div
                className={`flex items-center space-x-2 rtl:flex-row-reverse rtl:space-x-reverse text-danger group-hover:text-white`}
              >
                <div dir="ltr">{price.latest.change.toFixed(5)} %</div>
                <BiSolidUpArrow
                  className={`${price.latest.change > 0 ? "" : "rotate-180"}`}
                />
              </div>
            )}
            {price.latest.change == 0 && (
              <div
                className={`flex items-center space-x-2 rtl:flex-row-reverse rtl:space-x-reverse `}
              >
                <div dir="ltr">0.00 %</div>
                <GoDash strokeWidth={2} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
