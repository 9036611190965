import React, { useEffect, useState } from "react";
import Title from "../UI/Title";
import { useConfig } from "../../contexts/config/ConfigContext";
import { useSearchParams } from "react-router-dom";
import CleanEnergyMineralsOpportunitiesMineralFilter from "./CleanEnergyMineralsOpportunitiesMineralFilter";
import CleanEnergyMineralsOpportunitiesContainerGridItem from "./CleanEnergyMineralsOpportunitiesContainerGridItem";

export default function CleanEnergyMineralsOpportunitiesContainerGrid({
  country,
  opportunities,
  minerals,
}) {
  const { configState } = useConfig();
  const [opportunitiesCount, setOpportunitiesCount] = useState(0);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMineralId = searchParams.get("mineral_id");

  useEffect(() => {
    setFilteredOpportunities(opportunities);
    setOpportunitiesCount(opportunities.length);
    if (selectedMineralId) {
      const filteredOpportunities = opportunities.filter((opportunity) =>
        opportunity.minerals_ids
          .split(",")
          .some((id) => id.trim() == selectedMineralId)
      );
      setOpportunitiesCount(filteredOpportunities.length);
      setFilteredOpportunities(filteredOpportunities);
    }
  }, [opportunities, country, selectedMineralId]);

  if (opportunities && opportunities.length > 0 && country && minerals) {
    return (
      <div>
        <div className="my-12 mb-6 grid grid-cols-2 gap-6">
          <div
            className="col-span-1 flex flex-col justify-between"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <Title>{country[`name_${configState.language}`]}</Title>
            <h3 className="flex items-start justify-between font-bold text-warning leading-relaxed">
              <div className="text-3xl font-black">
                {configState.translation.OPPORTUNITIES_PAGE_SUB_TITLE}
              </div>
              <span className="text-7xl bg-primary inline-block py-1 px-6 text-white select-none">
                {opportunitiesCount}
              </span>
            </h3>
          </div>
          <CleanEnergyMineralsOpportunitiesMineralFilter minerals={minerals} />
        </div>
        <div
          className="grid grid-cols-6 gap-6 text-lg mb-12"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {filteredOpportunities.map((opportunity) => (
            <CleanEnergyMineralsOpportunitiesContainerGridItem
              opportunity={opportunity}
            />
          ))}
        </div>
      </div>
    );
  }
}
