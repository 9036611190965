import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { useConfig } from "../../../contexts/config/ConfigContext";

/**
 * Component for displaying each mineral grid item on the home page.
 */
export default function CleanEnergyMineralsRegionHomeGridItem({ mineral }) {
  // Access configuration state using custom hook
  const { configState } = useConfig();

  // Render each mineral grid item
  return (
    <article
      className={`col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-2 border-2 border-dashed hover:border-solid border-primary bg-primary/20 hover:bg-warning/50 hover:text-white text-center transform transition duration-300 ease-in-out group`}
      aria-labelledby={`mineral-${mineral.id}`}
      role="griditem"
    >
      {/* Link to the page displaying mineral details */}
      <Link
        to={`/clean-energy-minerals/region/${mineral.slug}/details`}
        aria-label={`View details of ${
          mineral[`name_${configState.language}`]
        }`}
        className={`no-underline`}
      >
        <div
          className={`flex flex-col space-y-6 md:space-y-3 items-center overflow-hidden`}
        >
          {/* Mineral name */}
          <h3
            id={`mineral-${mineral.id}`}
            className={`text-lg font-bold pt-3 md:text-lg capitalize`}
          >
            {mineral[`name_${configState.language}`]
              .replace("(", "")
              .replace(")", "")}
          </h3>
          {/* Mineral image */}
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
            alt={mineral[`name_${configState.language}`]
              .replace("(", "")
              .replace(")", "")}
            className={`h-[90px] group-hover:scale-125 transform transition duration-300 ease-in-out`}
          />
          {/* Marquee for displaying countries */}
          <div dir={`ltr`} className={`w-full`} aria-live="polite">
            <Marquee
              speed={30}
              play={true}
              direction={configState.language === `ar` ? `left` : `right`}
            >
              {/* Display countries flags */}
              {mineral.countries && // Check if countries data is available
                configState.language === `ar` && // Check if the language is Arabic
                mineral.countries.map((country) => (
                  <img
                    key={country.id}
                    className={`h-[32px] mr-4`}
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                    alt={country[`name_${configState.language}`]}
                  />
                ))}

              {/* Display countries flags in reverse order for non-Arabic language */}
              {mineral.countries && // Check if countries data is available
                configState.language !== `ar` && // Check if the language is not Arabic
                mineral.countries
                  .slice()
                  .reverse()
                  .map((country) => (
                    <img
                      key={country.id}
                      className={`h-[32px] mr-4`}
                      src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                      alt={country[`name_${configState.language}`]}
                    />
                  ))}
            </Marquee>
          </div>
        </div>
      </Link>
    </article>
  );
}
