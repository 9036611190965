import React from "react";
import { Link } from "react-router-dom";

export default function CleanEnergyMineralsTechnologiesGridItem({
  link,
  image,
  alt,
  title,
}) {
  return (
    <Link to={link} className={`col-span-6 md:col-span-2`}>
      <div
        className={`border-2 border-muted hover:bg-muted hover:border-warning p-4 flex flex-col items-center justify-center cursor-pointer duration-500 ease-in-out transform`}
      >
        <img className={`h-[128px]`} src={image} alt={alt} />
        <h2 className={`font-bold mt-4 text-lg text-center`}>{title}</h2>
      </div>
    </Link>
  );
}
