import { SplideSlide } from "@splidejs/react-splide";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CleanEnergyMineralsOpportunitiesCountriesSilderItem({
  country,
  language,
}) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  function handleOnClick(country) {
    navigate(`?country_id=${country.id}`);
  }

  return (
    <SplideSlide
      className={`rounded-none mt-2 cursor-pointer`}
      data-aos="zoom-out"
      data-aos-duration="500"
    >
      <div
        onClick={() => handleOnClick(country)}
        className={`flex items-center h-full flex-col space-y-4 transform z-50 ${
          searchParams.get("country_id") == country.id
            ? "border-2 border-dashed bg-warning/20 border-warning"
            : "transition duration-300 ease-in-out hover:scale-105 "
        }  px-1 py-4`}
      >
        <div>
          <img
            className="border-2 border-muted h-[64px] tv:h-[128px]"
            src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
            alt={`${country[`name_${language}`]} flag`}
            aria-hidden={true}
          />
        </div>
        <h3 className="text-center font-semibold">
          {country[`name_${language}`]}
        </h3>
      </div>
    </SplideSlide>
  );
}
