import React from "react";
import Title from "../UI/Title";
import { GrInProgress } from "react-icons/gr";

export default function CleanEnergyMineralsOpportunitiesContainerCountryHasNoData({
  hasData,
  country,
  language,
}) {
  if (!hasData && country && language == "ar")
    return (
      <div className="my-12">
        <Title>{country[`name_ar`]}</Title>
        <div
          className={`flex items-center justify-center border-2 bg-muted p-8 h-[256px] col-span-12`}
        >
          <div className={`flex flex-col items-center space-y-6`}>
            <GrInProgress size={40} />
            <h2 className={`font-bold text-xl`}>جاري تجميع المعلومات ...</h2>
          </div>
        </div>
      </div>
    );
  if (!hasData && country && language == "en")
    return (
      <div className="my-12">
        <Title>{country[`name_en`]}</Title>
        <div
          className={`flex items-center justify-center border-2 bg-muted p-8 h-[256px] col-span-12`}
        >
          <div className={`flex flex-col items-center space-y-6`}>
            <GrInProgress size={40} />
            <h2 className={`font-bold text-xl`}>
              Data loading in progress ...
            </h2>
          </div>
        </div>
      </div>
    );
}
