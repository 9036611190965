const ar = {

    PLATFORM_NAME: `المنصة العربية لمعادن المستقبل`,

    NEXT: `التالي`,
    PREVIOUS: `السابق`,

    MINERAL: `المعدن`,
    TIME_INTERVAL: `المدة الزمنية`,
    BAR_STYLE: `الأعمدة البيانية`,

    // Navbar
    HOME_PAGE_NAVBAR_HOME: 'الصفحة الرئيسية',
    HOME_PAGE_NAVBAR_ABOUT: 'حول المنصة',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY: 'معادن الطاقة النظيفة',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION: 'المعادن في المنطقة العربية',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_OPPORTUNITIES: 'الفرص الاستثمارية التعدينية العربية',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRODUCTION: 'الانتاج العربي',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_VALUE_CHAIN: 'سلسلة القيمة',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_GLOBAL_DEMAND: 'الطلب العالمي',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES: 'تقنيات الطاقة النظيفة',
    HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES: 'أسعار المعادن',
    HOME_PAGE_NAVBAR_REGION_MAP: 'الخريطة العربية',
    HOME_PAGE_NAVBAR_SEARCH_BUTTON: 'بحث',
    HOME_PAGE_NAVBAR_SEARCH_PLACEHOLDER: 'بحث',


    // Number
    HOME_PAGE_PLATFORM_NUMBERS_TITLE: 'المنصة في أرقام',
    HOME_PAGE_PLATFORM_NUMBERS_ARAB_COUNTRIES_TITLE: 'دولة عربية',
    HOME_PAGE_PLATFORM_NUMBERS_CLEAN_ENEGY_MINERALS_TITLE: 'معادن الطاقة النظيفة',
    HOME_PAGE_PLATFORM_NUMBERS_SITES_TITLE: 'موقع / مكمن',
    HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_TITLE: 'الفرص الاستثمارية التعدينية',
    HOME_PAGE_PLATFORM_NUMBERS_OPPORTUNITIES_SUB_TITLE: 'المتاحة حاليا',
    HOME_PAGE_PLATFORM_NUMBERS_SITES_SUB_TITLE: 'المتوفر حاليا',


    // Clean minerals energy
    HOME_PAGE_CLEAN_MINERALS_ENERGY_TITLE: 'معادن الطاقة النظيفة',
    HOME_PAGE_CLEAN_MINERALS_ENERGY_MORE: 'المزيد',

    // Region minerals
    HOME_PAGE_REGION_MINERALS_TITLE: 'المعادن في المنطقة العربية',
    HOME_PAGE_REGION_MINERALS_MORE: 'المزيد',

    // MAP
    HOME_PAGE_REGION_MAP_TITLE: 'الخريطة العربية',

    // Overview
    HOME_PAGE_OVERVIEW_MINERALS_PRICES: 'أسعار المعادن',
    HOME_PAGE_OVERVIEW_GLOBAL_DEMAND: 'الطلب العالمي',
    HOME_PAGE_OVERVIEW_VALUE_CHAIN: 'سلسلة القيمة',
    HOME_PAGE_OVERVIEW_ARAB_PRODUCTION: 'الانتاج العربي',

    // Footer
    HOME_PAGE_FOOTER_WEBSITE_MAP_TITLE: 'خريطة الموقع',
    HOME_PAGE_FOOTER_WEBSITE_MAP_HOME: 'الرئيسية',
    HOME_PAGE_FOOTER_WEBSITE_MAP_ABOUT: 'حول المنصة',
    HOME_PAGE_FOOTER_WEBSITE_MAP_CLEAN_ENERGY_MINERALS: 'معادن الطاقة النظيفة',
    HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MINERALS: 'المعادن في المنطقة العربية',
    HOME_PAGE_FOOTER_WEBSITE_MAP_PRODUCTION: 'الانتاج العربي',
    HOME_PAGE_FOOTER_WEBSITE_MAP_VALUE_CHAIN: 'سلسلة القيمة',
    HOME_PAGE_FOOTER_WEBSITE_MAP_OPPORTUNITIES: 'الفرص الاستثمارية التعدينية العربية',
    HOME_PAGE_FOOTER_WEBSITE_MAP_GLOBAL_DEMAND: 'الطلب العالمي',
    HOME_PAGE_FOOTER_WEBSITE_MAP_TECHNOLOGIES: 'تقنيات الطاقة النظيفة',
    HOME_PAGE_FOOTER_WEBSITE_MAP_PRICES: 'أسعار المعادن',
    HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MAP: 'الخريطة العربية',

    HOME_PAGE_FOOTER_WEBSITE_CONTACT_TITLE: 'تواصل معنا',
    HOME_PAGE_FOOTER_ADDRESS_TITLE: 'العنوان',
    HOME_PAGE_FOOTER_ADDRESS: ` ملتقى شارع فرنسا - زنقة الخطوات 10102<br />الرباط - اكدال
    المملكة المغربية`,
    HOME_PAGE_FOOTER_PHONE_TITLE: 'الهاتف',
    HOME_PAGE_FOOTER_FAX_TITLE: 'الفاكس',
    HOME_PAGE_FOOTER_EMAIL_TITLE: 'البريد الإلكتروني',

    // Clean energy minerals
    CLEAN_ENERGY_MINERALS_PAGE_PLACEHOLDER: `الألمنيوم ,الليثيوم , الكوبالت...`,

    // Technologies
    TECHNOLOGIES_PAGE_SOLAR_ENERGY_TITLE: `الطاقة الشمسية الكهروضوئية`,
    TECHNOLOGIES_PAGE_WIND_ENERGY_TITLE: `طاقة الرياح`,
    TECHNOLOGIES_PAGE_BATTERY_TITLE: `البطاريات`,
    TECHNOLOGIES_PAGE_ELECTRICAL_GRID_TITLE: `شبكات الكهرباء`,
    TECHNOLOGIES_PAGE_HYDROGEN_TITLE: `الهيدروجين`,
    TECHNOLOGIES_PAGE_ELECTRIC_CAR_TITLE: `المركبات الكهربائية`,

    // GLOBAL
    COPYRIGHT: `جميع حقوق المنصة محفوظة للمنظمة العربية للتنمية الصناعية والتقييس والتعدين AIDSMO © 2024`,
    SOURCE: `المصدر`,

    // Opportunities
    OPPORTUNITIES_PAGE_SUB_TITLE: 'فرص استثمارية متاحة حاليا',
    OPPORTUNITIES_PAGE_ROW_MENIRALS: 'الخام :',
    OPPORTUNITIES_PAGE_AREA: 'المساحة (كم 2) :',
    OPPORTUNITIES_PAGE_DATE: 'تاريخ الفرصة :',
    OPPORTUNITIES_PAGE_TYPE: 'نوع الفرصة :',
    OPPORTUNITIES_PAGE_ADDITIONAL_INFORMATION: 'معلومات إضافية',
    OPPORTUNITIES_PAGE_NOT_SPECIFIED: 'غير محدد',

    // Search
    SEARCH_PAGE_TITLE: `نتائج البحث`,
    FOR_MORE_INFORMATION: `لمزيد من المعلومات`,
    DOWNLOAD_STUDIES_FILE: `يمكنكم تحميل دراسة المعادن الاستراتيجية في الدول العربية`,


    // Single mineral 
    SINGLE_MINERAL_PAGE_HISTORY: `تاريخ`,
    SINGLE_MINERAL_PAGE_SOURCE: `مصادر #### في الطبيعة`,
    SINGLE_MINERAL_PAGE_COMPOUNDS: `مركبات`,

    // Clean Energy Technologies Batteries
    TECHNOLOGIES_PAGE_BATTERY_DEFINITION: `البطاريات هي وسيلة لتخزين الطاقة الكهربائية وإنتاجها، وتتألف عادة من خلايا كهربائية تحتوي على
    مواد كيميائية تتفاعل لإنتاج الكهرباء. يتوقع أن تسيطر البطاريات LFP الآمنة والأقل تكلفة على سوق
    تخزين البطاريات، والتي تحتاج إلى الليثيوم والفسفور، على الأقل في الوقت القريب. بينما يمكن أن تأخذ
    بطاريات النيكل والمنجنيز والكوبالت عالية الكثافة من الطاقة حصة صغيرة من السوق، على الرغم من
    بعض العوامل التكلفة المحتملة. بالإضافة إلى ذلك، من المتوقع أن تصبح بطاريات الفاناديوم لها دور في
    سوق الطاقة وتبدأ تجاريًا بحصة صغيرة، ومن ثم تزيد تدريجيًا لالتقاط حصة أكبر في سوق تخزين
    الطاقة في مشاريع الطاقة المتجددة الكبيرة.
    <br />
    المعادن المستخدمة في البطاريات، تحتوي عادة على مجموعة متنوعة من المعادن والمركبات الكيميائية
    المستخدمة في عمليات تخزين وتوليد الطاقة الكهربائية ومن أهمها: النحاس، النيكل ، الكوبالت، الليثيوم،
    المنغنيز، السيليكون والفاناديوم. هذه المعادن تستخدم بطرق مختلفة وتتفاعل مع مواد كيميائية أخرى
    داخل البطاريات لتوليد وتخزين الكهرباء. يُطور الباحثون باستمرار تكنولوجيا البطاريات لزيادة قدرتها
    ومدى صديقتها للبيئة.`,

    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_TITLE: `المعادن المستخدمة في البطاريات`,
    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_PARAGRAPH: ` تحتوي عادة على مجموعة متنوعة من المعادن والمركبات الكيميائية المستخدمة في عمليات تخزين
    وتوليد الطاقة الكهربائية ومن أهمها:`,
    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_IMAGE: ``,
    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_SECOND_PARAGRAPH: `هذه المعادن تستخدم بطرق مختلفة وتتفاعل مع مواد كيميائية أخرى داخل البطاريات لتوليد وتخزين
    الكهرباء. يُطور الباحثون باستمرار تكنولوجيا البطاريات لزيادة قدرتها ومدى صديقتها للبيئة.`,
    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_POWER_BI_TITLE: `الطلب العالمي على معادن المستخدمة في البطاريات`,
    TECHNOLOGIES_PAGE_BATTERY_MINERALS_USED_POWER_BI: `https://app.powerbi.com/view?r=eyJrIjoiMTg0MGMwYWUtMjhhMi00MjhkLWJlNjYtOWRiMzgzMWZiOWYzIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`,

    // Clean Energy Technologies Solar Energy
    TECHNOLOGIES_PAGE_SOLAR_DEFINITION: `هي الطاقة التي تستخدم الأشعة الشمسية لتحويلها إلى طاقة كهربائية باستخدام ألواح أشباه المواصلات، وتعدّ المصدرَ الأكثر تطوراً لإنتاج الطاقة النظيفة في وقتنا الحاضر والأسرع انتشارا على مستوى العالم، كما أنها عبارة عن وحدات معيارية للغاية ويتراوح حجمها من مجموعات الطاقة الشمسية المنزلية الصغيرة والتركيبات على الأسطح بسعة 3-20 كيلوواط ، وصولاً إلى الأنظمة ذات السعة في مئات ميجاوات.
        <br />
        وصلت القدرة المركبة للطاقة الشمسية الكهروضوئية الى 485 جيجاوات في عام 2018 (تقرير الوكالة الدولية للطاقة المتجددة 2019)، متفوقة في النمو على جميع التقنيات الأخرى بين عامي 2017 و 2018 بنسبة بلغت 24%.
        <br />
        وينعكس هذا الاتجاه في القدرة المركبة للطاقة الشمسية الكهروضوئية، والتي من المتوقع أن تصل الى 8519 جيجاوات بحلول عام 2050 في دول إفريقيا وأسيا وأوروبا مع وفورات مستمرة في التكاليف.
    `,

    TECHNOLOGIES_PAGE_SOLAR_MINERALS_USED_TITLE: `المعادن المستخدمة في الطاقة الشمسية الكهروضوئية`,
    TECHNOLOGIES_PAGE_SOLAR_MINERALS_USED_PARAGRAPH: ` تحتوي عادة على مجموعة متنوعة من المعادن والمركبات الكيميائية المستخدمة في عمليات تخزين
    وتوليد الطاقة الكهربائية ومن أهمها:`,
    TECHNOLOGIES_PAGE_SOLAR_MINERALS_USED_IMAGE: ``,
    TECHNOLOGIES_PAGE_SOLAR_MINERALS_USED_POWER_BI_TITLE: `الطلب العالمي على معادن المستخدمة في الطاقة الشمسية الكهروضوئية`,
    TECHNOLOGIES_PAGE_SOLAR_MINERALS_USED_POWER_BI: `https://app.powerbi.com/view?r=eyJrIjoiMzI2YzQyNjUtZDQ2NC00MDEyLWI2OWItYWFhYzI3MmVkNGZjIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`,

    // Clean Energy Electric vehicles
    TECHNOLOGIES_PAGE_ELECTRIC_VEHICLES_DEFINITION: `تمثل المتطلبات المعدنية لقطاع المركبات الكهربائية وتخزين البطاريات النصيب الأكبرمن الطلب على
    معادن تقنيات الطاقة النظيفة على مدى العقدين المقبلين، حيث زاد الطلب على المعادن المستخدمة في
    هذا القطاع ما يقرب من عشرة أضعاف في سيناريو السياسات المعلنة وحوالي 30 مرة في سيناريو
    التنمية المستدامة خلال الفترة حتى عام 2040.`,

    TECHNOLOGIES_PAGE_ELECTRIC_VEHICLES_MINERALS_USED_TITLE: `المعادن المستخدمة في المركبات الكهربائية`,
    TECHNOLOGIES_PAGE_ELECTRIC_VEHICLES_MINERALS_USED_IMAGE: ``,
    TECHNOLOGIES_PAGE_ELECTRIC_VEHICLES_MINERALS_USED_POWER_BI_TITLE: `الطلب العالمي على معادن المستخدمة في المركبات الكهربائية`,
    TECHNOLOGIES_PAGE_ELECTRIC_VEHICLES_MINERALS_USED_POWER_BI: `https://app.powerbi.com/view?r=eyJrIjoiY2JmNDFjODMtMTIzZS00NTVkLThjMjktZjIxZGJjZTA1MTYwIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`,


}

export default ar