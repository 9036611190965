import CleanEnergyMineralsTechnologiesGridItem from "./CleanEnergyMineralsTechnologiesGridItem";
import { useConfig } from "../../contexts/config/ConfigContext";

export default function CleanEnergyMineralsTechnologiesGrid() {
  const { configState } = useConfig();
  return (
    <div
      data-aos={`fade-in`}
      data-aos-duration={`500`}
      className={`grid grid-cols-6 gap-8 w-full`}
    >
      <CleanEnergyMineralsTechnologiesGridItem
        link={`/clean-energy-minerals/technologies/solar-energy`}
        title={configState.translation.TECHNOLOGIES_PAGE_SOLAR_ENERGY_TITLE}
        image={`/assets/images/technologies/solar-energy/solar-energy.webp`}
      />
      <CleanEnergyMineralsTechnologiesGridItem
        link={`/clean-energy-minerals/technologies/wind-energy`}
        title={configState.translation.TECHNOLOGIES_PAGE_WIND_ENERGY_TITLE}
        image={`/assets/images/technologies/wind-energy/wind-energy.webp`}
      />
      <CleanEnergyMineralsTechnologiesGridItem
        link={`/clean-energy-minerals/technologies/batteries`}
        title={configState.translation.TECHNOLOGIES_PAGE_BATTERY_TITLE}
        image={"/assets/images/technologies/batteries/battery.webp"}
      />
      <CleanEnergyMineralsTechnologiesGridItem
        title={configState.translation.TECHNOLOGIES_PAGE_ELECTRICAL_GRID_TITLE}
        link={`/clean-energy-minerals/technologies/electricity-networks`}
        image={
          "/assets/images/technologies/electricity-networks/electricity-networks.webp"
        }
      />
      <CleanEnergyMineralsTechnologiesGridItem
        title={configState.translation.TECHNOLOGIES_PAGE_HYDROGEN_TITLE}
        link={`/clean-energy-minerals/technologies/hydrogen`}
        image={"/assets/images/technologies/hydrogen/hydrogen.webp"}
      />
      <CleanEnergyMineralsTechnologiesGridItem
        title={configState.translation.TECHNOLOGIES_PAGE_ELECTRIC_CAR_TITLE}
        link={`/clean-energy-minerals/technologies/electric-vehicles`}
        image={
          "/assets/images/technologies/electric-vehicles/electric-vehicles.webp"
        }
      />
    </div>
  );
}
