import { Link, useNavigate } from "react-router-dom";
import MenuItem from "./../../global/FooterMenuItem";
import Copyright from "./../../global/Copyright";

import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { useConfig } from "../../../contexts/config/ConfigContext";

export default function MobileFooter() {
  const { configState } = useConfig();

  const navigate = useNavigate();

  return (
    <footer className="bg-muted overflow-hidden lg:hidden block">
      <div className="px-6 py-12">
        <div className="flex flex-col items-start space-y-12">
          <div
            data-aos-once="true"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-delay="800"
          >
            <h4 className="font-bold text-xl mb-4 text-start">
              {configState.translation.HOME_PAGE_FOOTER_WEBSITE_CONTACT_TITLE}
            </h4>
            <div className="space-y-2">
              <div className="font-bold">
                {configState.translation.HOME_PAGE_FOOTER_ADDRESS_TITLE}
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: configState.translation.HOME_PAGE_FOOTER_ADDRESS,
                }}
              />
            </div>
            <ul className="mt-4 space-y-2">
              <li className="space-x-2 rtl:space-x-reverse">
                <span className="font-bold">
                  {configState.translation.HOME_PAGE_FOOTER_PHONE_TITLE}
                </span>
                <span className="select-all">00212537274500</span>
              </li>
              <li className="space-x-2 rtl:space-x-reverse">
                <span className="font-bold">
                  {configState.translation.HOME_PAGE_FOOTER_FAX_TITLE}
                </span>
                <span className="select-all">00212537772188</span>
              </li>
              <li className="space-x-2 rtl:space-x-reverse">
                <span className="font-bold">
                  {configState.translation.HOME_PAGE_FOOTER_EMAIL_TITLE}
                </span>
                <span className="select-all">aidsmo@aidsmo.org</span>
              </li>
              <li>
                <ul className="flex mt-6 items-center justify-between">
                  <li
                    data-aos-once="true"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="1400"
                  >
                    <Link to={``}>
                      <FaFacebookF className="w-[12px] h-[22px] hover:text-warning" />
                    </Link>
                  </li>
                  <li
                    data-aos-once="true"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="1500"
                  >
                    <Link to={``}>
                      <FaLinkedinIn className="w-[22px] h-[22px] hover:text-warning" />
                    </Link>
                  </li>
                  <li
                    data-aos-once="true"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="1600"
                  >
                    <Link to={``}>
                      <FaYoutube className="w-[22px] h-[22px] hover:text-warning" />
                    </Link>
                  </li>
                  <li
                    data-aos-once="true"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="1700"
                  >
                    <Link to={``}>
                      <RiWhatsappFill className="w-[22px] h-[22px] hover:text-warning" />
                    </Link>
                  </li>
                  <li
                    data-aos-once="true"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="1800"
                  >
                    <Link to={``}>
                      <FaXTwitter className="w-[22px] h-[22px] hover:text-warning" />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div
            data-aos-once="true"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-delay="900"
          >
            <h4 className="font-bold text-xl mb-4">
              {configState.translation.HOME_PAGE_FOOTER_WEBSITE_MAP_TITLE}
            </h4>
            <div className="space-y-2 flex flex-col lg:flex-row-reverse lg:items-start lg:space-y-0">
              <div>
                <ul className="flex flex-col space-y-2">
                  <div
                    onClick={() => {
                      navigate("/");
                      window.scrollTo(0, 0);
                    }}
                    className="hover:text-warning hover:underline cursor-pointer"
                  >
                    {configState.translation.HOME_PAGE_FOOTER_WEBSITE_MAP_HOME}
                  </div>

                  <MenuItem
                    label={
                      configState.translation.HOME_PAGE_FOOTER_WEBSITE_MAP_ABOUT
                    }
                    to={"/about"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_CLEAN_ENERGY_MINERALS
                    }
                    to={"/clean-energy-minerals/1"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MINERALS
                    }
                    to={"/clean-energy-minerals/region"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_PRODUCTION
                    }
                    to={"/clean-energy-minerals/region/production"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_VALUE_CHAIN
                    }
                    to={"/clean-energy-minerals/value-chains"}
                  />
                </ul>
              </div>
              <div>
                <ul className="flex flex-col space-y-2">
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_OPPORTUNITIES
                    }
                    to={"/clean-energy-minerals/opportunities"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_GLOBAL_DEMAND
                    }
                    to={"/clean-energy-minerals/global/demand"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_TECHNOLOGIES
                    }
                    to={"/clean-energy-minerals/technologies"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_PRICES
                    }
                    to={"/prices"}
                  />
                  <MenuItem
                    label={
                      configState.translation
                        .HOME_PAGE_FOOTER_WEBSITE_MAP_REGION_MAP
                    }
                    to={"/region/map"}
                  />
                </ul>
              </div>
            </div>
          </div>
          <div
            data-aos-once="true"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-delay="1200"
            className="flex w-full justify-center items-center space-x-4 rtl:space-x-reverse"
          >
            <div>
              <Link to={"https://www.aidsmo.org/"}>
                <img
                  data-aos-once="true"
                  data-aos="fade-in"
                  data-aos-duration="900"
                  data-aos-delay="1300"
                  src={`/assets/logos/aidsmo_logo.webp`}
                  width={"160px"}
                  alt="apfrm logo"
                />
              </Link>
            </div>
            <div>
              <div
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  data-aos-once="true"
                  data-aos="fade-in"
                  data-aos-duration="900"
                  data-aos-delay="1300"
                  src={`/assets/logos/app_logo.svg`}
                  width={"160px"}
                  alt="apfrm logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}
