import { useConfig } from "../../../contexts/config/ConfigContext";
import PlatformOverviewCard from "./PlatformOverviewCard";
import PlatformOverviewBanner from "./PlatformOverviewBanner";

// Component to display an overview of the platform
export default function PlatformOverview() {
  const { configState } = useConfig();

  return (
    <>
      {/* Banner component for the overview */}
      <PlatformOverviewBanner />
      {/* Container for the overview cards */}
      <div className="px-6 py-12 lg:px-0 overflow-hidden app-container">
        <div className="grid grid-cols-4 gap-6">
          {/* Platform overview cards */}
          <PlatformOverviewCard
            to={`/clean-energy-minerals/region/production`}
            image={`/assets/images/overview/arab-production.webp`}
            icon={`/assets/icons/overview/arab-production-icon.svg`}
            label={configState.translation.HOME_PAGE_OVERVIEW_ARAB_PRODUCTION}
            className={"lg:ltr:order-1 lg:trl:order-1"}
          />
          <PlatformOverviewCard
            to={`/clean-energy-minerals/value-chains`}
            image={`/assets/images/overview/value-chain.webp`}
            icon={`/assets/icons/overview/value-chain-icon.svg`}
            label={configState.translation.HOME_PAGE_OVERVIEW_VALUE_CHAIN}
            className={"lg:ltr:order-2 lg:trl:order-2"}
          />
          <PlatformOverviewCard
            to={`/clean-energy-minerals/global/demand`}
            image={`/assets/images/overview/global-demand.webp`}
            icon={`/assets/icons/overview/global-demand-icon.svg`}
            label={configState.translation.HOME_PAGE_OVERVIEW_GLOBAL_DEMAND}
            className={"lg:ltr:order-3 lg:trl:order-3"}
          />
          <PlatformOverviewCard
            to={`/clean-energy-minerals/technologies`}
            image={`/assets/images/overview/clean-energy-technologies.jpg`}
            icon={`/assets/icons/overview/clean-energy-technologies-icon.svg`}
            label={configState.translation.HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES}
            className={"lg:ltr:order-4 lg:trl:order-4"}
          />
        </div>
      </div>
    </>
  );
}
