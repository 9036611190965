import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { LuSearch } from "react-icons/lu";
import { MdClose, MdMenu } from "react-icons/md";
import MobileNavbarItem from "./MobileNavbarItem";
import { useConfig } from "../../../contexts/config/ConfigContext";
import { BiDownArrow } from "react-icons/bi";
import { CONFIG_ACTIONS } from "../../../contexts/config/configActions";

export default function MobileNavbar() {
  const { configState, configDispatch } = useConfig();

  // Navigation hook from react-router-dom
  const navigate = useNavigate();

  // State for menu and search visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [
    isMenuCleanEnergyMineralItemsOpen,
    setIsMenuCleanEnergyMineralItemsOpen,
  ] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // State for search input
  const [search, setSearch] = useState("");

  // Animation for menu slide-in/slide-out
  const menuAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0%)" : "translateX(100%)",
  });

  // Animation for search slide-in/slide-out
  const searchAnimation = useSpring({
    transform: isSearchOpen ? "translateY(0%)" : "translateY(-10%)",
    opacity: isSearchOpen ? 1 : 0, // 1 for fully opaque, 0 for fully transparent
  });

  // Reset menu state when navigation occurs
  useEffect(() => {
    setIsMenuOpen(false);
    setIsMenuCleanEnergyMineralItemsOpen(false);
  }, [navigate]);

  // Toggle menu visibility and close search
  const handleOnClickMenuButton = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSearchOpen(false);
  };

  // Handle search submission
  const handleSearch = () => {
    if (search) {
      navigate(`/search/${search}`);
      setIsSearchOpen(false);
    }
  };

  // Toggle search visibility and close menu
  const handleOnClickSearchButton = () => {
    setIsSearchOpen(!isSearchOpen);
    setIsMenuOpen(false);
  };
  return (
    <div>
      {/* Header section with menu and search buttons */}
      <div
        className="
        lg:hidden 
        flex 
        items-center 
        justify-between 
        ltr:flex-row-reverse 
        py-3 
        px-6 
        bg-secondary/70
        backdrop-blur-xl 
      "
      >
        {/* Menu Button */}
        <button
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-delay="1200"
          onClick={handleOnClickMenuButton}
          className="text-white hover:text-warning"
        >
          {isMenuOpen ? <MdClose size={28} /> : <MdMenu size={28} />}
        </button>

        {/* Search Button */}
        <button
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="1200"
          onClick={handleOnClickSearchButton}
          className="text-white hover:text-warning"
        >
          <LuSearch size={28} className="text-white" />
        </button>
      </div>

      {/* Search bar section */}
      {isSearchOpen && (
        <animated.div style={searchAnimation}>
          <div className="lg:hidden p-6 w-full bg-secondary/70 backdrop-blur-xl ">
            <div className="flex">
              {/* Search Input */}
              <div className="relative w-full">
                <LuSearch
                  size={28}
                  className="absolute ltr:left-0 ltr:ml-3 rtl:right-0 z-5 text-primary mt-[7px] rtl:mr-3"
                />
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={
                    configState.translation.HOME_PAGE_NAVBAR_SEARCH_PLACEHOLDER
                  }
                  type="search"
                  className="
                                    flex
                                    w-full 
                                    outline-none 
                                    border 
                                    py-2 
                                    px-4 
                                    rtl:pr-12 
                                    ltr:pl-12
                                    bg-white/70 
                                    text-primary 
                                    focus:bg-white "
                />
              </div>

              {/* Search Button */}
              <button
                onClick={handleSearch}
                className="py-1.5 px-4 bg-primary text-white"
              >
                {configState.translation.HOME_PAGE_NAVBAR_SEARCH_BUTTON}
              </button>
            </div>
          </div>
        </animated.div>
      )}

      {/* Menu section */}
      {isMenuOpen && (
        <animated.div style={menuAnimation}>
          <div className="lg:hidden text-white font-semibold text-sm p-6 bg-secondary/70 backdrop-blur-xl ">
            {/* Menu items */}
            <ul className="flex flex-col space-y-3 ltr:items-start text-[18px]">
              <li>
                <Link className="hover:text-warning" to={"/"}>
                  {configState.translation.HOME_PAGE_NAVBAR_HOME}
                </Link>
              </li>
              <li>
                <Link className="hover:text-warning" to={"/about"}>
                  {configState.translation.HOME_PAGE_NAVBAR_ABOUT}
                </Link>
              </li>
              {/* Clean Minerals Energy Section */}
              <li className="w-full">
                <div className="flex justify-between w-full items-center">
                  <Link
                    className="hover:text-warning"
                    to={"/clean-energy-minerals/1"}
                  >
                    {
                      configState.translation
                        .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY
                    }
                  </Link>
                  <button
                    className={`p-2 ${
                      isMenuCleanEnergyMineralItemsOpen ? "rotate-180" : ""
                    } ease-in-out transition duration-150`}
                    onClick={() =>
                      setIsMenuCleanEnergyMineralItemsOpen(
                        !isMenuCleanEnergyMineralItemsOpen
                      )
                    }
                  >
                    <BiDownArrow />
                  </button>
                </div>
                {/* Submenu for Clean Minerals Energy */}
                {isMenuCleanEnergyMineralItemsOpen && (
                  <ul className="flex flex-col mt-3 pr-3 ">
                    <MobileNavbarItem
                      to={`/clean-energy-minerals/region`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_REGION
                      }
                    />

                    <MobileNavbarItem
                      to={`/clean-energy-minerals/opportunities`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_OPPORTUNITIES
                      }
                    />
                    <MobileNavbarItem
                      to={`/clean-energy-minerals/region/production`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRODUCTION
                      }
                    />
                    <MobileNavbarItem
                      to={`/clean-energy-minerals/value-chains`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_VALUE_CHAIN
                      }
                    />
                    <MobileNavbarItem
                      to={`/clean-energy-minerals/global/demand`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_GLOBAL_DEMAND
                      }
                    />
                    <MobileNavbarItem
                      to={`/clean-energy-minerals/technologies`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_TECHNOLOGIES
                      }
                    />
                    <MobileNavbarItem
                      islastItem
                      to={`/prices`}
                      label={
                        configState.translation
                          .HOME_PAGE_NAVBAR_CLEAN_MINERALS_ENERGY_PRICES
                      }
                    />
                  </ul>
                )}
              </li>
              <li>
                <Link className="hover:text-warning" to={"/region/map"}>
                  {configState.translation.HOME_PAGE_NAVBAR_REGION_MAP}
                </Link>
              </li>
              <li></li>
            </ul>
            <div className="flex items-center justify-center">
              <div className="flex space-x-2 rtl:space-x-reverse text-center  backdrop-blur-xl bg-secondary/90 p-2">
                <button
                  onClick={() =>
                    configDispatch({
                      type: CONFIG_ACTIONS.CHANGE_LANGUAGE,
                      payload: {
                        language: "ar",
                      },
                    })
                  }
                  className={`${
                    configState.language == "ar"
                      ? "bg-primary"
                      : "hover:bg-primary"
                  } py-2 px-4 cursor-pointer`}
                >
                  العربية
                </button>
                <button>
                  <button
                    onClick={() =>
                      configDispatch({
                        type: CONFIG_ACTIONS.CHANGE_LANGUAGE,
                        payload: {
                          language: "en",
                        },
                      })
                    }
                    className={`${
                      configState.language == "en"
                        ? "bg-primary"
                        : "hover:bg-primary"
                    } py-2 px-4 cursor-pointer`}
                  >
                    English
                  </button>
                </button>
              </div>
            </div>
          </div>
        </animated.div>
      )}
    </div>
  );
}
