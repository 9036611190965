import React from "react";
import CleanEnergyMineralsRegionResultContainerListItem from "./CleanEnergyMineralsRegionResultContainerListItem";

export default function CleanEnergyMineralsRegionResultContainer({
  isLoading,
  isError,
  minerals,
  laguage,
}) {
  if (isLoading) {
    return [...Array(3)].map((n, index) => (
      <li
        key={index}
        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 bg-gray-300 h-[180px] animate-pulse"
      />
    ));
  }
  if (isError) {
    return (
      <div className="col-span-12 flex items-center justify-center border border-muted p-8 h-[258px]">
        <h2 className="font-bold text-xl">Something went wrong!</h2>
      </div>
    );
  }
  return minerals.map((mineral) => (
    <CleanEnergyMineralsRegionResultContainerListItem
      key={mineral.id}
      mineral={mineral}
      laguage={laguage}
    />
  ));
}
