export default function Title({ children, className, ...otherProps }) {
  return (
    <h2
      {...otherProps}
      className={`border-warning mb-8 text-2xl lg:text-3xl ltr:font-bold ltr:border-l-8 ltr:pl-2 rtl:font-black rtl:border-r-8 rtl:pr-2 ${className}`}
    >
      {children}
    </h2>
  );
}
